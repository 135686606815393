// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

p {
  line-height: 13px;
}

.cancel {
  padding: 10px;
  max-width: 900px;
  width: 100%;
}

.loading {
  min-height: 300px;
  max-width: 900px;
  width: 100%;
  padding: 0 16px;
}

.btn-green {
  color: white;
  background-color: #00857D;
  border-radius: 35px;
  width: 97px;
  height: 36px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 740px) {
  .hours-of-operation {
    display: flex;
    gap: 20px;
  }
}
.hours-of-operation .label {
  font-weight: bold;
}
.hours-of-operation .hours {
  display: grid;
  grid-template-columns: minmax(105px, max-content) max-content;
  line-height: 1.3em;
}
@media (max-width: 739px) {
  .hours-of-operation .hours {
    padding-left: 20px;
    padding-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/home/contact-modal/contact-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAGI;EADJ;IAEI,aAAA;IACA,SAAA;EACF;AACF;AAGI;EACI,iBAAA;AADR;AAII;EACI,aAAA;EACA,6DAAA;EACA,kBAAA;AAFR;AAIQ;EALJ;IAMQ,kBAAA;IACA,iBAAA;EADV;AACF","sourcesContent":[".modal-header {\n    border-bottom: none;\n}\n\n.modal-footer {\n    border-top: none;\n}\n\np {\n    line-height: 13px;\n}\n\n.cancel {\n    padding: 10px;\n    max-width: 900px;\n    width: 100%;\n}\n\n.loading {\n    min-height: 300px;\n    max-width: 900px;\n    width: 100%;\n    padding: 0 16px;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n    border-radius: 35px;\n    width: 97px;\n    height: 36px;\n}\n\n.modal-body {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}\n\n.hours-of-operation {\n    @media (min-width:740px) {\n    display: flex;\n    gap: 20px;\n    }\n\n\n\n    & .label {\n        font-weight: bold;\n    }\n\n    & .hours {\n        display: grid;\n        grid-template-columns: minmax(105px, max-content) max-content;\n        line-height: 1.3em;\n\n        @media (max-width:739px) {\n            padding-left: 20px;\n            padding-top: 10px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
