import * as moment from 'moment';
import * as filterActions from '../../store/actions/filter.actions';
import { StatusFilterType } from '../../types/statusFilterType';
import { TrailerRequestPayload } from '../../types/trailerRequestPayload';
import { TrailerSortType } from '../../types/trailerSortType';
import { ADD_TRAILER_REQUESTS, SET_TRAILER_REQUESTS } from '../actions/trailer-requests.actions';

export const defaultFilters: TrailerRequestPayload = {
  locations: [],
  sortType: TrailerSortType.STATUS,
  ascending: true,
  fromDate: setHour('fromDate'),
  toDate: setHour('toDate'),
  skip: 0,
  take: 25,
  statusFilter: StatusFilterType.ALL,
  statusFilters: [StatusFilterType.ALL],
  query: '',
  requestType: [],
  trailerType: [],
  destination: null,
  destinationSort: [],
  serviceLevel: [],
  trailerDirection: [],
  trailerVolume: [],
  earliestDueDate: null,
  latestDueDate: null,
  lastUpdatedAfter: new Date().toISOString(),
  unreadCommentsOnly: false,
  scrollPosition: 0,
  locationUsersSearchTerm: "",
  locationSearchTerm: "",
  planNotMet: [],
  createdAs: []
};

export function filterReducer(state: TrailerRequestPayload = defaultFilters, { type, payload }): TrailerRequestPayload {
  switch (type) {
    case filterActions.UPDATE_SEARCH_QUERY:
      return { ...state, skip: 0, query: payload.query };
    case filterActions.SET_STATUS_FILTERS:
      return { ...state, skip: 0, statusFilters: payload.filters };
    case filterActions.UPDATE_DATE_RANGE:
      return { ...state, skip: 0, fromDate: payload.fromDate, toDate: payload.toDate };
    case filterActions.TOGGLE_SORT_DIRECTION:
      return { ...state, skip: 0, ascending: !state.ascending };
    case filterActions.UPDATE_SORT_FILTER:
      return { ...state, skip: 0, sortType: payload.sortType };
    case filterActions.UPDATE_LOCATIONS:
      return { ...state, skip: 0, locations: payload.locations };
    case filterActions.UPDATE_FILTERS:
      return {
        ...state,
        skip: 0,
        requestType: payload.requestType,
        trailerType: payload.trailerType,
        destination: payload.destination,
        destinationSort: payload.destinationSort,
        serviceLevel: payload.serviceLevel,
        trailerVolume: payload.trailerVolume,
        trailerDirection: payload.trailerDirection,
        earliestDueDate: payload.earliestDueDate,
        latestDueDate: payload.latestDueDate,
        unreadCommentsOnly: payload.unreadCommentsOnly,
        planNotMet: payload.planNotMet,
        createdAs: payload.createdAs
      };
    case filterActions.SET_SCROLL_POSITION:
      return { ...state, scrollPosition: payload };
    case filterActions.UPDATE_LOCATION_USER_FILTER:
      return { ...state, locationUsersSearchTerm: payload }
    case filterActions.UPDATE_LOCATION_USER_LOCATION_FILTER:
      return { ...state, locationSearchTerm: payload };
    case ADD_TRAILER_REQUESTS:
      return { ...state, skip: payload.totalRequestCount };
    case SET_TRAILER_REQUESTS:
      return { ...state, skip: payload.requestData.requests.length };
    default:
      return state;
  }
}

function setHour(field: string) {
  let d = new Date();
  if (field === 'fromDate') {
    d.setHours(0, 0, 0, 0);
  } else if (field === 'toDate') {
    d.setHours(23, 59, 59, 999);
  }
  return moment(d).format('YYYY-MM-DDTHH:mm:ss');
}
