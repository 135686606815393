import { LocationInfo } from "src/app/types/locationInfo";
import { LocationProfile } from "src/app/types/locationProfile";
import { LocationSortType } from "../../types/locationSortType";
import { LocationUser } from "../../types/locationUser";

export const LOAD_LOCATION_LIST = 'LOAD_LOCATION_LIST';
export const loadLocationList = () => ({
  type: LOAD_LOCATION_LIST
})

export const LOAD_LOCATION_LIST_TIMER = 'LOAD_LOCATION_LIST_TIMER';
export const loadLocationListTimer = () => ({
  type: LOAD_LOCATION_LIST_TIMER
})

export const SET_LOCATION_LIST = 'SET_LOCATION_LIST';
export const setLocationList = (locations: LocationInfo[]) => ({
  type: SET_LOCATION_LIST,
  payload: locations
})

export const SET_LOCATION_LIST_QUERY = 'SET_LOCATION_LIST_QUERY';
export const setLocationListQuery = (query: string) => ({
  type: SET_LOCATION_LIST_QUERY,
  payload: { query }
})

export const LOAD_LOCATION_USERS = 'LOAD_LOCATION_USERS';
export const loadLocationUsers = (slicNumber: string, countryCode: string) => ({
  type: LOAD_LOCATION_USERS,
  payload: { slicNumber, countryCode }
})

export const SET_LOCATION_USERS = 'SET_LOCATION_USERS';
export const setLocationUsers = (slicNumber: string, countryCode: string, users: LocationUser[]) => ({
  type: SET_LOCATION_USERS,
  payload: { slicNumber, countryCode, users }
})

export const UPDATE_LOCATION_PROFILE = 'UPDATE_LOCATION_PROFILE';
export const updateLocationProfile = (profile: LocationProfile) => ({
  type: UPDATE_LOCATION_PROFILE,
  payload: { profile }
})

export const UPDATE_LOCATION_SORT_FILTER = "UPDATE_LOCATION_SORT_FILTER";
export const updateLocationSortFilter = (sortType: LocationSortType) => ({
  type: UPDATE_LOCATION_SORT_FILTER,
  payload: { sortType }
})

export const TOGGLE_LOCATION_SORT_DIRECTION = "TOGGLE_LOCATION_SORT_DIRECTION";
export const toggleLocationSortDirection = () => ({
  type: TOGGLE_LOCATION_SORT_DIRECTION
})
