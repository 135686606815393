// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  width: 100%;
  display: flex;
}
:host .trailer-items {
  overflow-x: hidden !important;
  height: 100%;
  width: 100%;
}
:host .loading {
  min-height: 300px;
  max-width: 900px;
  width: 100%;
  padding: 0 16px;
}

:host ::ng-deep .scrollable-content {
  padding-top: 1px;
}

:host ::ng-deep .selected .trailer {
  border: 3px solid var(--Colors-UPS-Blue, #426DA9);
  border-radius: 4px;
  height: 60px;
  width: 348px;
  margin: -2px 6px;
}
:host ::ng-deep .selected.first .trailer {
  margin-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/on-property/trailer-list/trailer-list.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;AACJ;AACI;EACI,6BAAA;EACA,YAAA;EACA,WAAA;AACR;AAEI;EACI,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;AAAR;;AAIA;EACI,gBAAA;AADJ;;AAKI;EACI,iDAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AAFR;AAKI;EACI,eAAA;AAHR","sourcesContent":[":host {\n    height: 100%;\n    width: 100%;\n    display: flex;\n\n    .trailer-items {\n        overflow-x: hidden !important;\n        height: 100%;\n        width: 100%;\n    }\n\n    .loading {\n        min-height: 300px;\n        max-width: 900px;\n        width: 100%;\n        padding: 0 16px;\n    }\n}\n\n:host ::ng-deep .scrollable-content {\n    padding-top: 1px;\n}\n\n:host ::ng-deep .selected {\n    .trailer {\n        border: 3px solid var(--Colors-UPS-Blue, #426DA9);\n        border-radius: 4px;\n        height: 60px;\n        width: 348px;\n        margin: -2px 6px;\n    }\n\n    &.first .trailer {\n        margin-top: 2px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
