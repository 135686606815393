import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { NewUserQueueErrorService } from 'src/app/services/new-user-queue-error.service';
import { LocationProfile } from 'src/app/types/locationProfile';
import { LocationUser } from 'src/app/types/locationUser';
import { ApiService } from "../../api.service";
import { ImportErrorModalComponent } from './import-error-modal/import-error-modal.component';
import { ImportInvalidEntriesModalComponent } from './import-invalid-entries-modal/import-invalid-entries-modal.component';
import { QueueUserModalComponent } from './new-user-queue/queue-user-modal/queue-user-modal.component';

@Component({
  selector: 'add-location-users',
  templateUrl: './add-location-users.component.html',
  styleUrls: ['./add-location-users.component.scss']
})
export class AddLocationUsersComponent extends LocalizableComponent implements OnDestroy {
  locations: LocationProfile[] = [];
  totalUsers: number = 0;
  showErrorMessage: boolean = false;
  submitting: boolean = false;
  usersEditing: boolean = false;
  deactivatable: boolean = false;
  importing: boolean = false;

  disconnect$: Subject<boolean> = new Subject<boolean>();

  locationUserListChange: Subject<void> = new Subject<void>();

  breadcrumbs: { name: string; url: string; }[] = [
    { name: this.localize(this.langSection.Breadcrumb, this.langText.Home), url: '/' },
    { name: this.localize(this.langSection.Breadcrumb, this.langText.LocationUserManagement), url: '/location-users' },
    { name: this.localize(this.langSection.Breadcrumb, this.langText.AddNewUsers), url: '/location-users/add-users' }];

  constructor(private modalService: NgbModal, private router: Router, private apiService: ApiService, private maintenanceService: MaintenanceService, public queueErrorService: NewUserQueueErrorService) {
    super();
    this.locationUserListChange.pipe(takeUntil(this.disconnect$)).subscribe({
      next: () => {
        this.totalUsers = this.locations.reduce((sum, l) => sum + l.userList.length, 0)
        this.locations = this.locations.filter(l => l.userList.length > 0);
        this.usersEditing = this.locations.some(l => l.userList.some(u => u.isEditing));
      }
    });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();

    this.queueErrorService.clearEntries();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleNavigateFromApp($event) {
    if (!this.maintenanceService.maintenance.getValue() && this.hasUsersInQueue()) {
      $event.preventDefault();
      $event.returnValue = `${this.localize(this.langSection.Term, this.langText.AreYouSure)}\n${this.localize(this.langSection.AddUserPage, this.langText.UserQueueCancelMessage)}.`;
    }
  }

  hasUsersInQueue(): boolean {
    this.locations = this.locations?.filter(l => l.userList?.length > 0);
    return this.locations?.length > 0;
  }

  cancelChanges(event: Event) {
    event.preventDefault();
    this.deactivatable = true;
    if (!this.hasUsersInQueue()) {
      this.router.navigateByUrl('/location-users');
    } else {
      this.confirmCancel('/location-users');
    }
  }

  confirmCancel(url: string): boolean {
    let modalRef = this.modalService.open(QueueUserModalComponent, { centered: true, backdrop: 'static' });
    let modalRefComponent = modalRef.componentInstance as QueueUserModalComponent;

    modalRefComponent.confirm.pipe(take(1)).subscribe(result => {
      if (result) {
        this.deactivatable = true;
        this.router.navigateByUrl(url);
      } else {
        this.deactivatable = false;
      }
      modalRef.close();
      return result;
    });
    return false;
  }

  saveUsers() {
    this.showErrorMessage = false;
    this.submitting = true;
    this.apiService.addLocationUsers(this.locations)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(() => {
        this.locations = [];
        this.router.navigateByUrl('/location-users');
      }, 
      error => {
        console.log(error);
        this.showErrorMessage = true;
        this.submitting = false;
      });

  }

  addUserToQueue(userLocations: LocationProfile[]) {
    let user = userLocations[0].userList[0];
    userLocations.forEach(location => {
      let added = this.locations.find(l => l.slicNumber === location.slicNumber && l.countryCode === location.countryCode) ?? location;
      if (added !== location) { // add user to location
        let existingUser = added.userList.find(u => u.upsId.toLowerCase() === user.upsId.toLowerCase());
        if (existingUser) {
          existingUser.email = user.email;
          existingUser.role = user.role;
        }
        else {
          added.userList.push(user);
        }
      }
      else {
        this.locations.push(location);
      }

      this.syncUserInfo(user);
      this.locations = this.locations.sort((a, b) => {
        let nameA = (a.customName) ? a.customName : a.slicName;
        let nameB = (b.customName) ? b.customName : b.slicName;
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
    });
  }

  addImportedUsersToQueue(userLocations: LocationProfile[]) {
    userLocations.forEach(ul => {
      const existingLocation = this.locations.find(l => l.countryCode === ul.countryCode && l.slicNumber === ul.slicNumber);
      if (!existingLocation) {
        this.locations.push(ul);
        ul.userList.forEach(importUser => this.syncUserInfo(importUser));
      } else {
        ul.userList.forEach(importUser => {
          const existingUserIndex = existingLocation.userList.findIndex(u => u.upsId === importUser.upsId)
          if (existingUserIndex === -1) {
            existingLocation.userList.push(importUser);
          } else {
            existingLocation.userList[existingUserIndex] = importUser;
          }

          this.syncUserInfo(importUser);
        });
      }
    });
  }

  syncUserInfo(user: LocationUser) {
    this.locations.forEach(location => {
      const existingUserIndex = location.userList.findIndex(u => u.upsId.toLowerCase() === user.upsId.toLowerCase());
      if (existingUserIndex !== -1) {
        const existingUser = location.userList[existingUserIndex];
        location.userList[existingUserIndex] = { ...existingUser, email: user.email };
      }
    });
    this.totalUsers = this.locations.reduce((sum, l) => sum + l.userList.length, 0);
  }

  downloadExcelTemplate() {
    let language = this.getLanguageCode() as string;
    this.apiService.downloadUserImportTemplate(this.localize(this.langSection.AddUserPage, this.langText.LocationUserImportTemplateFilename), language)
    .subscribe(result => { },
      error => {
        console.log(error);
      });;
  }

  importUsers(inputEvent: any) {
    const importUsersFile = inputEvent.target.files[0] as File;
    this.importing = true;

    this.apiService.importUsers(importUsersFile, this.getLanguageCode() as string).subscribe({ 
      next: importResponse => {
        const responseAsLocationProfiles = importResponse.importedProfiles.map(ip => {
          return { ...ip.profile, userList: ip.userList } as LocationProfile;
        })
        this.addImportedUsersToQueue(responseAsLocationProfiles);
        if (importResponse.errors.length > 0) {
          const modalRef = this.modalService.open(ImportInvalidEntriesModalComponent, { modalDialogClass: 'modal-invalid-import-entries', centered: true, backdrop: 'static' });
          modalRef.componentInstance.modal = modalRef;
          modalRef.componentInstance.errors = importResponse.errors;
        }
      }, 
      error: () => {
        const modalRef = this.modalService.open(ImportErrorModalComponent, { centered: true, size: 'lg', backdrop: 'static' });
        modalRef.componentInstance.modal = modalRef;
        this.importing = false;
      },
      complete: () => {
        this.importing = false;
      },
    });

    // Clear input to prevent issues with file of the same name being uploaded
    inputEvent.target.value = '';
  }

}