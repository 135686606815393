import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';

@Component({
  selector: 'queue-user-modal',
  templateUrl: './queue-user-modal.component.html',
  styleUrls: ['./queue-user-modal.component.scss']
})
export class QueueUserModalComponent extends LocalizableComponent {

  public confirm: Subject<boolean> = new Subject<boolean>();

  constructor() { 
    super();
  }

  dismissModal() {
    this.confirm.next(false);
  }

  confirmCancel() {
    this.confirm.next(true);
  }
}
