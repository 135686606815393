import { Component, OnInit } from '@angular/core';
import { LocalizableComponent } from '../components/localizable/localizable.component';

@Component({
  selector: 'ups-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss']
})
export class SystemMessageComponent extends LocalizableComponent {

  showMessage: boolean = true;

  constructor() {
    super();
  }

  closeMessage() {
    this.showMessage = false;
  }

}
