import { Component, Input } from '@angular/core';
import { LocationProfile } from '../../../types/locationProfile';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { NotificationUserLocationSettings } from 'src/app/types/NotificationUserLocationSettings';

@Component({
  selector: 'notification-location',
  templateUrl: './notification-location.component.html',
  styleUrls: ['./notification-location.component.scss']
})
export class NotificationLocationComponent extends LocalizableComponent {
  @Input() location: NotificationUserLocationSettings;
  
  emptyAddressRegex = /^[\s,]+$/;

}
