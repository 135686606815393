import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'cancel-request-modal',
  templateUrl: './cancel-request-modal.component.html',
  styleUrls: ['./cancel-request-modal.component.scss']
})
export class CancelRequestModal extends LocalizableComponent {
@Input() isNewRequest: boolean;

  constructor(private apiService: ApiService, private router: Router,public activeModal: NgbActiveModal) {
    super();
  }

  goBack() {
    this.activeModal.close(true);
  }

  dismissModal() {
    this.activeModal.close(false);
  }
}
