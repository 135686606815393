import { RequestFilter } from "../../types/requestFilter";
import { StatusFilterType } from "../../types/statusFilterType";
import { TrailerSortType } from "../../types/trailerSortType";

export const UPDATE_STATUS_FILTER = "UPDATE_STATUS_FILTER";
export const updateStatusFilter = (statusFilter: StatusFilterType) => ({
  type: UPDATE_STATUS_FILTER,
  payload: { statusFilter }
});

export const FETCH_MORE_REQUESTS = "FETCH_MORE_REQUESTS";
export const fetchMoreRequests = () => ({
  type: FETCH_MORE_REQUESTS
})

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const updateLocation = (location: string) => ({
  type: UPDATE_LOCATION,
  payload: { location }
})

export const UPDATE_LOCATIONS = "UPDATE_LOCATIONS";
export const updateLocations = (locations: string[]) => ({
  type: UPDATE_LOCATIONS,
  payload: { locations }
})

export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE";
export const updateDateRange = (fromDate: string, toDate: string) => ({
  type: UPDATE_DATE_RANGE,
  payload: { fromDate, toDate }
})

export const UPDATE_SORT_FILTER = "UPDATE_SORT_FILTER";
export const updateSortFilter = (sortType: TrailerSortType) => ({
  type: UPDATE_SORT_FILTER,
  payload: { sortType }
})

export const UPDATE_SEARCH_QUERY = "UPDATE_SEARCH_QUERY";
export const updateSearchQuery = (query: string) => ({
  type: UPDATE_SEARCH_QUERY,
  payload: { query }
})

export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const updateFilters = (filters: RequestFilter) => ({
  type: UPDATE_FILTERS,
  payload: { ...filters }
})

export const TOGGLE_SORT_DIRECTION = "TOGGLE_SORT_DIRECTION";
export const toggleSortDirection = () => ({
  type: TOGGLE_SORT_DIRECTION
})

export const SET_STATUS_FILTERS = 'SET_STATUS_FILTERS';
export const setStatusFilters = (filters: number[]) => ({
  type: SET_STATUS_FILTERS,
  payload: { filters }
})

export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const setScrollPosition = (scrollTop: number) => ({
  type: SET_SCROLL_POSITION,
  payload: scrollTop
})

export const UPDATE_LOCATION_USER_FILTER = 'UPDATE_LOCATION_USER_FILTER';
export const updateLocationUsersFilter = (searchString: string) => ({
  type: UPDATE_LOCATION_USER_FILTER,
  payload: searchString
})

export const UPDATE_LOCATION_USER_LOCATION_FILTER = 'UPDATE_LOCATION_USER_LOCATION_FILTER';
export const updateLocationUsersLocationFilter = (searchString: string) => ({
  type: UPDATE_LOCATION_USER_LOCATION_FILTER,
  payload: searchString
})