import { Component, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationSettingsComponent } from 'src/app/notification-settings/notification-settings.component';
import { RequestDetailsComponent } from 'src/app/request-details/request-details.component';
import { RequestFormComponent } from 'src/app/request-form/request-form.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { StorageAccountService } from 'src/app/services/storage-account.service';
import { UserAccessHistoryComponent } from 'src/app/user-access-history/user-access-history.component';

@Component({
  selector: 'full-page-modal',
  templateUrl: './full-page-modal.component.html',
  styleUrls: ['./full-page-modal.component.scss']
})
export class FullPageModalComponent {
  @Input() requestId;
  @Input() modal;
  @Input() type;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  storageAccountIsDown: boolean = false;

  @ViewChild('internalComponent') internalComponent: RequestFormComponent | RequestDetailsComponent | UserAccessHistoryComponent | NotificationSettingsComponent;

  constructor(private storageAccountService: StorageAccountService, private notificationsService: NotificationsService){}

  ngOnInit() {
    this.storageAccountService.isDown.pipe(takeUntil(this.disconnect$)).subscribe(isDown => {
      this.storageAccountIsDown = isDown;
    });
    
    this.storageAccountIsDown = localStorage.getItem("storageAccountDown") == "true";
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }
}
