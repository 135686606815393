// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select {
  background: #FFF;
  position: relative;
  border-color: #979797;
}

.ups-select-icon {
  display: inline-block;
}

.ups-select-icon:after {
  font-family: upsicons, fantasy !important;
  content: "\\e626" !important;
  color: #02857e;
  position: absolute;
  top: 10px;
  right: 15px;
  pointer-events: none;
}

.ups-select-icon.disabled:after {
  color: #6e6e6e;
}

:host {
  position: relative;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ups-select/ups-select.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AACA;EACI,qBAAA;AAEJ;;AAAA;EACE,yCAAA;EACA,2BAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,oBAAA;AAGF;;AADA;EACI,cAAA;AAIJ;;AADA;EACE,kBAAA;EACA,aAAA;AAIF","sourcesContent":[".form-select {\n  background: #FFF;\n  position: relative;\n  border-color: #979797;\n}\n.ups-select-icon{\n    display:inline-block;\n}\n.ups-select-icon:after {\n  font-family: upsicons, fantasy !important;\n  content: '\\E626' !important;\n  color: #02857e;\n  position: absolute;\n  top: 10px;\n  right: 15px;\n  pointer-events: none;\n}\n.ups-select-icon.disabled:after {\n    color: #6e6e6e;\n}\n\n:host {\n  position: relative;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
