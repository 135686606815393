import { Pipe, PipeTransform } from '@angular/core';
import { LocationProfile } from '../types/locationProfile';

@Pipe({
  name: 'locationListItem'
})
export class LocationListItemPipe implements PipeTransform {

  transform(location: LocationProfile): string {
    const addressEmpty = (location?.address?.trim() === ', ,' || location?.address?.trim() === '');
    if (addressEmpty)
      return `${location?.slicName} (${location?.crdSlicName})`;
    else
      return `${location?.slicName} (${location?.crdSlicName}) - ${location?.address}`;
  }

}
