import { Pipe, PipeTransform } from '@angular/core';
import { LocationInfo } from '../types/locationInfo';
import { DocumentStatus, LocationProfile } from '../types/locationProfile';

@Pipe({
  name: 'filterDocumentStatus'
})
export class FilterDocumentStatusPipe implements PipeTransform {

  transform(documentStatusObjects: LocationProfile[], ...args: DocumentStatus[]): LocationProfile[] {
    return documentStatusObjects.filter(ds => !args.some(arg => arg == ds.documentStatus));
  }
}
