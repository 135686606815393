import { Params } from "@angular/router"

export interface HeaderMenu {
    label: string,
    submenus: Submenu[]
}

export interface Submenu {
    label: string,
    links: Link[]
}

export interface Link {
    label: string,
    link: string,
    queryParams?: Params,
    permissions: MenuPermission
    openNewTab: boolean;
}

export enum MenuPermission {
    AllUsers = 0,
    ReadOnly = 1,
    UserAndAdmin = 128,
    AdminOnly = 256
}