// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}
:host .storage-account-down-toast {
  background-color: #FFD100;
  height: 42px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
:host .storage-account-down-toast .message {
  font-weight: 700;
  font-size: 14px;
  color: #212121;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0b3JhZ2UtYWNjb3VudC1kb3duLXRvYXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZ0JBQUE7RUFDQSxNQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7QUFDSjtBQUNJO0VBQ0kseUJBQUE7RUFDQSxZQUFBO0VBQ0EsYUFBQTtFQUNBLDZCQUFBO0VBQ0EsbUJBQUE7QUFDUjtBQUNRO0VBQ0ksZ0JBQUE7RUFDQSxlQUFBO0VBQ0EsY0FBQTtBQUNaIiwiZmlsZSI6InN0b3JhZ2UtYWNjb3VudC1kb3duLXRvYXN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIHBvc2l0aW9uOiBzdGlja3k7XG4gICAgdG9wOiAwO1xuICAgIHotaW5kZXg6IDEwMDtcbiAgICB3aWR0aDogMTAwJTtcblxuICAgIC5zdG9yYWdlLWFjY291bnQtZG93bi10b2FzdCB7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6ICNGRkQxMDA7XG4gICAgICAgIGhlaWdodDogNDJweDtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1hcm91bmQ7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgICAgICAgLm1lc3NhZ2Uge1xuICAgICAgICAgICAgZm9udC13ZWlnaHQ6IDcwMDtcbiAgICAgICAgICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICAgICAgICAgIGNvbG9yOiAjMjEyMTIxO1xuICAgICAgICB9XG4gICAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/storage-account-down-toast/storage-account-down-toast.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,yBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;AACR;AACQ;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AACZ;AACA,4/BAA4/B","sourcesContent":[":host {\n    position: sticky;\n    top: 0;\n    z-index: 100;\n    width: 100%;\n\n    .storage-account-down-toast {\n        background-color: #FFD100;\n        height: 42px;\n        display: flex;\n        justify-content: space-around;\n        align-items: center;\n\n        .message {\n            font-weight: 700;\n            font-size: 14px;\n            color: #212121;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
