// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  font-family: "Roboto", Tahoma, Helvetica, sans-serif;
  border-bottom: none;
}
.modal-header h4 {
  font-size: 32px;
}

.modal-title {
  padding-left: 35px;
}

.modal-body {
  min-height: 36px;
  font-size: 14px;
  padding-left: 35px;
  padding-bottom: 20px;
  padding-right: 20px;
  font-weight: 400;
}

.modal-footer {
  padding-top: 30px;
  padding-bottom: 10px;
  border-top: none;
}

.btn {
  margin-top: 28px;
  height: 36px;
  border-radius: 100px;
  border: 0;
  padding-right: 28px;
  padding-left: 28px;
  font-size: 13px;
}

.btn-green {
  color: white;
  background-color: #00857D;
}

.btn-close {
  padding-right: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/request-form/default-responsible-site-information-modal/default-responsible-site-information-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,oDAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;AACR;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,oBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,YAAA;EACA,yBAAA;AAAJ;;AAIA;EACI,8BAAA;AADJ","sourcesContent":[".modal-header {\n    font-family: \"Roboto\", Tahoma, Helvetica, sans-serif;\n    border-bottom: none;\n\n    h4 {\n        font-size: 32px;\n    }\n}\n\n.modal-title {\n    padding-left: 35px;\n}\n\n.modal-body {\n    min-height: 36px;\n    font-size: 14px;\n    padding-left: 35px;\n    padding-bottom: 20px;\n    padding-right: 20px;\n    font-weight: 400;\n}\n\n.modal-footer {\n    padding-top: 30px;\n    padding-bottom: 10px;\n    border-top: none;\n}\n\n.btn {\n    margin-top: 28px;\n    height: 36px;\n    border-radius: 100px;\n    border: 0;\n    padding-right: 28px;\n    padding-left: 28px;\n    font-size: 13px;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n\n}\n\n.btn-close {\n    padding-right: 40px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
