import { Pipe, PipeTransform } from '@angular/core';
import { StringService } from '../services/string.service';
import { UserWithLocations } from '../types/userWithLocations';

@Pipe({
  name: 'filterUsersList',
})
export class FilterUsersListPipe implements PipeTransform {
  
  constructor(private stringService: StringService){ }
  
  transform(users: UserWithLocations[], searchTerm: string): UserWithLocations[] {
    if(searchTerm.trim()) {
      return this.filterUsers(users, searchTerm);
    }
    else {
      return users;
    }
  }

  private filterUsers = (users: UserWithLocations[],searchTerm: string): UserWithLocations[] => {
    const searchTerms = searchTerm.split(',').filter(term => term.trim());
    return users.filter(u => this.anyFieldContainsSearchTerm(searchTerms, [u.email, u.upsId]))
  }
  
  private anyFieldContainsSearchTerm = (searchTerms: string[], fields: string[]): boolean => {
    return !!searchTerms.find(term => 
      fields.find(field =>
        this.stringService.normalizeString(field)
        .toLowerCase()
        .includes(this.stringService.normalizeString(term.trim().toLowerCase())))
    );
  }
}

