import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { updateSortFilter } from 'src/app/store/actions/filter.actions';
import { sortLocations } from 'src/app/store/actions/location-users.actions';
import { Role } from 'src/app/types/role';
import { UserLocationRole } from 'src/app/types/userLocationRole';
import { LocalizableComponent } from "../../../components/localizable/localizable.component";
import { UserWithLocations } from '../../../types/userWithLocations';
import { RemoveUserModalComponent } from '../../remove-user-modal/remove-user-modal.component';

@Component({
  selector: 'user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() user: UserWithLocations;
  userLocationsWithAccess: UserLocationRole[];
  disconnect$ = new Subject<boolean>();
  classes = { filtered: false};

  constructor(private modalService: NgbModal, private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.userLocationsWithAccess = this.user?.locations?.filter(location => this.userHasAccess(location.role)) || [];

    this.store.pipe(takeUntil(this.disconnect$)).subscribe(state => {
      const currentSort = state.locationUsers.locationSort;
      this.classes.filtered = currentSort.customSortValues && currentSort.customSortValues['userId'] == this.user.userId;
    });
  }

  ngOnDestroy() {
    this.disconnect$.next(false);
    this.disconnect$.unsubscribe();
  }
 
  openRemoveUserDialog(event: Event) {
    event.preventDefault();
    const modalRef = this.modalService.open(RemoveUserModalComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.modal = modalRef;
  }

  filterByUser(event: Event) {
    event.preventDefault();
    this.store.pipe(take(1)).subscribe(state => {
      const currentSort = state.locationUsers.locationSort;
      this.store.dispatch(sortLocations({field: currentSort.field, isAscending: currentSort.isAscending, customSortValues: {
        userId: this.user.userId
      }}));
    });
  }

  private userHasAccess(role: Role): boolean {
    return ![Role.NonUser, Role.PendingAdmin, Role.PendingUser, Role.PendingReadOnly].includes(role);
  }
}
