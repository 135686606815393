export class TrailerRequestAudit {
  RequestId: string = '';
  CreatedOn: string = '';
  UpdatedOn: string = '';
  AuditEntries: {
    User: string;
    Type: string;
    Source: string;
    Reason: string;
    FieldValuePairs: {
      Field: string;
      Value: string;
    }
    Timestamp: string;
  }[] = [];
}
