// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}
:host .user-data-stale-toast {
  background-color: #FFD100;
  height: 42px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
:host .user-data-stale-toast .message {
  font-weight: 700;
  font-size: 14px;
  color: #212121;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-data-stale-toast/user-data-stale-toast.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,yBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,eAAA;AACR;AACQ;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AACZ","sourcesContent":[":host {\n    position: sticky;\n    top: 0;\n    z-index: 100;\n    width: 100%;\n\n    .user-data-stale-toast {\n        background-color: #FFD100;\n        height: 42px;\n        display: flex;\n        justify-content: space-around;\n        align-items: center;\n        cursor: pointer;\n\n        .message {\n            font-weight: 700;\n            font-size: 14px;\n            color: #212121;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
