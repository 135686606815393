import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as newGuid } from 'uuid';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({ providedIn: 'root' })
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private maintenanceService: MaintenanceService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    try {
        request = request.clone({
          setHeaders: {
            'Transaction-Id': newGuid()
          },
          url: request.url.replace('#', '%23')
        });

        return next.handle(request)
          .pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              let headers = event.headers.keys();
              if (headers.includes('trs-maintenance')) {
                this.enableMaintenance();
              }
            }
            return event;
          }));
    } catch (error) {
      console.error(error);
      return next.handle(request);
    }
  }

  enableMaintenance() {
    if (!this.maintenanceService.maintenance.getValue()) {
      this.maintenanceService.setMaintenance(true);
    }
  }
}