import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Toast } from '../types/Toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly _toasts = new BehaviorSubject<Toast[]>([]);
  get Toasts(): Observable<Toast[]> {
    return this._toasts.asObservable();
  }

  pushToast(toast: Toast) {
    let existing = this._toasts.value.find(t => t.id === toast.id);
    if (existing?.timer) { clearTimeout(existing.timer); }

    this._toasts.next([...this._toasts.value.filter(t => t.id !== toast.id), toast]);

    if (toast.duration > 0) {
      toast.timer = window.setTimeout(() => {
        this.removeToastById(toast.id);
      },
        /* Accomodate for either seconds or milliseconds using the assumption that we will never want to display a toast for less than a second */
        toast.duration < 1000 ? toast.duration * 1000 : toast.duration);
    }
  }

  removeToast(toast: Toast | string) {
    let id: string = (typeof toast === 'string') ? toast : toast?.id;

    if (id) {
      this.removeToastById(id);
    } else {
      this._toasts.next([]);
    }
  }

  private removeToastById(id: string) {
    const toast = this._toasts.value.find(t => t.id === id);
    if (toast?.timer) { clearTimeout(toast.timer); }
    if (toast && toast.callback) { toast.callback(); }
    this._toasts.next(this._toasts.value.filter(t => t.id !== id));
  }
}
