import { Audit } from "./audit";
import { Comment } from "./comment";
import { RejectionOption } from "./rejectionOption";
import { TrailerLoad } from "./trailerLoad";

export class TrailerRequestResponse {
  arrivalPickupDateTime: string = null;
  arrivingPickupDateTime: string = null;
  createdDateTime: string = null;
  canceledPickupDateTime: string = null;
  customer: string = null;
  customerLocationName: string = null;
  deliveryNumber: string = null;
  dueDate: string = null;
  earliestPickupDateTime: string = null;
  extendedHours: string = null;
  jobNumber: string = null;
  latestPickupDateTime: string = null;
  pickUpNumber: string = null;
  pickUpWindow: string = null;
  rejectedPickupDateTime: string = null;
  rejectReason: string = null;
  requestorFirstName: string = null;
  requestorId: string = null;
  originalSlicName: string = null;
  requestorLastName: string = null;
  requestCategory: string = null;
  requestId: string = null;
  requestReason: string = null;
  requestStatus: string = null;
  requestType: string = null;
  sortDateTime: string = null;
  cancelReason: string = null;
  lastUpdatedByName: string = null;
  lastUpdatedByUserId: string = null;
  lastUpdatedDateTime: string = null;
  scheduledPickupDateTime: string = null;
  serviceLevel: string = null;
  specialInstructions: string = null;
  tractorNumber: string = null;
  updateTypeCode: string = null;
  numberTrailersRequested: number = 1;
  trailerLoads: TrailerLoad[] = [];
  slicName: string = null;
  auditRecords: Audit[] = [];
  comments: Comment[] = [];
  timezoneOffset: number = 0;
  timezoneName: string = null;
  selectedCustomerRejectionOption: number = null;
  standardRejectionReason: number;
  customerRejectionOptions: RejectionOption[] = [];
  attentionNeeded: boolean = false;
  isViewOnly?: boolean;
  responsibleSiteCountryCode: string = null;
  responsibleSiteSlicNumber: string = null;
  responsibleSiteAbbreviation: string = null;
  slicCountryCode: string = null;
  slicNumber: string = null;
  driverCreated: boolean;
  _ts: number = 0;

  // For UI purposes only
  peakSeasonWarning?: string = null;

  constructor() {
    this.trailerLoads.push(new TrailerLoad());
  }
}
