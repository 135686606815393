import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalizableComponent } from "src/app/components/localizable/localizable.component";
import { AddLocationUsersComponent } from "./add-location-users.component";
import { MaintenanceService } from "src/app/services/maintenance.service";

@Injectable()
export class CanDeactivateAddLocationUsersFormService extends LocalizableComponent  {
  constructor(private maintenanceService: MaintenanceService){
    super();
  }

  async canDeactivate(component: AddLocationUsersComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot):  Promise<boolean> {
    if (!component.deactivatable && component.hasUsersInQueue() && 
        nextState.url !== '/gateway' && !this.maintenanceService.maintenance.getValue()) {
      return await component.confirmCancel(nextState.url);
    } else {
      return true;
    }
  }

}

export const CanDeactivateAddLocationUsersForm: CanDeactivateFn<AddLocationUsersComponent> = (component: AddLocationUsersComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean> => {
  return inject(CanDeactivateAddLocationUsersFormService).canDeactivate(component, currentRoute, currentState, nextState);
}
