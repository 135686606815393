// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep ngb-typeahead-window.dropdown-menu {
  max-height: 200%;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  ::ng-deep ngb-typeahead-window.dropdown-menu {
    width: 100%;
  }
  ::ng-deep ngb-typeahead-window.dropdown-menu .dropdown-item {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvY2F0aW9uLWFkZHJlc3Mtc2VhcmNoLWlucHV0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZ0JBQUE7RUFDQSxnQkFBQTtBQUNKO0FBQ0k7RUFKSjtJQUtRLFdBQUE7RUFFTjtFQUFNO0lBQ0ksMkJBQUE7SUFDQSxrQ0FBQTtFQUVWO0FBQ0YiLCJmaWxlIjoibG9jYXRpb24tYWRkcmVzcy1zZWFyY2gtaW5wdXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6Om5nLWRlZXAgbmdiLXR5cGVhaGVhZC13aW5kb3cuZHJvcGRvd24tbWVudXtcbiAgICBtYXgtaGVpZ2h0OiAyMDAlO1xuICAgIG92ZXJmbG93LXk6IGF1dG87XG4gICAgXG4gICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogNzY3cHgpIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG5cbiAgICAgICAgLmRyb3Bkb3duLWl0ZW17XG4gICAgICAgICAgICBvdmVyZmxvdzogaGlkZGVuICFpbXBvcnRhbnQ7XG4gICAgICAgICAgICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpcyAhaW1wb3J0YW50O1xuICAgICAgICB9XG4gICAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/location-address-search-input/location-address-search-input.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;AACI;EAJJ;IAKQ,WAAA;EAEN;EAAM;IACI,2BAAA;IACA,kCAAA;EAEV;AACF;AACA,4yBAA4yB","sourcesContent":["::ng-deep ngb-typeahead-window.dropdown-menu{\n    max-height: 200%;\n    overflow-y: auto;\n    \n    @media screen and (max-width: 767px) {\n        width: 100%;\n\n        .dropdown-item{\n            overflow: hidden !important;\n            text-overflow: ellipsis !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
