import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { EnvironmentService } from '../services/environment.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { HeaderMenu, Link, MenuPermission } from '../types/header-menu';
import { LangSection, LangText } from '../types/language';
import { Role } from '../types/role';

@Component({
  selector: 'ups-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends LocalizableComponent implements OnInit, OnDestroy {

  @Input() showHeaderMenu: boolean = true;
  signUp: string = `https://www.ups.com/doapp/signup?returnto=${encodeURIComponent(window.location.href)}`;
  fullName: string = "";
  abbreviatedName: string = "";
  loggedIn: boolean = false;
  showPersonalMenu: boolean = false;
  showLanguageMenu: boolean = false;
  showNcmLink: boolean = false;
  ncmLink: string = `${window.location.origin}/network-carrier-management`;
  navigationUrls: { [key: string]: string } = {
    profile: 'https://wwwapps.ups.com/ppc/ppc.html',
    info: 'https://wwwapps.ups.com/ppc/ppc.html/informationPage',
    add: 'https://wwwapps.ups.com/ppc/ppc.html/contactPage',
    acc: 'https://wwwapps.ups.com/ppc/ppc.html/payment',
    pref: 'https://wwwapps.ups.com/ppc/ppc.html/preferencePage',
    view: 'https://www.ups.com/us/en/help-center/billing-payment.page',
    logout: 'Replaced in Auth Service'
  }
  disconnect$: Subject<boolean> = new Subject<boolean>();

  highestRole: Role = Role.NonUser;
  selectedMenu = -1;
  subMenuVisible = false;
  menuDefinition = [
    {
      label: this.localize(LangSection.Term, LangText.TrailerReservationSystem),
      submenus: [
        {
          label: (this.localize(LangSection.HomePage, LangText.Requests) as string).toUpperCase(),
          links: [
            {
              label: this.localize(LangSection.HomePage, LangText.Header),
              link: '/',
              permissions: MenuPermission.AllUsers
            },
            {
              label: this.localize(LangSection.HomePage, LangText.AddRequest),
              link: '/add-request',
              permissions: MenuPermission.UserAndAdmin
            },
            {
              label: this.localize(LangSection.HomePage, LangText.NotificationSettings),
              link: '/',
              queryParams: { path: 'notification-settings' },
              permissions: MenuPermission.ReadOnly
            }
          ]
        },
        {
          label: (this.localize(LangSection.Term, LangText.Location) as string).toUpperCase(),
          links: [
            {
              label: this.localize(LangSection.HomePage, LangText.LocationUserManager),
              link: '/location-users',
              permissions: MenuPermission.AdminOnly
            },
            {
              label: this.localize(LangSection.HomePage, LangText.OnProperty),
              link: '/onproperty',
              permissions: MenuPermission.ReadOnly,
              openNewTab: true
            }
          ]
        }]
    }];

  menus: HeaderMenu[] = [];
  expandMenu: boolean = false;
  menuRole: Role;

  private outsideClickHandler = (e) => {
    let element = e.target as HTMLElement;
    if (![element, element?.parentElement, element?.parentElement?.parentElement].some(x => x && (x.classList.contains('language-links-bubble') || x.classList.contains('personal-links-bubble') || x.classList.contains('personal-link') || x.classList.contains('language-link')))) {
      this.showPersonalMenu = this.showLanguageMenu = false;
    }
  }

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private environmentService: EnvironmentService,
    private featureFlagService: FeatureFlagService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadUser();
    this.authService.loggedInEvent$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(_ => {
        this.loadUser();
      });

    this.authService.rolesChanged$.pipe(takeUntil(this.disconnect$)).subscribe((changed: boolean) => {
      if (changed) {
        this.getMenuOptions();
      }
    });

    this.featureFlagService.sdkReady$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(() => {
        this.showNcmLink = this.featureFlagService.checkFeatureFlagBoolean('ncm-header', false) && this.authService.isNcmUser();
      });
    this.ncmLink = this.environmentService.getNcmLink();
  }

  ngAfterContentInit() {
    document.addEventListener('click', this.outsideClickHandler, false);
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
    document.removeEventListener('click', this.outsideClickHandler, false);
  }

  setLanguage(code: string) {
    localStorage.setItem('lang', code);
    window.location.reload();
  }

  loadUser() {
    this.loggedIn = this.authService.isAuthenticated();
    if (this.loggedIn) {
      this.fullName = this.authService.getFullName();

      const parts = this.fullName?.split(' ') ?? [];
      if (parts.length > 1)
        this.abbreviatedName = `${parts[0].charAt(0)}${parts[parts.length - 1].charAt(0)}`;
      else
        this.abbreviatedName = parts[0].charAt(0);

      this.getMenuOptions();
    }
  }

  showPersonalMenuClick() {
    this.showPersonalMenu = !this.showPersonalMenu;
    this.showLanguageMenu = false;
  }

  showLanguageMenuClick() {
    this.showLanguageMenu = !this.showLanguageMenu;
    this.showPersonalMenu = false;
  }

  menuClick(location: string) {
    if (this.navigationUrls[location]) {
      if (location === "logout") {
        this.authService.loggedOutEvent$.next();
      } else {
        window.open(this.navigationUrls[location], '_blank');
      }
    }
  }

  goToSignup() {
    window.location.href = this.signUp;
  }

  toggleSubMenu(index: number, event: Event) {
    if (index === this.selectedMenu) {
      this.subMenuVisible = !this.subMenuVisible;
      this.selectedMenu = -1;
    } else {
      this.selectedMenu = index;
      this.subMenuVisible = true;
    }

    if (this.subMenuVisible) {
      document.addEventListener('click', this.menuOutsideClickHandler, true);
    } else {
      document.removeEventListener('click', this.menuOutsideClickHandler, false);
    }

    event.stopPropagation();
  }

  toggleHamburgerSubMenu(index: number, event: Event) {
    if (index === this.selectedMenu) {
      this.subMenuVisible = !this.subMenuVisible;
      this.selectedMenu = -1;
    } else {
      this.selectedMenu = index;
      this.subMenuVisible = true;
    }

    event.stopPropagation();
  }

  onLinkClick(link?: Link) {
    if (link?.openNewTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([link.link])
      );

      window.open(url, '_blank');
    } else {
      this.router.navigate([link.link], { queryParams: link.queryParams });
    }

    this.subMenuVisible = false;
    this.selectedMenu = -1;
    this.expandMenu = false;

    if (!link?.queryParams)
      this.modalService.dismissAll();
  }

  onNcmLinkClick() {
    window.open(this.ncmLink);

    this.subMenuVisible = false;
    this.selectedMenu = -1;
    this.expandMenu = false;
  }

  private menuOutsideClickHandler = (e: MouseEvent) => {
    let element = e.target as HTMLElement;
    let submenu = element?.closest('.submenu-container');
    let hamburgerIcon = element?.closest('.menu-hamburger')
    let hamburgerSubmenu = element?.closest('.menu-hamburger-dropdown-container');
    if (submenu || element?.classList.contains('submenu-container') || hamburgerSubmenu || element?.classList.contains('menu-hamburger-dropdown-container') || hamburgerIcon || element?.classList.contains('menu-hamburger')) {
      e.preventDefault();
      return;
    }
    this.expandMenu = false;
    this.subMenuVisible = false;
    this.selectedMenu = -1;
  }

  onHamburgerClick() {
    this.expandMenu = !this.expandMenu;

    if (this.expandMenu) {
      document.addEventListener('click', this.menuOutsideClickHandler, true);
    } else {
      document.removeEventListener('click', this.menuOutsideClickHandler, false);
    }
  }

  onHamburgerLanguageClick(language: string) {
    this.setLanguage(language);
    this.onLinkClick();
  }

  getMenuOptions() {
    let userRole = Role.NonUser;

    if (this.authService.hasRole(Role.Admin))
      userRole = Role.Admin;
    else if (this.authService.hasRole(Role.User))
      userRole = Role.User;
    else if (this.authService.hasRole(Role.ReadonlyUser))
      userRole = Role.ReadonlyUser;

    if (userRole !== this.menuRole) {
      this.menuRole = userRole;

      if (userRole === Role.Admin)
        this.menus = this.getMenuCopy().filter(m => m.submenus.filter(s => s.links = s.links.filter(l => l.permissions == MenuPermission.AllUsers || l.permissions == MenuPermission.ReadOnly || l.permissions == MenuPermission.UserAndAdmin || l.permissions == MenuPermission.AdminOnly)));
      else if (userRole === Role.User)
        this.menus = this.getMenuCopy().filter(m => m.submenus.filter(s => s.links = s.links.filter(l => l.permissions == MenuPermission.AllUsers || l.permissions == MenuPermission.ReadOnly || l.permissions == MenuPermission.UserAndAdmin)));
      else if (userRole === Role.ReadonlyUser)
        this.menus = this.getMenuCopy().filter(m => m.submenus.filter(s => s.links = s.links.filter(l => l.permissions == MenuPermission.AllUsers || l.permissions == MenuPermission.ReadOnly)));
      else
        this.menus = this.getMenuCopy().filter(m => m.submenus.filter(s => s.links = s.links.filter(l => l.permissions == MenuPermission.AllUsers)));
    }
  }

  private getMenuCopy(): HeaderMenu[] {
    return JSON.parse(JSON.stringify(this.menuDefinition));
  }
}
