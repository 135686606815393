// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  max-width: 360px;
}

.peak-season {
  margin-bottom: 48px;
  line-height: 17px;
}

.move-up {
  margin-top: -20px;
}

.col.small {
  font-size: 80%;
}

@media (min-width: 1280px) {
  .row {
    max-width: 408px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/home/trailer-request/request-progress/request-progress.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF","sourcesContent":[".row {\n  max-width: 360px;\n}\n\n.peak-season {\n  margin-bottom: 48px;\n  line-height: 17px;\n}\n\n.move-up {\n  margin-top: -20px;\n}\n\n.col.small {\n  font-size: 80%;\n}\n\n@media (min-width:1280px) {\n  .row {\n    max-width: 408px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
