import { Role } from "./role";

export class UserAccessHistory {
    upsId: string;
    email: string;
    audits: UserAccessHistoryAudit[];
}

export class UserAccessHistoryAudit {
    locationCountryCode: string;
    locationSlicNumber: string;
    locationSlicName: string;
    date: Date;
    activity: ActivityType;
    role: Role;
    modifyingUser: string;
}

export enum ActivityType {
    Removed = -2,
    Denied = -1,
    Submitted = 0,
    Added = 1,
    Approved = 2,
    Updated = 3
}