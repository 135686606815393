import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { NewUserQueueErrorService } from 'src/app/services/new-user-queue-error.service';
import { LocationProfile } from 'src/app/types/locationProfile';
import { LocationUser } from 'src/app/types/locationUser';

@Component({
  selector: 'new-user-queue',
  templateUrl: './new-user-queue.component.html',
  styleUrls: ['./new-user-queue.component.scss']
})
export class NewUserQueueComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() locations: LocationProfile[] = [];
  @Input() totalUsers: number = 0;
  @Input() locationUserListChange: Subject<void>;
  syncUsers: Subject<LocationUser> = new Subject<LocationUser>();
  $disconnect: Subject<boolean> = new Subject();

  constructor(public queueErrorService: NewUserQueueErrorService) {
    super();
  }

  ngOnInit(): void {
    this.syncUsers.asObservable().pipe(takeUntil(this.$disconnect)).subscribe(user => this.updateUserInAllLocations(user));
  }

  ngOnDestroy() {
    this.$disconnect.next(true);
    this.$disconnect.unsubscribe();
  }

  updateUserInAllLocations(user: LocationUser) {
    this.locations.forEach((profile) => {
      const userIndex = profile.userList.findIndex(ul => ul.upsId.toLowerCase() === user.upsId.toLowerCase());
      if (userIndex !== -1) {
        const existingUser = profile.userList[userIndex]
        profile.userList[userIndex] = { ...existingUser, email: user.email };
      }
    });
  }
}