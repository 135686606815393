import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class LoadingIndicatorService {

      public trailerRequestsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
      public locationListLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  }
