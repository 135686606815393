// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: none;
  padding: 22px 16px 16px 60px;
}

.modal-body {
  padding: 0 60px;
  border-bottom: none;
}

.modal-footer {
  padding: 0 0 24px 0;
  border-top: none;
}

.btn {
  margin-top: 32px;
  height: 36px;
  border-radius: 100px;
  border: 0;
  padding-right: 28px;
  padding-left: 28px;
}

.btn-green {
  color: white;
  background-color: #00857D;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/add-location-users/import-error-modal/import-error-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,4BAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;AACJ","sourcesContent":[".modal-header {\n    border-bottom: none;\n    padding: 22px 16px 16px 60px;\n}\n\n.modal-body {\n    padding: 0 60px;\n    border-bottom: none;\n}\n\n.modal-footer {\n    padding: 0 0 24px 0;\n    border-top: none;\n}\n\n.btn {\n    margin-top: 32px;\n    height: 36px;\n    border-radius: 100px;\n    border: 0;\n    padding-right: 28px;\n    padding-left: 28px;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
