import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestSubForm } from '../request-sub-form.component';

@Component({
  selector: 'retrieve-empty-request-form',
  templateUrl: './retrieve-empty-request-form.component.html',
  styleUrls: []
})
export class RetrieveEmptyhRequestFormComponent extends RequestSubForm {
  pageName = 'RequestRetrieveEmpty';
}
