import { TrailerRequestDataResponse } from 'src/app/types/trailerRequestDataResponse';
import { ADD_TRAILER_REQUESTS, SET_TRAILER_REQUESTS, UPDATE_TRAILER_REQUEST, UPDATE_TRAILER_REQUESTS } from "../actions/trailer-requests.actions";

export function trailerRequestsReducer(state: TrailerRequestDataResponse, { type, payload }): TrailerRequestDataResponse {
  switch (type) {
    case ADD_TRAILER_REQUESTS:
      return {...state, requests: [...state.requests, ...payload.requestData.requests], metrics: payload.requestData.metrics};
    case SET_TRAILER_REQUESTS:
      return {...state, requests: payload.requestData.requests, metrics: payload.requestData.metrics};
    case UPDATE_TRAILER_REQUEST:
      return {...state, requests: state.requests.map(req => req.requestId === payload.requestId ? payload.request : req)};
    case UPDATE_TRAILER_REQUESTS:
      if (payload.requestData.length === 0)
        return state;
      
      return {...state, requests: state.requests.map(req => {
        let newRequest = payload.requestData.find(r => r.requestId === req.requestId);
        return newRequest && newRequest._ts > req._ts ? newRequest : req;
      })};
    default:
      return state;
  }
}
