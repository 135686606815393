// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 375px) {
  #AddRequestInputs {
    min-width: 318px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZC1yZXF1ZXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0k7SUFDRSxnQkFBQTtFQUFKO0FBQ0YiLCJmaWxlIjoiYWRkLXJlcXVlc3QuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcbkBtZWRpYSAobWluLXdpZHRoOiAzNzVweCkge1xuICAgICNBZGRSZXF1ZXN0SW5wdXRzIHtcbiAgICAgIG1pbi13aWR0aDogMzE4cHg7XG4gICAgfVxuICB9Il19 */`, "",{"version":3,"sources":["webpack://./src/app/request-form/add-request/add-request.component.scss"],"names":[],"mappings":"AACA;EACI;IACE,gBAAA;EAAJ;AACF;AACA,4YAA4Y","sourcesContent":["\n@media (min-width: 375px) {\n    #AddRequestInputs {\n      min-width: 318px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
