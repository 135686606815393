export enum StatusFilterType {
  ALL = 0,
  ATTENTIONNEEDED = 1,
  PENDING = 2,
  APPROVED = 3,
  SCHEDULED = 4,
  IN_TRANSIT = 5,
  ON_PROPERTY = 6,
  COMPLETED = 7,
  CANCELEDORREJECTED = 8
}
