import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from '../api.service';
import { takeUntil, skip } from 'rxjs/operators';

@Injectable()

export class MaintenanceService implements OnDestroy {

    public maintenance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public maintenance$: Observable<boolean>;

    disconnect$: Subject<boolean> = new Subject<boolean>();

    constructor(private apiService: ApiService) {
        this.maintenance$ = this.maintenance.asObservable().pipe(skip(1)); // bypass inital value to wait for api call setting
    }

    ngOnDestroy() {
        this.disconnect$.next(true);
        this.disconnect$.unsubscribe();
    }

    public queryMaintenance() {

        this.apiService.CheckMaintenance()
            .pipe(takeUntil(this.disconnect$))
            .subscribe(maintenance => {

                this.setMaintenance(maintenance);
            });
    }

    public setMaintenance(maintenance: boolean) {
        this.maintenance.next(maintenance);
    }
}
