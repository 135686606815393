import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { TrailerRequestPayload } from 'src/app/types/trailerRequestPayload';

@Component({
  selector: 'app-export-comments-modal',
  templateUrl: './export-comments-modal.component.html',
  styleUrls: ['./export-comments-modal.component.scss']
})
export class ExportCommentsModalComponent extends LocalizableComponent {
  @Input() filters: TrailerRequestPayload;
  @Input() modalRef: NgbModalRef;

  includeComments: boolean = false;
  receivedError: boolean = false;
  exporting: boolean = false;

  constructor(private requestService: ApiService) {
    super();
  }

  confirm() {
    let language = this.getLanguageCode() as string;

    this.receivedError = false;
    this.exporting = true;
    this.requestService.exportRequests(this.localize(this.langSection.HomePage, this.langText.TrailerRequestsExportFilename), { filter: this.filters, language: language, includeComments: this.includeComments })
      .subscribe({
        next: () => {
          this.modalRef.close(null);
        },
        error: error => {
          console.log(error);
          this.receivedError = true;
          this.exporting = false;
        }
      });
  }

  close() {
    this.modalRef.close(null);
  }

}
