import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { NotificationUserLocationSettings } from 'src/app/types/NotificationUserLocationSettings';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'status-change-notifications',
  templateUrl: './status-change-notifications.component.html',
  styleUrls: ['./status-change-notifications.component.scss']
})
export class StatusChangeNotificationsComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() showStatusCheckboxes: boolean = false;
  @Input() settings: NotificationUserLocationSettings;
  @Output() enableNotificationChanged = new EventEmitter<boolean>();
  @Output() statusChanged = new EventEmitter<void>();

  dataOutOfDate: boolean = false;

  disconnect$ = new Subject<boolean>();

  constructor(private notificationsService: NotificationsService) {
    super();
  }

  ngOnInit(): void {
    this.notificationsService.notificationSettingsOutOfDate$.pipe(takeUntil(this.disconnect$)).subscribe(outOfDate => {
      this.dataOutOfDate = outOfDate;
    });
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  onCheckChanged() {
    let keys = Object.keys(this.settings.statusChangeNotifications);
    let anyChecked = keys.reduce((checked, key) => {
      return checked || this.settings.statusChangeNotifications[key];
    }, false);

    if (!anyChecked && this.settings.statusChange) {
      this.settings.statusChange = false;
      this.enableNotificationChanged.emit(false);
    }

    this.statusChanged.emit();
  }

  toggleEnableNotification(enabled: boolean) {
    this.settings.statusChange = enabled;

    if (enabled) {
      let keys = Object.keys(this.settings.statusChangeNotifications);
      let anyChecked = keys.reduce((checked, key) => {
        return checked || this.settings.statusChangeNotifications[key];
      }, false);
      if (!anyChecked) {
        keys.forEach(key => {
          this.settings.statusChangeNotifications[key] = true;
        });
      }
    }
    this.enableNotificationChanged.emit(enabled);
  }
}
