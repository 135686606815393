// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user {
  min-width: 275px;
  padding: 9px 11px 9px 11px;
  font-weight: normal;
  line-height: 1em;
  display: flex;
  flex-wrap: wrap;
}
.user > div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user .remove-user {
  display: flex;
  justify-content: right;
}

a {
  text-decoration: underline;
  color: #426DA9;
  font-size: 14px;
}

.filtered {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

.bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/location-user-grid/user-header/user-header.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,0BAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;AACJ;AACK;EACC,WAAA;EACA,gBAAA;EACA,uBAAA;AACN;AAEK;EACC,aAAA;EACA,sBAAA;AAAN;;AAIE;EACE,0BAAA;EACA,cAAA;EACA,eAAA;AADJ;;AAIE;EACE,qBAAA;EACA,iBAAA;EACA,cAAA;AADJ;;AAIE;EACE,iBAAA;AADJ","sourcesContent":[".user {\n    min-width: 275px;\n    padding: 9px 11px 9px 11px;\n    font-weight: normal;\n    line-height: 1em;\n    display: flex;\n    flex-wrap: wrap;\n\n     >div {\n      width: 100%;\n      overflow: hidden;\n      text-overflow: ellipsis;\n     }\n\n     .remove-user {\n      display: flex;\n      justify-content: right;\n    }\n  }\n  \n  a {\n    text-decoration: underline;\n    color: #426DA9;\n    font-size: 14px;\n  }\n\n  .filtered {\n    text-decoration: none;\n    font-weight: bold;\n    color: inherit;\n  }\n\n  .bold {\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
