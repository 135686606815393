// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep a {
  text-decoration: underline;
}

:host ::ng-deep .time-select {
  display: flex;
  flex-direction: row;
  align-items: center;
}

:host ::ng-deep .time-select input {
  max-width: 5em;
}

:host ::ng-deep .form-row {
  margin-left: 0;
}

:host ::ng-deep input, :host ::ng-deep textarea {
  border-radius: 0;
  resize: none;
}

:host ::ng-deep h4 {
  margin-top: 1em;
}

.date-separator {
  position: relative;
  left: 30px;
}

.time-separator {
  position: relative;
  left: 24px;
}

.form-group {
  margin-bottom: 0px !important;
}

.request-date-row {
  height: 77px;
}

.error-container {
  margin-top: -20px;
  margin-bottom: 20px;
}

.submitButton:enabled {
  color: white;
}

.am-pm {
  max-height: 21px;
}

.submit-failure {
  padding-top: 4px;
}

.btn-link {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/request-form/request-form.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACE,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;AACF;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host ::ng-deep a {\n  text-decoration: underline;\n}\n\n:host ::ng-deep .time-select {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n:host ::ng-deep .time-select input {\n  max-width: 5em;\n}\n\n:host ::ng-deep .form-row {\n    margin-left: 0;\n}\n\n:host ::ng-deep input, :host ::ng-deep textarea {\n    border-radius: 0;\n    resize: none;\n}\n\n:host ::ng-deep h4 {\n    margin-top: 1em;\n}\n\n.date-separator {\n  position: relative;\n  left: 30px;\n}\n\n.time-separator {\n  position: relative;\n  left: 24px;\n}\n\n.form-group {\n    margin-bottom: 0px!important;\n}\n\n.request-date-row {\n  height: 77px;\n}\n\n.error-container {\n  margin-top: -20px;\n  margin-bottom: 20px;\n}\n\n.submitButton:enabled {\n  color: white;\n}\n\n.am-pm {\n  max-height: 21px;\n}\n\n.submit-failure {\n  padding-top: 4px;\n}\n\n.btn-link {\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
