import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { interval } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { refreshTrailerRequests } from 'src/app/store/actions/trailer-requests.actions';
import { DocumentStatus, LocationProfile } from 'src/app/types/locationProfile';
import { AppState } from '../../app.state';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { TrailerRequestResponse } from '../../types/trailerRequestResponse';
import { CancelModal } from '../cancel-modal/cancel-modal.component';
import { CommentsModal } from '../comments-modal/comments-modal.component';
import { ContactModal } from '../contact-modal/contact-modal.component';

const THIRTY_SECONDS_IN_MS = 30 * 1000;

@Component({
  selector: 'trailer-request',
  templateUrl: './request-grid-row.component.html',
  styleUrls: ['./request-grid-row.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class RequestGridRowComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() set requests(value: TrailerRequestResponse[]) {
    this._requests = value;
    this.mapSoftDeletion();
  }

  get requests() {
    return this._requests;
  }
  
  private _requests: TrailerRequestResponse[];
  disconnect$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean;
  scrollTopPosition: number = 0;
  locationProfiles: LocationProfile[];
  mappedRequests: any[];

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private loadingIndicatorService: LoadingIndicatorService,
    private store: Store<AppState>)
  {
    super();
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.loadingIndicatorService.trailerRequestsLoading$.pipe(takeUntil(this.disconnect$)).subscribe((val: boolean) => {
      this.isLoading = val;
    });

    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(locationList => {
        this.locationProfiles = locationList?.locations?.map(l => l.profile);
      });

    interval(THIRTY_SECONDS_IN_MS)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(_ => {
        this.store.dispatch(refreshTrailerRequests());
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  showCommentsIndicator(request: TrailerRequestResponse) {
    return request.comments.some(c => c.systemName == "UPS" && !c.read);
  }

  openModal(type: string, request: TrailerRequestResponse) {
    let modal = null;
    if (type == 'cancel') {
      modal = CancelModal;
    } else if (type == 'contact') {
      modal = ContactModal;
    }
    else if (type == 'comments') {
      modal = CommentsModal;
    }

    if (modal) {
      const modalRef = this.modalService.open(modal, { centered: true, size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.request = request;
      modalRef.componentInstance.modal = modalRef;
      if (modal === CancelModal) {
        modalRef.componentInstance.confirm
        .pipe(takeUntil(this.disconnect$))
        .subscribe(() => {
          const index = this.requests.findIndex(r => r.requestId === request.requestId);
          this.requests[index] = {...request};
        });
      }
    }
  }

  showLink(request: TrailerRequestResponse) {
    return request.isViewOnly || ['Rejected', 'Canceled', 'Canceling', 'Picked Up', 'Completed'].some(s => s === request.requestStatus) ? 'hidden' : '';
  }

  mapSoftDeletion() {
    this.mappedRequests = this.requests
      .map(request => {
        return {
          ...request,
          isSoftDeleted: this.locationProfiles?.find(lp => lp.slicName === request.slicName)?.documentStatus === DocumentStatus.SoftDelete
        }
      });
  }

  trackByFunction(index: number, item: TrailerRequestResponse) {
    return item.requestId;
  }
}


