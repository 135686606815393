import { TrailerInfo } from "./trailerInfo";

export class TrailerLoad {
  trailerNumber: string = null;
  trailerType: string = null;
  pieceCount: number = null;
  percentFull: number = null;
  palletCount: number = null;
  gaylords: number = null;
  bags: number = null;
  irregs: number = null;
  originCountryCode: string = null;
  originOrganizationAbbreviatedName: string = null;
  originOrganizationNumber: string = null;
  originSortCode: string = null;
  destinationCountryCode: string = null;
  destinationOrganizationAbbreviatedName: string = null;
  destinationOrganizationNumber: string = null;
  destinationSortCode: string = null;
  serviceLevel: string = null;
  bayNumber: string = null;
  trailerInfo: TrailerInfo;
  hasUnplannedLoad: boolean = false;
  dueDate: string = null;
  isBobtail?: boolean = false;
}
