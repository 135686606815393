import { Injectable } from '@angular/core';

@Injectable()
export class StringService {
  //https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
  normalizeString(unformattedString: string) {
    let normalized = this.encodeForNormalization(unformattedString)?.normalize('NFD').replace(/\p{Diacritic}/gu, '') ?? '';
    return this.decodeNormalized(normalized);
  }

  encodeForNormalization(unnormalizedString: string): string {
    return unnormalizedString?.replace('^','__caret__');
  }

  decodeNormalized(normalizedString: string): string {
    return normalizedString?.replace('__caret__', '^');
  }
}
