export enum StandardRejectionOptionType {
  EARLIESTAVAILABLETIMEAFTERCRITICALPULLTIME = 0,
  NETWORKOVERCAPACITY = 1,
  SUBMITTEDAFTERREQUESTCUTOFFTIME = 2,
  VOLUMEDOESNOTJUSTIFYTRAILERPICKUP = 3,
  VOLUMEOVERALLOCATION = 4,
  WEATHER = 5,
  PEAKVOLUMEOVERALLOCATION = 6,
  UPSHOLIDAY = 7,
  CUSTOMERHASSUFFICIENTEQUIPMENT = 8,
  NOEQUIPMENTAVAILABLE = 9
}
