import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import { first, skip, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Sorter } from 'src/app/helpers/sort';
import { loadLocationList } from 'src/app/store/actions/location-list.actions';
import { refreshTrailerRequests } from 'src/app/store/actions/trailer-requests.actions';
import { Role } from 'src/app/types/role';
import { ApiService } from '../../api.service';
import { AppState } from '../../app.state';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { Comment } from '../../types/comment';
import { TrailerRequestResponse } from '../../types/trailerRequestResponse';



@Component({
  selector: 'comments',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModal extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() request: TrailerRequestResponse;
  @Input() modal;

  processing: boolean = false;
  newComment: string = "";
  customName: string = "";
  characters: number = 0;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  refreshPercent = 0;
  timerSeconds = 15;
  intervalMilliseconds = 50;
  lastReadTime: Date;
  commentsSorter: Sorter<Comment> = new Sorter<Comment>();
  paused: Subject<boolean> = new Subject<boolean>();
  currentMiliseconds: number = 0;
  submitError: boolean = false;

  constructor(private apiService: ApiService, private store: Store<AppState>, private authService: AuthService) {
    super();
    this.lastReadTime = new Date();
    this.paused.next(false);
    //refresh for comments
    timer(0, this.intervalMilliseconds).pipe(skip(1), takeUntil(this.disconnect$), withLatestFrom(this.paused)).subscribe(([time, paused]) => {
      if (!paused) {
        this.currentMiliseconds += this.intervalMilliseconds;
        const secondsPassed = this.currentMiliseconds / 1000;
        const timeUntilRefresh = secondsPassed % this.timerSeconds;
        this.refreshPercent = (timeUntilRefresh / this.timerSeconds) * 100;
        if (timeUntilRefresh === 0) {
          this.currentMiliseconds = 0;
          this.paused.next(true);
          this.loadComments();
          this.updateCommentIndicators();
        }
      }
    });
  }

  ngOnInit() {
    this.newComment = "";
    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(state =>
        this.customName = state.locations.find(loc => this.request.slicName == loc.slicName).profile.customName
      );
    this.loadComments(false);
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  submit() {
    this.submitError = false;

    try {
      if (!this.processing && this.newComment && this.newComment.trim() != "") {
        this.processing = true;
        let commentText = this.newComment.trim();
        this.currentMiliseconds = 0;
        this.paused.next(true);
        // Get the latest comments so we don't overwrite
        this.apiService.getComment(this.request.requestId)
          .subscribe({
            next: data => {
              this.request = data;
              this.request.isViewOnly = this.authService.hasLocationRole(this.request.slicName, Role.ReadonlyUser);

              let comment = new Comment();
              comment.commentId = this.request.comments.length > 0 ? Math.max.apply(Math, this.request.comments.map(function (c) { return c.commentId })) + 1 : 0;
              comment.userUpsId = this.authService.getUniqueName();
              comment.text = commentText;
              comment.localUnread = false;

              this.request.comments.push(comment);
              this.updateRead();
              this.apiService.addComment(this.request)
                .subscribe({
                  next: response => {
                    if (!(response?.ok || (response instanceof HttpErrorResponse && response.status === 504))) {
                      this.newComment = commentText;
                    }
                    this.paused.next(false);
                    this.processing = false;
                    this.newComment = "";
                  },
                  error: error => {
                    console.log(error);
                    this.submitError = true;
                    this.paused.next(false);
                    this.processing = false;
                  }
                });
            },
            error: error => {
              console.log(error);
              this.submitError = true;
              this.paused.next(false);
              this.processing = false;
            }
          });
      }
    } catch {
      this.paused.next(false);
      this.processing = false;
    }
  }

  dismissModal() {
    this.updateCommentIndicators();
    this.modal.close();
  }

  updateRead() {
    this.lastReadTime = new Date();
    this.request.comments.forEach(comment => {
      comment.localUnread = comment.localUnread || this.lastReadTime < new Date(comment.timeStamp);
    });
  }

  private loadComments(isReload: boolean = true) {

    const commentStatuses = {};
    this.request.comments.forEach(c => {
      commentStatuses[c.commentId] = c.localUnread;
    });

    this.apiService.getComment(this.request.requestId)
      .pipe(first())
      .subscribe(data => {
        //updates the status to whether or not it was read if not using the localUnread
        data.comments = data.comments.map(c => {
          c.localUnread = commentStatuses[c.commentId] ?? !c.read;
          return c;
        });

        this.request = data;
        this.request.isViewOnly = this.authService.hasLocationRole(this.request.slicName, Role.ReadonlyUser);

        this.markRead();
      });
  }

  private markRead() {
    // marks UPS comments that are unread with the indicator in the UI
    this.request.comments = this.request.comments.map(comment => {
      comment.localUnread = !comment.read && comment.systemName == "UPS";
      return comment;
    });

    if (!this.request.isViewOnly && this.request.comments.some(c => c.systemName == "UPS" && !c.read)) {
      const trsComments = this.request.comments.filter(c => c.systemName == "TRS");
      let upsComments = this.request.comments.filter(c => c.systemName == "UPS").map(c => ({ ...c, read: true }));
      const allComments = this.commentsSorter.sortValues(trsComments.concat(upsComments), 'commentId');
      this.request.comments = [...allComments];
      this.apiService.addComment(this.request)
        .pipe(first())
        .subscribe(response => {
          if (!(response?.ok || (response instanceof HttpErrorResponse && response.status === 504))) {
            console.log(response);
          }
          this.paused.next(false);
        });
    } else {
      this.paused.next(false);
    }
  }

  private updateCommentIndicators() {
    this.store.dispatch(refreshTrailerRequests());
    this.store.dispatch(loadLocationList());
  }
}
