// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toasts {
  position: absolute;
  top: 0;
  z-index: 2000;
  width: 100%;
}
.toasts .error-toast {
  width: 100%;
  background-color: #C91A13;
  color: white;
  min-height: 42px;
  font-size: 14px;
  font-weight: 700;
  display: grid;
  align-items: center;
  line-height: 2.25em;
  grid-template-columns: 118px [icon] min-content 6px [message] auto [close] min-content 118px;
}
.toasts .error-toast .message {
  grid-area: message;
}`, "",{"version":3,"sources":["webpack://./src/app/components/toast/toast.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,aAAA;EACA,WAAA;AACJ;AACI;EACI,WAAA;EACA,yBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,4FAAA;AACR;AACQ;EACI,kBAAA;AACZ","sourcesContent":[".toasts {\n    position: absolute;\n    top: 0;\n    z-index: 2000;\n    width: 100%;\n\n    .error-toast {\n        width: 100%;\n        background-color: #C91A13;\n        color: white;\n        min-height: 42px;\n        font-size: 14px;\n        font-weight: 700;\n        display: grid;\n        align-items: center;\n        line-height: 2.25em;\n        grid-template-columns: 118px [icon] min-content 6px [message] auto [close] min-content 118px;\n\n        .message {\n            grid-area: message;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
