import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { TrailerAsset } from "src/app/types/onPropertyData";
import { LocalizableComponent } from "../../../components/localizable/localizable.component";

@Component({
  selector: 'trailer',
  templateUrl: './trailer.component.html',
  styleUrls: ['./trailer.component.scss']
})

export class TrailerComponent extends LocalizableComponent implements OnInit {
  @Input() trailer: TrailerAsset;
  formattedDueDate: string = '';
  serviceLevelTranslate = { "EAM": "Early AM", "1DA": "Next Day Air", "2DA": "Second Day Air", "3DS": "Three Day Select", "GRD": "Ground" };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formattedDueDate = this.formatDateWithoutOffset(this.trailer.dueDate);
  }

  formatDateWithoutOffset(date: string | Date): string {
    return date && moment.utc(date).isValid() ? moment.utc(date).format('MM/DD') : '';
  }
}
