// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: sticky;
  top: 0;
  z-index: 10000;
  width: 100%;
}
:host .notification-location-data-stale-toast {
  background-color: #FFD100;
  height: 42px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
:host .notification-location-data-stale-toast .message {
  font-weight: 700;
  font-size: 14px;
  color: #212121;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdGlmaWNhdGlvbi1sb2NhdGlvbi1kYXRhLXN0YWxlLXRvYXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZ0JBQUE7RUFDQSxNQUFBO0VBQ0EsY0FBQTtFQUNBLFdBQUE7QUFDSjtBQUNJO0VBQ0kseUJBQUE7RUFDQSxZQUFBO0VBQ0EsYUFBQTtFQUNBLDZCQUFBO0VBQ0EsbUJBQUE7RUFDQSxlQUFBO0FBQ1I7QUFDUTtFQUNJLGdCQUFBO0VBQ0EsZUFBQTtFQUNBLGNBQUE7QUFDWiIsImZpbGUiOiJub3RpZmljYXRpb24tbG9jYXRpb24tZGF0YS1zdGFsZS10b2FzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBwb3NpdGlvbjogc3RpY2t5O1xuICAgIHRvcDogMDtcbiAgICB6LWluZGV4OiAxMDAwMDtcbiAgICB3aWR0aDogMTAwJTtcblxuICAgIC5ub3RpZmljYXRpb24tbG9jYXRpb24tZGF0YS1zdGFsZS10b2FzdCB7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6ICNGRkQxMDA7XG4gICAgICAgIGhlaWdodDogNDJweDtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1hcm91bmQ7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIGN1cnNvcjogcG9pbnRlcjtcblxuICAgICAgICAubWVzc2FnZSB7XG4gICAgICAgICAgICBmb250LXdlaWdodDogNzAwO1xuICAgICAgICAgICAgZm9udC1zaXplOiAxNHB4O1xuICAgICAgICAgICAgY29sb3I6ICMyMTIxMjE7XG4gICAgICAgIH1cbiAgICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/notification-location-data-stale-toast/notification-location-data-stale-toast.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,MAAA;EACA,cAAA;EACA,WAAA;AACJ;AACI;EACI,yBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,eAAA;AACR;AACQ;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AACZ;AACA,4lCAA4lC","sourcesContent":[":host {\n    position: sticky;\n    top: 0;\n    z-index: 10000;\n    width: 100%;\n\n    .notification-location-data-stale-toast {\n        background-color: #FFD100;\n        height: 42px;\n        display: flex;\n        justify-content: space-around;\n        align-items: center;\n        cursor: pointer;\n\n        .message {\n            font-weight: 700;\n            font-size: 14px;\n            color: #212121;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
