import { EditableLocationUser } from "./editableLocationUser";
import { OperatingHours } from "./operatingHours";

export interface LocationProfile {
  slicName: string;
  slicNumber: string;
  countryCode: string;
  crdSlicName: string;
  customName: string;
  forceReadOnly: boolean;
  locationEnabled: boolean;
  address: string;
  customer: string;
  telephoneNumber: string;
  email: string;
  users: number;
  userList: EditableLocationUser[];
  timezoneOffset: number;
  timezoneName: string;
  customNameLastUpdatedAt: string;
  customNameLastUpdatedBy: string;
  documentStatus: DocumentStatus;
  operatingHours: OperatingHours[];
  enableSameDayRequests: boolean;
  defaultResponsibleSite?: string;
  numberOfDestinations?: number;
}

export enum DocumentStatus {
  HardDelete = -1,
  Current = 0,
  SoftDelete = 1,
  New = 2
}
