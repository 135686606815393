// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select {
  background: #FFF;
  position: relative;
  border-color: #979797;
}

.ups-select-icon {
  display: inline-block;
}

.ups-select-icon:after {
  font-family: upsicons, fantasy !important;
  content: "\\e626" !important;
  color: #02857e;
  position: absolute;
  top: 10px;
  right: 15px;
  pointer-events: none;
}

.ups-select-icon.disabled:after {
  color: #6e6e6e;
}

:host {
  position: relative;
  display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVwcy1zZWxlY3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EscUJBQUE7QUFDRjs7QUFDQTtFQUNJLHFCQUFBO0FBRUo7O0FBQUE7RUFDRSx5Q0FBQTtFQUNBLDJCQUFBO0VBQ0EsY0FBQTtFQUNBLGtCQUFBO0VBQ0EsU0FBQTtFQUNBLFdBQUE7RUFDQSxvQkFBQTtBQUdGOztBQURBO0VBQ0ksY0FBQTtBQUlKOztBQURBO0VBQ0Usa0JBQUE7RUFDQSxhQUFBO0FBSUYiLCJmaWxlIjoidXBzLXNlbGVjdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5mb3JtLXNlbGVjdCB7XG4gIGJhY2tncm91bmQ6ICNGRkY7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgYm9yZGVyLWNvbG9yOiAjOTc5Nzk3O1xufVxuLnVwcy1zZWxlY3QtaWNvbntcbiAgICBkaXNwbGF5OmlubGluZS1ibG9jaztcbn1cbi51cHMtc2VsZWN0LWljb246YWZ0ZXIge1xuICBmb250LWZhbWlseTogdXBzaWNvbnMsIGZhbnRhc3kgIWltcG9ydGFudDtcbiAgY29udGVudDogJ1xcRTYyNicgIWltcG9ydGFudDtcbiAgY29sb3I6ICMwMjg1N2U7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAxMHB4O1xuICByaWdodDogMTVweDtcbiAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG59XG4udXBzLXNlbGVjdC1pY29uLmRpc2FibGVkOmFmdGVyIHtcbiAgICBjb2xvcjogIzZlNmU2ZTtcbn1cblxuOmhvc3Qge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIGRpc3BsYXk6IGZsZXg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/ups-select/ups-select.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AACA;EACI,qBAAA;AAEJ;;AAAA;EACE,yCAAA;EACA,2BAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,oBAAA;AAGF;;AADA;EACI,cAAA;AAIJ;;AADA;EACE,kBAAA;EACA,aAAA;AAIF;AACA,wlCAAwlC","sourcesContent":[".form-select {\n  background: #FFF;\n  position: relative;\n  border-color: #979797;\n}\n.ups-select-icon{\n    display:inline-block;\n}\n.ups-select-icon:after {\n  font-family: upsicons, fantasy !important;\n  content: '\\E626' !important;\n  color: #02857e;\n  position: absolute;\n  top: 10px;\n  right: 15px;\n  pointer-events: none;\n}\n.ups-select-icon.disabled:after {\n    color: #6e6e6e;\n}\n\n:host {\n  position: relative;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
