// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host.status-header .notification-type-header {
  padding: 0;
  padding-left: 28px;
}

.notification-type-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 28px;
  white-space: nowrap;
  font-weight: normal;
}
.notification-type-header > label {
  padding-top: 8px;
}
.notification-type-header > on-off-tri-toggle {
  padding: 11px 0;
}
.notification-type-header > div {
  padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/notification-settings/location-settings-grid/notification-type-header/notification-type-header.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,mBAAA;AADR","sourcesContent":[":host.status-header .notification-type-header {\n    padding: 0;\n    padding-left: 28px;\n}\n\n.notification-type-header {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    padding: 0 28px;\n    white-space: nowrap;\n    font-weight: normal;\n\n    >label {\n        padding-top: 8px;\n    }\n\n    >on-off-tri-toggle {\n        padding: 11px 0;\n    }\n\n    >div {\n        padding-bottom: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
