import { Injectable } from '@angular/core';
import { Role } from "../types/role";

@Injectable()

export class UserRoleService {
  hasRole(roles: number, role: Role): boolean {
    return !!(roles & role);
  }

  hasAccessRole(roles:number): boolean {
    return [Role.ReadonlyUser, Role.User, Role.Admin]
      .some(role =>  this.hasRole(roles, role));
  }

}
