// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-text {
  color: #F68B20;
}

.attention-dropdown > select {
  border: 1px solid #F68B20 !important;
}

.btn-green {
  color: white;
  background-color: #00857D;
  border-radius: 35px;
  width: 117px;
  height: 36px;
  font-weight: 400;
  font-size: 13px;
  margin-top: 15px;
}

.request-rejection {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 27px;
  min-width: 292px;
}
.request-rejection .selection {
  align-self: flex-start;
  text-align: left;
  width: 100%;
}

@media (min-width: 1880px) {
  .request-rejection {
    margin-bottom: 0px;
  }
  .request-rejection .reason {
    margin-bottom: 10px;
  }
  .request-rejection .selection {
    width: 360px;
  }
  .btn-green {
    margin-top: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/home/trailer-request/request-rejection/request-rejection.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,sBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAGA;EAEE;IAEE,kBAAA;EAFF;EAIE;IACE,mBAAA;EAFJ;EAKE;IACE,YAAA;EAHJ;EAOA;IACE,gBAAA;EALF;AACF","sourcesContent":[".confirmation-text {\n  color: #F68B20;\n}\n\n.attention-dropdown>select {\n  border: 1px solid #F68B20 !important;\n}\n\n.btn-green {\n  color: white;\n  background-color: #00857D;\n  border-radius: 35px;\n  width: 117px;\n  height: 36px;\n  font-weight: 400;\n  font-size: 13px;\n  margin-top: 15px;\n}\n\n.request-rejection {\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  margin-bottom: 27px;\n  min-width: 292px;\n\n  .selection {\n    align-self: flex-start;\n    text-align: left;\n    width: 100%;\n  }\n}\n\n@media (min-width:1880px) {\n\n  .request-rejection {\n\n    margin-bottom: 0px;\n    \n    .reason {\n      margin-bottom: 10px;\n    }\n\n    .selection {\n      width: 360px;\n    }\n  }\n\n  .btn-green {\n    margin-top: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
