// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e668" !important;
  color: #FFF;
}

.clear.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e67b" !important;
  color: #FFF;
}

label {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/user-access-history/user-access-history-search/user-access-history-search.component.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,2BAAA;EACA,WAAA;AACJ;;AAEA;EACE,yCAAA;EACA,2BAAA;EACA,WAAA;AACF;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".search.icon:before {\n    font-family: upsicons, fantasy !important;\n    content: \"\\e668\" !important;\n    color: #FFF;\n  }\n\n.clear.icon:before {\n  font-family: upsicons, fantasy !important;\n  content: \"\\e67b\" !important;\n  color:#FFF;\n}\n\nlabel {\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
