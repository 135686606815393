import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { DateService } from '../../../services/date.service';
import { LangSection, LangText } from '../../../types/language';
import { LocationProfile } from '../../../types/locationProfile';
import { StandardRejectionOptionType } from '../../../types/standardRejectionOptionType';
import { TrailerRequestResponse } from '../../../types/trailerRequestResponse';
import { LocalizeCancelReasonService } from 'src/app/services/localize-cancel-reason.service';


@Component({
  selector: 'request-progress',
  templateUrl: './request-progress.component.html',
  styleUrls: ['./request-progress.component.scss']
})
export class RequestProgressComponent extends LocalizableComponent implements OnInit {
  @Input() request: TrailerRequestResponse;
  statuses: string[];
  earliestPickupDate: Date;
  locationProfile: LocationProfile = null;
  disconnect$: Subject<boolean> = new Subject<boolean>();


  //Hardcoded Peak season dates. TODO: Make configurable
  //peakSeasonStartDate: Date = new Date(2021, 11 - 1, 20, 0, 0, 0);
  //peakSeasonEndDate: Date = new Date(2022, 1 - 1, 15, 23, 59, 59);
  peakSeasonStartDate: Date = new Date(2019, 10 - 1, 12, 0, 0, 0);
  peakSeasonEndDate: Date = new Date(2020, 10 - 1, 23, 23, 59, 59);
  cancelReason: string = "";
  rejectionReason: string = "";

  constructor(private dateService: DateService, private store: Store<AppState>, private localizeCancelReasonService: LocalizeCancelReasonService) {
    super();
  }

  ngOnInit() {
    this.statuses = ['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property', 'Completed'];
    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(state =>
        this.locationProfile = state.locations.find(loc => this.request.slicName == loc.slicName)?.profile
      );

    this.earliestPickupDate = new Date(this.request.earliestPickupDateTime);

    this.cancelReason = this.localizeCancelReasonService.localizeCancelReasonString(this.request.cancelReason);
    this.rejectionReason = this.getRejectionReason();
    this.validatePeakSeason();
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  isStatusPending(status) {
    return this.statuses.indexOf(status) > this.statuses.indexOf(this.request.requestStatus);
  }
  isStatusActive(status) {
    return this.statuses.indexOf(status) === this.statuses.indexOf(this.request.requestStatus);
  }
  isStatusComplete(status) {
    return status != 'Completed' ? this.statuses.indexOf(status) < this.statuses.indexOf(this.request.requestStatus) : this.statuses.indexOf(status) === this.statuses.indexOf(this.request.requestStatus);
  }

  private validatePeakSeason() {
    let showPeakSeasonWarning = false;

    if (this.request.requestCategory === 'Ad Hoc' &&
      this.request.requestStatus === 'Pending' &&
      this.earliestPickupDate &&
      this.earliestPickupDate >= this.peakSeasonStartDate &&
      this.earliestPickupDate <= this.peakSeasonEndDate) {

      let earliestDate = new Date(this.earliestPickupDate.getFullYear(), this.earliestPickupDate.getMonth(), this.earliestPickupDate.getDate(), 0, 0, 0);

      // if we have the location's offset (we should) use location's current time
      let currentDateTime = (this.locationProfile && this.locationProfile.timezoneOffset) ?
        this.dateService.getDateTimeForLocation(this.locationProfile.timezoneOffset) : new Date();

      // Do not show warning if after 5 am if earliest date 
      let currentDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), 0, 0, 0);
      let currentDateTimeLimit = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), 5, 0, 0);

      showPeakSeasonWarning = this.dateService.datesAreEqual(earliestDate, currentDate) ? currentDateTime.getTime() <= currentDateTimeLimit.getTime() ? true : false : true;

    } else {
      showPeakSeasonWarning = false;
    }
    if (showPeakSeasonWarning) {
      this.request.peakSeasonWarning =
        this.localize(this.langSection.RequestFormPage, this.langText.PeakSeasonDateRangeWarning) +
        this.dateService.getLocalizedMonthDateFormat(this.earliestPickupDate) + '.';
    } else {
      this.request.peakSeasonWarning = null;
    }

  }

  getRejectionReason() {

    if (this.request.selectedCustomerRejectionOption === null && !this.request.customerRejectionOptions.some(r => !r.disable) && this.request.customerRejectionOptions.some(r => r.startDate)) {
      return this.localize(LangSection.RequestRejection, LangText.NoResponseFromCustomer);
    }

    switch (this.request.standardRejectionReason) {
      case StandardRejectionOptionType.EARLIESTAVAILABLETIMEAFTERCRITICALPULLTIME: {
        return this.localize(LangSection.RequestRejection, LangText.EarliestTimeAfterCriticalPullTime);
      }
      case StandardRejectionOptionType.NETWORKOVERCAPACITY: {
        return this.localize(LangSection.RequestRejection, LangText.NetworkOverCapacity);
      }
      case StandardRejectionOptionType.SUBMITTEDAFTERREQUESTCUTOFFTIME: {
        return this.localize(LangSection.RequestRejection, LangText.SubmittedAfterCutoffTime);
      }
      case StandardRejectionOptionType.VOLUMEDOESNOTJUSTIFYTRAILERPICKUP: {
        return this.localize(LangSection.RequestRejection, LangText.VolumeDoesNotJustifyPickup);
      }
      case StandardRejectionOptionType.VOLUMEOVERALLOCATION: {
        return this.localize(LangSection.RequestRejection, LangText.VolumeOverAllocation);
      }
      case StandardRejectionOptionType.WEATHER: {
        return this.localize(LangSection.RequestRejection, LangText.Weather);
      }
      case StandardRejectionOptionType.PEAKVOLUMEOVERALLOCATION: {
        return this.localize(LangSection.RequestRejection, LangText.PeakVolumeOverAllocation);
      }
      case StandardRejectionOptionType.UPSHOLIDAY: {
        return this.localize(LangSection.RequestRejection, LangText.UPSHoliday);
      }
      case StandardRejectionOptionType.CUSTOMERHASSUFFICIENTEQUIPMENT: {
        return this.localize(LangSection.RequestRejection, LangText.CustomerHasSufficientEquipment);
      }
      case StandardRejectionOptionType.NOEQUIPMENTAVAILABLE: {
        return this.localize(LangSection.RequestRejection, LangText.NoEquipmentAvailable);
      }
      default: {
        return this.request.rejectReason;
      }
    }
  }
}
