import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class MapLoaderService {

  loadPromise: Promise<any>;
  isProduction: boolean = false;
  apiKey: string;

  constructor(public environmentService: EnvironmentService) {
    this.environmentService.environment$
      .pipe(filter(env => !!env), take(1))
      .subscribe(environment => {
        this.isProduction = environment.production;
        this.apiKey = environment.bingMapsApiKey;
      });
  }

  public load() {
    // Do not allow in production for now
    if (this.isProduction)
      return;

    const url = `https://www.bing.com/api/maps/mapcontrol?callback=__onBingLoaded&branch=${this.determineBingType(this.isProduction)}&key=${this.apiKey}`;
    if (!this.loadPromise) {
      this.loadPromise = new Promise(resolve => {

        // Set callback for when bing maps is loaded.
        window['__onBingLoaded'] = () => {
          resolve('Bing Maps API loaded');
        };

        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return this.loadPromise;
  }

  public determineBingType(prod: boolean) {
    if (prod) {
      return 'release';
    } else {
      return 'experimental';
    }
  }
}
