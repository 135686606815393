import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownOption } from '../../types/dropdownOption';

@Component({
  selector: 'ups-select',
  templateUrl: './ups-select.component.html',
  styleUrls: ['./ups-select.component.scss']
})
export class UpsSelectComponent implements OnInit {
  options: DropdownOption[] = [];
  disconnect$: Subject<boolean> = new Subject<boolean>();
  @Input() revertSelection: Observable<number> = null;
  @Input() value: string = null;
  @Input() readonly: boolean = false;
  @Input()
  set list(input: Array<string | { name: string, value: string }>) {
    if (!input || typeof (input) !== 'object') return;
    let isInit = this.options.length === 0;
    if (typeof (input[0]) === 'string') {
      this.options = (input as Array<string>).map(str => ({ name: str, value: str }));
    } else {
      this.options = input as DropdownOption[];
    }

    //If the options were updated and the current selection does not exist, default to the first
    if (!isInit && !this.options.some(o => o.value === this.value)) {
      this.value = this.options.length > 0 ? this.options[0].value : "";
    }
  }

  ngOnInit() {
    this.revertSelection?.pipe(takeUntil(this.disconnect$)).subscribe(x => this.value = x.toString());
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

}
