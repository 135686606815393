import { Component, Input } from "@angular/core";

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: [
    './notification.component.scss'
  ]
})
export class NotificationComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() allowClose: boolean = true;
  isVisible: boolean = true;

  hide() {
    this.isVisible = false;
  }
}
