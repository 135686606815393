import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizableComponent } from '../localizable/localizable.component';

@Component({
  selector: 'on-off-toggle',
  templateUrl: './on-off-toggle.component.html',
  styleUrls: ['./on-off-toggle.component.scss']
})
export class OnOffToggleComponent extends LocalizableComponent {
  @Input() boolValue: boolean = false;
  @Input() active: boolean = false;
  @Output() onChange = new EventEmitter<boolean>();

  toggle() {
    if (!this.active)
      return;
    
    this.boolValue = !this.boolValue;
    this.onChange.emit(this.boolValue);
  }
}
