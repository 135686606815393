// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: none;
}

.modal-footer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-top: none;
}

p {
  line-height: 13px;
}

textarea {
  resize: none;
  width: 100%;
  border-radius: 0;
  min-height: 70px;
}

.remove-user-modal {
  padding: 10px;
  max-width: 900px;
  width: 100%;
}

.btn-green {
  color: white;
  background-color: #00857D;
  border-radius: 35px;
  width: 97px;
  height: 36px;
}

.btn-white {
  color: #00857D;
  background-color: white;
  border-radius: 35px;
  border: thin #00857D solid;
  width: 97px;
  height: 36px;
  margin-left: 5px;
}

.error {
  color: red;
}

.t-error::placeholder {
  color: red;
  font-size: 1.5em;
  text-align: right;
  font-weight: bold;
}

.t-error {
  border-color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/remove-user-modal/remove-user-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AACA;EACE,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAEF;;AACA;EACI,UAAA;AAEJ;;AACA;EACI,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAEJ;;AACA;EACI,iBAAA;AAEJ","sourcesContent":[".modal-header {\n    border-bottom: none;\n}\n\n.modal-footer{\n    display: flex;\n    flex-flow: column;\n    justify-content: center;\n    align-items: center;\n    border-top: none;\n}\n\np {\n    line-height: 13px;\n}\n\ntextarea {\n    resize: none;\n    width: 100%;\n    border-radius: 0;\n    min-height: 70px;\n}\n\n.remove-user-modal {\n    padding: 10px;\n    max-width: 900px;\n    width: 100%;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n    border-radius: 35px;\n    width: 97px;\n    height: 36px;\n}\n.btn-white {\n  color: #00857D;\n  background-color: white;\n  border-radius: 35px;\n  border: thin #00857D solid;\n  width: 97px;\n  height: 36px;\n  margin-left: 5px;\n}\n\n.error {\n    color: red;\n}\n\n.t-error::placeholder {\n    color: red;\n    font-size: 1.5em;\n    text-align: right;\n    font-weight: bold;\n}\n\n.t-error {\n    border-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
