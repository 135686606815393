// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location {
  width: 328px;
  height: 69px;
  padding: 11px;
  padding-top: 10px;
  padding-bottom: 9px;
  font-weight: normal;
  line-height: 16px;
  display: grid;
  grid-template-areas: "slic users" "name edit" "address address";
  row-gap: 1px;
}
.location div {
  height: 16px;
}
.location div:nth-child(1) {
  grid-area: slic;
  font-weight: 700;
}
.location div:nth-child(2) {
  grid-area: users;
  text-align: right;
}
.location div:nth-child(3) {
  grid-area: name;
  overflow: hidden;
  text-overflow: ellipsis;
}
.location div:nth-child(4) {
  grid-area: edit;
  text-align: right;
}
.location div:nth-child(5) {
  grid-area: address;
  overflow: hidden;
  text-overflow: ellipsis;
}

a {
  text-decoration: underline;
  color: #426DA9;
  font-size: 14px;
}

.filtered {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/location-user-grid/location-header/location-header.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,+DACE;EAGF,YAAA;AAFF;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,eAAA;EACA,gBAAA;AAHJ;AAME;EACE,gBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;AALJ;AAQE;EACE,eAAA;EACA,iBAAA;AANJ;AASE;EACE,kBAAA;EACA,gBAAA;EACA,uBAAA;AAPJ;;AAWA;EACE,0BAAA;EACA,cAAA;EACA,eAAA;AARF;;AAWA;EACE,qBAAA;EACA,iBAAA;EACA,cAAA;AARF","sourcesContent":[".location {\n  width: 328px;\n  height: 69px;\n  padding: 11px;\n  padding-top: 10px;\n  padding-bottom: 9px;\n  font-weight: normal;\n  line-height: 16px;\n  display: grid;\n  grid-template-areas: \n    \"slic users\"\n    \"name edit\"\n    \"address address\";\n  row-gap: 1px;\n\n  div {\n    height: 16px;\n  }\n\n  div:nth-child(1) {\n    grid-area: slic;\n    font-weight: 700;\n  }\n\n  div:nth-child(2) {\n    grid-area: users;\n    text-align: right;\n  }\n\n  div:nth-child(3) {\n    grid-area: name;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  div:nth-child(4) {\n    grid-area: edit;\n    text-align: right;\n  }\n\n  div:nth-child(5) {\n    grid-area: address;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n\na {\n  text-decoration: underline;\n  color: #426DA9;\n  font-size: 14px;\n}\n\n.filtered {\n  text-decoration: none;\n  font-weight: bold;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
