import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { refreshTrailerRequests, updateTrailerRequest } from 'src/app/store/actions/trailer-requests.actions';
import { DocumentStatus, LocationProfile } from 'src/app/types/locationProfile';
import { ApiService } from '../../../api.service';
import { AppState } from '../../../app.state';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { DropdownOption } from '../../../types/dropdownOption';
import { LangSection, LangText } from '../../../types/language';
import { StandardRejectionOptionType } from '../../../types/standardRejectionOptionType';
import { TrailerRequestResponse } from '../../../types/trailerRequestResponse';

@Component({
  selector: 'request-rejection',
  templateUrl: './request-rejection.component.html',
  styleUrls: ['./request-rejection.component.scss']
})
export class RequestRejectionComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() request: TrailerRequestResponse;
  
  reason: string;
  confirmation: string;
  responseOptions: DropdownOption[] = [];
  selection: number = null;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  locationProfile: LocationProfile = null;
  submitting: boolean = false;

  constructor(private apiService: ApiService, private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.confirmation = '';
    this.reason = this.getRejectionReason();
    this.buildDropdown();
    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(locationList => {
        const location = locationList?.locations?.find(loc => this.request.slicName == loc.slicName);
        if (location) {
          this.locationProfile = location.profile;
        }
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  submit() {
    if (this.selection) {
      try {
        this.submitting = true;
        let option = parseInt(this.selection.toString());

        let apiRequest = {...this.request};
        apiRequest.selectedCustomerRejectionOption = option;
        apiRequest.attentionNeeded = false;

        if (option == -1) {
          apiRequest.requestStatus = "Canceling";
          this.apiService.cancelRequest(apiRequest)
          .pipe(takeUntil(this.disconnect$))
          .subscribe(
            _ => {
              this.request = apiRequest;
              this.store.dispatch(updateTrailerRequest(this.request.requestId, this.request));
              setTimeout(() => this.store.dispatch(refreshTrailerRequests()), 5000);
            }, 
            error => {
              console.log(error);
              this.submitting = false;
            }
          );
        }
        else {
          this.apiService.editRequest(apiRequest, false)
          .pipe(takeUntil(this.disconnect$))
          .subscribe(response => {
            if (response?.ok || (response instanceof HttpErrorResponse && response.status === 504)) {
              this.request = apiRequest;
              this.store.dispatch(updateTrailerRequest(this.request.requestId, this.request));
            } else {
              console.log(response);
              this.submitting = false;
            }
          });
        }
      }
      catch (err) {
        this.submitting = false;
        console.log(err);
      }
    }
  }

  getRejectionReason() {
    switch (this.request.standardRejectionReason) {
      case StandardRejectionOptionType.EARLIESTAVAILABLETIMEAFTERCRITICALPULLTIME: {
        return this.localize(LangSection.RequestRejection, LangText.EarliestTimeAfterCriticalPullTime);
      }
      case StandardRejectionOptionType.NETWORKOVERCAPACITY: {
        return this.localize(LangSection.RequestRejection, LangText.NetworkOverCapacity);
      }
      case StandardRejectionOptionType.SUBMITTEDAFTERREQUESTCUTOFFTIME: {
        return this.localize(LangSection.RequestRejection, LangText.SubmittedAfterCutoffTime);
      }
      case StandardRejectionOptionType.VOLUMEDOESNOTJUSTIFYTRAILERPICKUP: {
        return this.localize(LangSection.RequestRejection, LangText.VolumeDoesNotJustifyPickup);
      }
      case StandardRejectionOptionType.VOLUMEOVERALLOCATION: {
        return this.localize(LangSection.RequestRejection, LangText.VolumeOverAllocation);
      }
      case StandardRejectionOptionType.WEATHER: {
        return this.localize(LangSection.RequestRejection, LangText.Weather);
      }
      case StandardRejectionOptionType.PEAKVOLUMEOVERALLOCATION: {
        return this.localize(LangSection.RequestRejection, LangText.PeakVolumeOverAllocation);
      }
      case StandardRejectionOptionType.UPSHOLIDAY: {
        return this.localize(LangSection.RequestRejection, LangText.UPSHoliday);
      }
      case StandardRejectionOptionType.CUSTOMERHASSUFFICIENTEQUIPMENT: {
        return this.localize(LangSection.RequestRejection, LangText.CustomerHasSufficientEquipment);
      }
      case StandardRejectionOptionType.NOEQUIPMENTAVAILABLE: {
        return this.localize(LangSection.RequestRejection, LangText.NoEquipmentAvailable);
      }
      default: {
        return '';
      }
    }


  }

  onChange(event: Event) {
    this.selection = event.target['options'][event.target['options'].selectedIndex].value;

    if (this.selection) {
      if (this.selection == -1) {
        this.confirmation = this.localize(LangSection.RequestRejection, LangText.RequestMovedToCanceledStatus);
      } else {
        this.confirmation = this.localize(LangSection.RequestRejection, LangText.RequestMovedTo) + this.request.customerRejectionOptions[this.selection].startDate;
      }
    }
  }

  buildDropdown() {

    this.request.customerRejectionOptions.forEach((option, index) => {
      if (!option.disable && option.startDate) {

        let optionText = this.localize(LangSection.RequestRejection, LangText.FulfillRequestOn) + option.startDate;
        if (option.endDate) {
          optionText += this.localize(LangSection.RequestRejection, LangText.AndProcessOn) + option.endDate;
        }

        this.responseOptions.push({
          name: optionText,
          value: index.toString()
        });
      }
    });

    this.responseOptions.push({
      name: this.localize(LangSection.RequestRejection, LangText.CancelResponse),
      value: '-1'
    });
  }

  documentStatus(): typeof DocumentStatus {
    return DocumentStatus;
  }

}
