import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';

@Component({
  selector: 'app-default-responsible-site-information-modal',
  templateUrl: './default-responsible-site-information-modal.component.html',
  styleUrls: ['./default-responsible-site-information-modal.component.scss']
})
export class DefaultResponsibleSiteInformationModalComponent extends LocalizableComponent {
 
  @Input() defaultResponsibleSitePhone: string;
  @Input() defaultResponsibleSiteEmail: string;
  @Input() defaultResponsibleSiteCompanyName: string;

  constructor(public activeModal: NgbActiveModal) {
    super();
  }

  goBack() {
    this.activeModal.close(true);
  }
  
  dismissModal() {
    this.activeModal.close(true);
  }
}
