// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
  width: 100%;
  height: 100%;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Roboto", Tahoma, Helvetica, sans-serif;
  color: #AEAEAE;
  font-size: 31px;
  font-weight: 500;
  padding: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/on-property-map/on-property-map.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,oDAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".map {\n    width: 100%;\n    height: 100%;\n}\n\n.message {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    font-family: \"Roboto\", Tahoma, Helvetica, sans-serif;\n    color: #AEAEAE;\n    font-size: 31px;\n    font-weight: 500;\n    padding: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
