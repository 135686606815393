import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestSubForm } from '../request-sub-form.component';

@Component({
  selector: 'switch-request-form',
  templateUrl: './switch-request-form.component.html',
  styleUrls: []
})
export class SwitchRequestFormComponent extends RequestSubForm {
  pageName = 'RequestSwitch';
}
