import { Component } from "@angular/core";
import { LocalizableComponent } from "../localizable/localizable.component";

@Component({
  selector: 'storage-account-down-toast',
  templateUrl: './storage-account-down-toast.component.html',
  styleUrls: [
    './storage-account-down-toast.component.scss'
  ]
})
export class StorageAccountDownToast extends LocalizableComponent {
  isVisible: boolean = true;

  constructor () {
    super();
  }
}
