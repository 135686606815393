import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { sortUsers } from 'src/app/store/actions/location-users.actions';
import { Sort } from 'src/app/types/states/locationUsersState';
import { UserWithLocations } from '../../types/userWithLocations';

@Component({
  selector: 'location-users-user-sort',
  templateUrl: './user-sort.component.html',
  styleUrls: ['./user-sort.component.scss']
})
export class UserSortComponent extends LocalizableComponent implements OnDestroy, OnInit {

  public selectedSort: keyof(UserWithLocations) | 'custom' = 'upsId';
  public storedCustomSort: {field?: keyof(UserWithLocations) | 'custom', locationCountryCode?: string, locationSlicNumber?: string} = {field: null, locationCountryCode: '', locationSlicNumber: ''};

  public sortOptions: {name: string, value: keyof(UserWithLocations) | 'custom'}[] = [
    {name: this.localize(this.langSection.LocationUserPage, this.langText.UserId), value:'upsId'},
    {name: this.localize(this.langSection.LocationUserPage, this.langText.Email), value:'email'},
    {name: this.localize(this.langSection.LocationUserPage, this.langText.NumberOfLocations), value: 'accessibleLocations'}
  ];

  public isAscending: boolean = true;
  private disconnect$ = new Subject<boolean>();

  constructor(private store: Store<AppState>) {
    super();

    store.pipe(takeUntil(this.disconnect$)).subscribe(state => {
      const sort = state.locationUsers.userSort;

      if(sort.customSortValues && Object.keys(sort.customSortValues).length > 0) {
        const previousSearch = this.sortOptions.find(option => option.value == this.selectedSort);
        if(sort.customSortValues?.slicCountryCode && sort.customSortValues?.slicNumber) {
          this.storedCustomSort.locationCountryCode = sort.customSortValues.slicCountryCode;
          this.storedCustomSort.locationSlicNumber = sort.customSortValues.slicNumber;
        }
        if(previousSearch.value != 'custom') {
          this.storedCustomSort.field = previousSearch.value;
          this.sortOptions = this.sortOptions.filter(option => option.value !== 'custom');
          this.sortOptions.push({name: `${previousSearch.name} (${this.localize(this.langSection.Term, this.langText.Custom)})`, value: 'custom'});
        }
      
        this.selectedSort = 'custom';
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(sortUsers({ field: this.selectedSort as keyof(UserWithLocations), isAscending: this.isAscending}));
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();

    this.store.dispatch(sortUsers({ field: 'upsId', isAscending: true, customSortValues: null }));
  }

  updateSort() {
    let params: Sort<UserWithLocations> = { field: this.selectedSort as keyof(UserWithLocations), isAscending: this.isAscending};

    if(this.selectedSort == 'custom') {
      params.customSortValues = {...this.storedCustomSort};
    } else {
      this.sortOptions = this.sortOptions.filter(option => option.value != 'custom');
    }

    this.store.dispatch(sortUsers(params));
  }

  updateUserSort(key: keyof(UserWithLocations)) {
    this.selectedSort = key;
    this.updateSort();
  }

  toggleAscending() {
    this.isAscending = !this.isAscending;
    this.updateSort();
  }
}
