// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 128px;
  height: 36px;
  border: 1px solid #02857D;
  cursor: pointer;
}
.toggle-container.deactivated {
  cursor: default;
  border-color: #E8E8E6;
}
.toggle-container.deactivated .on-half {
  border: none;
}
.toggle-container.deactivated .off-half.selected {
  background-color: #E8E8E6;
}
.toggle-container.deactivated .off-half.selected span {
  color: #bebebe;
}

.on-half {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #02857D;
}
.on-half span {
  visibility: hidden;
  margin: 0 0.25rem;
}
.on-half.selected {
  background-color: #02857D;
}
.on-half.selected span {
  visibility: visible;
  color: white;
}

.off-half {
  display: flex;
  justify-content: center;
  align-items: center;
}
.off-half span {
  visibility: hidden;
  margin: 0 0.25rem;
}
.off-half.selected {
  background-color: #EDEDED;
}
.off-half.selected span {
  visibility: visible;
  color: #242424;
}`, "",{"version":3,"sources":["webpack://./src/app/components/on-off-toggle/on-off-toggle.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AACJ;AACI;EACI,eAAA;EACA,qBAAA;AACR;AACQ;EACI,YAAA;AACZ;AAEQ;EACI,yBAAA;AAAZ;AAEY;EACI,cAAA;AAAhB;;AAMA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,+BAAA;AAHJ;AAKI;EACI,kBAAA;EACA,iBAAA;AAHR;AAMI;EACI,yBAAA;AAJR;AAMQ;EACI,mBAAA;EACA,YAAA;AAJZ;;AASA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AANJ;AAQI;EACI,kBAAA;EACA,iBAAA;AANR;AASI;EACI,yBAAA;AAPR;AASQ;EACI,mBAAA;EACA,cAAA;AAPZ","sourcesContent":[".toggle-container {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    min-width: 128px;\n    height: 36px;\n    border: 1px solid #02857D;\n    cursor: pointer;\n\n    &.deactivated {\n        cursor: default;\n        border-color: #E8E8E6;\n\n        .on-half {\n            border: none;\n        }\n\n        .off-half.selected {\n            background-color: #E8E8E6;\n            \n            span {\n                color: #bebebe;\n            }\n        }\n    }\n}\n\n.on-half {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-right: 1px solid #02857D;\n    \n    span {\n        visibility: hidden;\n        margin: 0 .25rem;\n    }\n\n    &.selected {\n        background-color: #02857D;\n        \n        span {\n            visibility: visible;\n            color: white;\n        }\n    }\n}\n\n.off-half {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n    span {\n        visibility: hidden;\n        margin: 0 .25rem;\n    }\n\n    &.selected {\n        background-color: #EDEDED;\n\n        span {\n            visibility: visible;\n            color: #242424;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
