import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from '../api.service';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { DateService } from '../services/date.service';
import { LangSection, LangText } from '../types/language';
import { Role } from '../types/role';
import { ActivityType, UserAccessHistory, UserAccessHistoryAudit } from '../types/userAccessHistory';

@Component({
  selector: 'user-access-history',
  templateUrl: './user-access-history.component.html',
  styleUrls: ['./user-access-history.component.scss']
})
export class UserAccessHistoryComponent extends LocalizableComponent implements OnInit, OnDestroy {
  breadcrumbs: { name: string, url: string }[] = [
    { name: this.localize(LangSection.Breadcrumb, LangText.Home) , url: "/" },
    { name: this.localize(LangSection.Breadcrumb, LangText.LocationUserManagement), url: "/location-users"},
    { name: this.localize(LangSection.Breadcrumb, LangText.UserAccessHistory), url: "/user-access-history"}
  ];
  disconnect$: Subject<boolean> = new Subject<boolean>();
  entryCount: number = 0;
  userAccessHistory: UserAccessHistory;
  id: string;
  upsId: string;
  email: string;
  filteredAudits: UserAccessHistoryAudit[];

  constructor(private activeRoute: ActivatedRoute, private apiService: ApiService, public dateService: DateService) {
    super();
  }

  ngOnInit(): void {
    const params = new URLSearchParams(window.location.href);
    const id = params.get('id');
    if (id) {
      this.apiService.getUserAccessHistory(id).subscribe(accessHistory => {
        this.userAccessHistory = accessHistory;
        this.upsId = accessHistory.upsId;
        this.email = accessHistory.email;
        this.filteredAudits = accessHistory.audits;
      });
    }
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  getRoleText(role: Role) {
    if (role == Role.Admin || role == Role.PendingAdmin) {
        return "Administrator";
    } else if (role == Role.User || role == Role.PendingUser) {
      return "ViewEdit";
    } else if (role == Role.ReadonlyUser || role == Role.PendingReadOnly) {
        return "ViewOnly";
    }
  }

  getActivityText(activity: ActivityType) {
    switch (activity) {
      case ActivityType.Added:
        return "Added";
      case ActivityType.Approved:
        return "Approved";
      case ActivityType.Denied:
        return "Denied";
      case ActivityType.Removed:
        return "Removed";
      case ActivityType.Submitted:
        return "Submitted";
      case ActivityType.Updated:
        return "Updated";
    }
  }

  setFilteredList(event: UserAccessHistoryAudit[]) {
    this.filteredAudits = event;
  }

}
