export class Comment {
  commentId: number = 0;
  userUpsId: string = '';
  timeStamp: string = '';
  text: string = '';
  read: boolean;
  systemName: string = '';
  localUnread: boolean;

  constructor() {
    this.timeStamp = new Date().toISOString();
    this.read = false;
    this.systemName = "TRS";
  }
}
