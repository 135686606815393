// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep ngb-typeahead-window.dropdown-menu {
  max-height: 200%;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  ::ng-deep ngb-typeahead-window.dropdown-menu {
    width: 100%;
  }
  ::ng-deep ngb-typeahead-window.dropdown-menu .dropdown-item {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/location-address-search-input/location-address-search-input.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;AACI;EAJJ;IAKQ,WAAA;EAEN;EAAM;IACI,2BAAA;IACA,kCAAA;EAEV;AACF","sourcesContent":["::ng-deep ngb-typeahead-window.dropdown-menu{\n    max-height: 200%;\n    overflow-y: auto;\n    \n    @media screen and (max-width: 767px) {\n        width: 100%;\n\n        .dropdown-item{\n            overflow: hidden !important;\n            text-overflow: ellipsis !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
