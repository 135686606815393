// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1880px) {
  .xl-d-none {
    display: none !important;
  }
}
.move-up {
  margin-top: -20px;
}

.col, .col-lg-5 {
  padding: 0px !important;
  line-height: 17px;
}

.pending-removal {
  color: #6E6E6E;
  font-weight: 400;
  white-space: nowrap;
}

.request-info {
  display: grid;
  grid-template-columns: auto 1fr;
  white-space: nowrap;
  column-gap: 16px;
}
.request-info > div {
  display: contents;
}`, "",{"version":3,"sources":["webpack://./src/app/home/trailer-request/request-info/request-info.component.scss"],"names":[],"mappings":"AACA;EACE;IACE,wBAAA;EAAF;AACF;AAGA;EACE,iBAAA;AADF;;AAIA;EACE,uBAAA;EACA,iBAAA;AADF;;AAIA;EACE,cAAA;EACA,gBAAA;EACA,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,+BAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAGE;EACE,iBAAA;AADJ","sourcesContent":["\n@media (min-width:1880px) {\n  .xl-d-none {\n    display: none !important;\n  }\n}\n\n.move-up {\n  margin-top: -20px;\n}\n\n.col, .col-lg-5 {\n  padding: 0px!important;\n  line-height: 17px;\n}\n\n.pending-removal {\n  color: #6E6E6E;\n  font-weight: 400;\n  white-space: nowrap;\n}\n\n.request-info {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  white-space: nowrap;\n  column-gap: 16px;\n\n  & > div {\n    display: contents;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
