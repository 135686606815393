import { Injectable } from '@angular/core';
import { LangText } from '../types/language';
import { LocalizableComponent } from '../components/localizable/localizable.component';


@Injectable({
  providedIn: 'root'
})
export class LocalizeCancelReasonService extends LocalizableComponent {

  constructor() {
    super();
  }

  localizeCancelReasonString(value: string) {
    if (value) {
      // Remove any mention of (PVAT)
      value = value.replace('(PVAT)', '');

      let cancelReasonSplit = value.split(':');
      let localizedReason = this.localize(this.langSection.CancelModal, cancelReasonSplit[0].trim() as LangText) || cancelReasonSplit[0].trim();

      // remove the reason from the string since it is stored in localizedReason
      cancelReasonSplit.shift();

      //add any additional text if it exists
      let joinedRemarks = cancelReasonSplit.join();
      return joinedRemarks ? `${localizedReason}: ${joinedRemarks}` : localizedReason;
    } else {
      return "";
    }
  }
}
