// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel {
  padding-left: 30px;
  padding-bottom: 20px;
  padding-right: 10px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-body {
  padding-bottom: 0;
}

.modal-body, .modal-footer {
  padding-right: 40px;
}

.modal-footer {
  padding-top: 0;
  padding-bottom: 10px;
  border-top: none;
}

.btn {
  margin-top: 28px;
  height: 36px;
  border-radius: 100px;
  border: 0;
  padding-right: 28px;
  padding-left: 28px;
}

.btn-green {
  color: white;
  background-color: #00857D;
}

.btn-white {
  color: #00857D;
  background-color: white;
  border: 1px solid #00857D;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/add-location-users/new-user-queue/queue-user-modal/queue-user-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,oBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,oBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,uBAAA;EACA,yBAAA;AACJ","sourcesContent":[".cancel {\n    padding-left: 30px;\n    padding-bottom: 20px;\n    padding-right: 10px;\n}\n\n.modal-header {\n    border-bottom: none;\n    padding-bottom: 0;\n}\n\n.modal-body {\n    padding-bottom: 0;\n}\n\n.modal-body, .modal-footer {\n    padding-right: 40px;\n}\n\n.modal-footer {\n    padding-top: 0;\n    padding-bottom: 10px;\n    border-top: none;\n}\n\n.btn {\n    margin-top: 28px;\n    height: 36px;\n    border-radius: 100px;\n    border: 0;\n    padding-right: 28px;\n    padding-left: 28px;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n}\n\n.btn-white {\n    color: #00857D;\n    background-color: white;\n    border: 1px solid #00857D;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
