import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Role } from "../types/role";
import { LocationUser } from '../types/locationUser';

@Injectable()

export class UserDataService {

    isStale: EventEmitter<boolean> = new EventEmitter();
    refreshUserData: EventEmitter<void> = new EventEmitter();

    constructor(private apiService: ApiService) {
    }

    checkIfStale(slicName: string, userId: string, role: Role): Observable<LocationUser> {
        return this.apiService.getLocationUser(slicName, userId)
            .pipe(tap(x => {
                this.isStale.emit(x !== null && x.role?.toString() !== role?.toString());
            }));
    }

}
