// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  max-width: 360px;
}

.peak-season {
  margin-bottom: 48px;
  line-height: 17px;
}

.move-up {
  margin-top: -20px;
}

.col.small {
  font-size: 80%;
}

@media (min-width: 1280px) {
  .row {
    max-width: 408px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlcXVlc3QtcHJvZ3Jlc3MuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtBQUNGOztBQUVBO0VBQ0UsbUJBQUE7RUFDQSxpQkFBQTtBQUNGOztBQUVBO0VBQ0UsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLGNBQUE7QUFDRjs7QUFFQTtFQUNFO0lBQ0UsZ0JBQUE7RUFDRjtBQUNGIiwiZmlsZSI6InJlcXVlc3QtcHJvZ3Jlc3MuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucm93IHtcbiAgbWF4LXdpZHRoOiAzNjBweDtcbn1cblxuLnBlYWstc2Vhc29uIHtcbiAgbWFyZ2luLWJvdHRvbTogNDhweDtcbiAgbGluZS1oZWlnaHQ6IDE3cHg7XG59XG5cbi5tb3ZlLXVwIHtcbiAgbWFyZ2luLXRvcDogLTIwcHg7XG59XG5cbi5jb2wuc21hbGwge1xuICBmb250LXNpemU6IDgwJTtcbn1cblxuQG1lZGlhIChtaW4td2lkdGg6MTI4MHB4KSB7XG4gIC5yb3cge1xuICAgIG1heC13aWR0aDogNDA4cHg7XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home/trailer-request/request-progress/request-progress.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;AACF;AACA,4uBAA4uB","sourcesContent":[".row {\n  max-width: 360px;\n}\n\n.peak-season {\n  margin-bottom: 48px;\n  line-height: 17px;\n}\n\n.move-up {\n  margin-top: -20px;\n}\n\n.col.small {\n  font-size: 80%;\n}\n\n@media (min-width:1280px) {\n  .row {\n    max-width: 408px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
