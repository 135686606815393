import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';

@Component({
  selector: 'notification-type-header',
  templateUrl: './notification-type-header.component.html',
  styleUrls: ['./notification-type-header.component.scss']
})
export class NotificationTypeHeaderComponent extends LocalizableComponent {
  @Input() name: string;
  @Input() status: { allOn: boolean, allOff: boolean };
  @Input() active: boolean = true;
  @Output() onChange = new EventEmitter<boolean>();

  changeAllToggles(on) {
    this.onChange.emit(on);
  }
}
