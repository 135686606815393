import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { StringService } from 'src/app/services/string.service';
import { Role } from 'src/app/types/role';
import { ActivityType, UserAccessHistoryAudit } from 'src/app/types/userAccessHistory';
import { debounceFunction } from 'src/decorators/debounceFunction';

@Component({
  selector: 'user-access-history-search',
  templateUrl: './user-access-history-search.component.html',
  styleUrls: ['./user-access-history-search.component.scss']
})
export class UserAccessHistorySearchComponent extends LocalizableComponent {
  @Input() audits: UserAccessHistoryAudit[];
  @Output() filterAudits = new EventEmitter<UserAccessHistoryAudit[]>();

  historySearchString = "";
  iconClass: string = 'search icon';

  constructor(private _stringService: StringService) {
    super();
   }

  @debounceFunction(300)
  searchForHistory() {
    this.iconClass = this.historySearchString === "" ? 'search icon' : 'clear icon';
    const normalizedLowerCaseSearch = this._stringService.normalizeString(this.historySearchString).toLowerCase().trim();
    const filteredAudits = this.audits.filter(a => 
      this._stringService.normalizeString(a.locationSlicName).toLowerCase().includes(normalizedLowerCaseSearch) ||
      this.getActivityText(a.activity, a.modifyingUser).includes(normalizedLowerCaseSearch) ||
      this.getRoleText(a.role).includes(normalizedLowerCaseSearch)
    );

    this.filterAudits.emit(filteredAudits);
  }

  clearHistorySearch() {
    this.historySearchString = "";
    this.searchForHistory();
  }

  private getActivityText(activity: ActivityType, modifyingUser: string) {
    let activityText;
    switch (activity) {
      case ActivityType.Added:
        activityText = "Added";
        break;
      case ActivityType.Approved:
        activityText = "Approved";
        break;
      case ActivityType.Denied:
        activityText = "Denied";
        break;
      case ActivityType.Removed:
        activityText = "Removed";
        break;
      case ActivityType.Submitted:
        activityText = "Submitted";
        break;
      case ActivityType.Updated:
        activityText = "Updated";
        break;
    }

    const localizedString = `${this.localize(this.langSection.UserAccessActivity, activityText)} ${this.langSection.Term, this.langText.By} ${modifyingUser}`;
    return this._stringService.normalizeString(localizedString).toLowerCase();
  }

  private getRoleText(role: Role){
    let roleText;
    if (role == Role.Admin || role == Role.PendingAdmin) {
      roleText = "Administrator";
    } else if (role == Role.User || role == Role.PendingUser) {
      roleText = "ViewEdit";
    } else if (role == Role.ReadonlyUser || role == Role.PendingReadOnly) {
      roleText = "ViewOnly";
    }

    const localizedString = this.localize(this.langSection.Role, roleText);
    return this._stringService.normalizeString(localizedString).toLowerCase();
  }

}
