import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FilterType } from '../../types/filterType';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ApiService } from '../../api.service';
import { Observable } from 'rxjs';
import { TrailerRequestPayload } from '../../types/trailerRequestPayload';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { LocationName } from '../../types/locationName';

@Component({
  selector: 'filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent extends LocalizableComponent implements OnInit {
  lowerLimit: string = null;
  earlistDueDate: Date | null = null;
  latestDueDate: Date | null = null;
  filterForm: UntypedFormGroup = this.formBuilder.group({
    requestType: this.formBuilder.group({
      'Delivery': false,
      'Request Empty': false,
      'Live Load': false,
      'Retrieve Empty': false,
      'Retrieve Load': false,
      'Switch': false
    }),
    trailerType: this.formBuilder.group({
      'Short': false,
      'Long': false
    }),
    destination: "",
    destinationSort: this.formBuilder.group({
      'S': false,
      'P': false,
      'M': false,
      'D': false,
      'A': false,
      'L': false,
      'T': false,
      'N': false
    }),
    serviceLevel: this.formBuilder.group({
      'EAM': false,
      '1DA': false,
      '2DA': false,
      '3DS': false,
      'GRD': false
    }),
    trailerDirection: this.formBuilder.group({
      'To UPS': false,
      'From UPS': false
    }),
    planNotMet: this.formBuilder.group({
      'Not Planned': false,
      'Tractor Only': false
    }),
    trailerVolume: this.formBuilder.group({
      'Volume': false,
      'No Volume': false,
      'Pallets': false,
      'Gaylords': false
    }),
    createdAs: this.formBuilder.group({
      'Planned': false,
      'Ad Hoc': false
    }),
    'earliestDueDate': null,
    'latestDueDate': null,
    'unreadCommentsOnly': false
  });

  @Input() value: TrailerRequestPayload;
  @Output() filterEvent = new EventEmitter<FilterType>();

  constructor(private formBuilder: UntypedFormBuilder, private apiService: ApiService) {
    super();
  }

  ngOnInit() {
    if (this.value) {
      if (this.value.requestType.length > 0) {
        for (let x in this.value.requestType) {
          (this.filterForm.controls["requestType"] as any).controls[this.value.requestType[x]].setValue(true);
        }
      }
      if (this.value.trailerType.length > 0) {
        for (let x in this.value.trailerType) {
          (this.filterForm.controls["trailerType"] as any).controls[this.value.trailerType[x]].setValue(true);
        }
      }
      if (this.value.destinationSort.length > 0) {
        for (let x in this.value.destinationSort) {
          (this.filterForm.controls["destinationSort"] as any).controls[this.value.destinationSort[x]].setValue(true);
        }
      }
      if (this.value.serviceLevel.length > 0) {
        for (let x in this.value.serviceLevel) {
          (this.filterForm.controls["serviceLevel"] as any).controls[this.value.serviceLevel[x]].setValue(true);
        }
      }
      if (this.value.trailerDirection.length > 0) {
        for (let x in this.value.trailerDirection) {
          (this.filterForm.controls["trailerDirection"] as any).controls[this.value.trailerDirection[x]].setValue(true);
        }
      }
      if (this.value.planNotMet.length > 0) {
        for (let x in this.value.planNotMet) {
          (this.filterForm.controls["planNotMet"] as any).controls[this.value.planNotMet[x]].setValue(true);
        }
      }
      if (this.value.trailerVolume.length > 0) {
        for (let x in this.value.trailerVolume) {
          (this.filterForm.controls["trailerVolume"] as any).controls[this.value.trailerVolume[x]].setValue(true);
        }
      }
      if (this.value.destination) {
        this.filterForm.controls["destination"].setValue({ slicName: this.value.destination });
      }
      if (this.value.earliestDueDate) {
        this.filterForm.controls["earliestDueDate"].setValue(this.value.earliestDueDate);
        this.earlistDueDate = new Date(this.value.earliestDueDate);
        this.lowerLimit = this.value.earliestDueDate;
      }
      if (this.value.latestDueDate) {
        this.filterForm.controls["latestDueDate"].setValue(this.value.latestDueDate);
        this.latestDueDate = new Date(this.value.latestDueDate);
      }
      if (this.value.unreadCommentsOnly) {
        this.filterForm.controls["unreadCommentsOnly"].setValue(this.value.unreadCommentsOnly);
      }
      if (this.value.createdAs.length > 0) {
        for (let x in this.value.createdAs) {
          (this.filterForm.controls["createdAs"] as any).controls[this.value.createdAs[x]].setValue(true);
        }
      }
    }
  }

  onApplyClick() {
    this.filterEvent.emit(this.filterForm.value);
  }

  clearFilters(form) {
    this.earlistDueDate = null;
    this.latestDueDate = null;
    this.filterForm.reset({ unreadCommentsOnly: false }); //This resets all of the fields to null, but specifically resets unreadCommentsOnly to false instead of null
    this.filterEvent.emit(this.filterForm.value);
  }

  dateChanged(date, name) {
    if (date)
      this.filterForm.controls[name].setValue(date.toISOString());
    else
      this.filterForm.controls[name].setValue(null);

    if (name == "earliestDueDate") {
      this.lowerLimit = date;
      this.earlistDueDate = date;
    } else
      this.latestDueDate = date;
  }

  onDestinationFilterSelect(loc: LocationName | null) {
    this.filterForm.controls['destination'].setValue(loc ? loc.slicName : '');
  }

  onDestinationQueryChange(query: string) {
    this.filterForm.controls['destination'].setValue(query);
  }
}
