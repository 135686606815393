import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  loggingIn: boolean = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({ username: [''], password: [''] });
  }

  ngAfterViewInit() {
    window.name = 'Authorization';
  }

  submit() {
    this.loggingIn = true;
    this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
      .subscribe({ error: _ => this.loggingIn = false });
  }
}
