import { Injectable } from '@angular/core';
import { LDClient, initialize } from 'launchdarkly-js-client-sdk';
import { ReplaySubject, Subject, first, takeUntil } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { EnvironmentService } from './environment.service';

@Injectable()
export class FeatureFlagService {

  private ldClient: LDClient | null = null;
  public sdkReady$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private clientId: string;

  private isInit = false;
  disconnect$ = new Subject<boolean>();

  constructor(private environmentService: EnvironmentService, private authService: AuthService) {
    this.environmentService.environment$.pipe(takeUntil(this.disconnect$)).subscribe(env => {
      if (env) {
        this.authService.loggedInEvent$
          .pipe(first())
          .subscribe(() => {
            this.clientId = env.launchDarklyClientId;
            this.initLaunchDarkly();
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();

    if (this.ldClient != null) {
      this.ldClient.flush();
    }
  }

  public initLaunchDarkly() {
    if (!this.isInit) {
      if (!!this.clientId) {
        console.log('using clientID: ' + this.clientId);
        this.isInit = true;

        const userContext = this.getUserContext();

        this.ldClient = initialize(
          this.clientId,
          userContext,
          {
            sendEventsOnlyForVariation: true,
            evaluationReasons: true,
            bootstrap: 'localStorage'
          }
        );

        this.ldClient.on('change', (flags) => {
          this.sdkReady$.next();
        });

        this.ldClient.on('ready', () => {
          this.sdkReady$.next();
          return this.ldClient;
        });
      } else {
        console.log('No clientId will not init');
      }
    } else {
      console.log('Already Init');
    }
  }

  checkFeatureFlagBoolean(flagKey: string, fallback: boolean = false) {
    if (fallback === undefined || fallback == null) {
      fallback = false;
    }

    if (this.ldClient == null || this.ldClient === undefined) {
      return fallback;
    }

    const flagDetails = this.ldClient.variationDetail(flagKey, fallback);
    const flagValue = flagDetails.value;
    return flagValue;
  }

  checkFeatureFlag(flagKey: string, fallback = undefined) {
    if (this.ldClient == null || this.ldClient === undefined) {
      return fallback;
    }

    const flagDetails = this.ldClient.variationDetail(flagKey, fallback);
    const flagValue = flagDetails.value;
    return flagValue;
  }

  getUserContext() {
    const userId = this.authService.getUniqueName();
    const fullName = this.authService.getFullName();
    const userNameSplit = fullName?.split(' ');
    const userFirstName = (!!userNameSplit && userNameSplit.length > 0) ? userNameSplit[0] : '';
    const userLastName = (!!userNameSplit && userNameSplit.length > 1) ? userNameSplit.slice(1).join(' ') : '';

    return {
      key: userId,
      anonymous: false,
      firstName: userFirstName,
      lastName: userLastName
    };
  }
}
