import { Pipe, PipeTransform } from '@angular/core';
import { StringService } from '../services/string.service';
import { LocationProfile } from '../types/locationProfile';

@Pipe({
  name: 'filterLocationsList',
})
export class FilterLocationsListPipe implements PipeTransform {
  
  constructor(private stringService: StringService){ }
  
  transform(locations: LocationProfile[], searchTerm: string): LocationProfile[] {
    if(searchTerm?.trim()) {
      return this.filterLocations(locations, searchTerm);
    }
    else {
      return locations;
    }
  }

  private filterLocations = (locations: LocationProfile[], searchTerm: string): LocationProfile[] => {
    return locations.filter(loc => this.anyFieldContainsSearchTerm([searchTerm], this.generateFields(loc)))
  }
  
  private anyFieldContainsSearchTerm = (searchTerms: string[], fields: string[]): boolean => {
    return !!searchTerms.find(term => 
      fields.find(field => 
        this.stringService.normalizeString(field)
        .toLowerCase()
        .includes(this.stringService.normalizeString(term.trim().toLowerCase())))
    );
  }

  private generateFields(loc: LocationProfile): string[] {
    const fields = [
      loc?.customName,
      loc?.address
    ]

    if(loc?.slicName && loc?.slicNumber) {
      fields.push((loc?.slicName + ' - ' + loc?.slicNumber));
    } else if(loc?.slicName || loc?.slicNumber) {
      loc?.slicName && fields.push(loc?.slicName); 
      loc?.slicNumber && fields.push(loc?.slicNumber); 
    }

    return fields;
  }
}

