import { OnInit, AfterViewInit, Input, Output, EventEmitter, Directive } from "@angular/core";
import { RequestFormFieldChange } from "./request-form.component";
import { DropdownOption } from "../types/dropdownOption";
import { TrailerRequestResponse } from "../types/trailerRequestResponse";
import { TrailerRequest } from "../types/trailerRequest";
import { LocalizableComponent } from "../components/localizable/localizable.component";

@Directive()
export class RequestSubForm extends LocalizableComponent implements AfterViewInit {
  @Input() errors: { [field: string]: string };
  @Input() model: TrailerRequest = null;
  @Input() destinations: DropdownOption[] = [];
  @Input() sortTypes: DropdownOption[];
  @Input() serviceLevels: DropdownOption[];
  @Input() trailerTypes: DropdownOption[];
  @Input() isNewRequest: boolean;
  pageName: string;
  set selectedDestination(slic: string) {
    this.onDestinationChange.emit(slic);
  }
  get selectedDestination() {
    return this.model.destinationOrganizationAbbreviatedName;
  }
  set destinationSortCode(sortCode: string) {
    this.onDestinationSortChange.emit(sortCode);
  }
  get destinationSortCode() {
    return this.model.destinationSortCode;
  }
  @Output() fieldChange: EventEmitter<RequestFormFieldChange> = new EventEmitter<RequestFormFieldChange>();
  @Output() onDestinationChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDestinationSortChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngAfterViewInit() {
    window.name = this.pageName;
  }

  onFieldChange(field: string, value: string) {
    if (field === 'trailerNumber')
      this.fieldChange.emit({ field, value: value.toUpperCase() });
    else
      this.fieldChange.emit({ field, value });
  }

  isDisabled(field: string) {
    if (!this.model.requestId) return false;

    switch (field) {
      case "trailerNumber":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "trailerType":
        return !['Pending', 'Approved', 'Scheduled'].some(status => status === this.model.requestStatus);
      case "bayNumber":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "specialInstructions":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "deliveryNumber":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "pickUpNumber":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "requestReason":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "pieceCount":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "palletCount":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "percentFull":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "gaylords":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "bags":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "irregs":
        return !['Pending', 'Approved', 'Scheduled', 'In Transit', 'On Property'].some(status => status === this.model.requestStatus);
      case "serviceLevel":
        return !['Pending'].some(status => status === this.model.requestStatus) || this.model.trailerRequestData.attentionNeeded;
      case "destinationOrganizationAbbreviatedName":
        return !['Pending'].some(status => status === this.model.requestStatus) || this.model.trailerRequestData.attentionNeeded;
      case "destinationSortCode":
        return !['Pending'].some(status => status === this.model.requestStatus) || this.model.trailerRequestData.attentionNeeded;
    }

    return true;
  }
}
