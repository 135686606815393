import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestSubForm } from '../request-sub-form.component';

@Component({
  selector: 'live-load-request-form',
  templateUrl: './live-load-request-form.component.html',
  styleUrls: []
})
export class LiveLoadhRequestFormComponent extends RequestSubForm {
  pageName = 'RequestLiveLoad';
}
