import { ExportUser } from './exportUser'
import { LocationProfile } from './locationProfile';

export class ExportLocation {
    slicName: string;
    slicNumber: string;
    customName: string;
    lastUpdatedAt: string;
    lastUpdatedBy: string;
    address: string;
    users: ExportUser[];

    constructor(location: LocationProfile) {
        this.slicName = location.slicName;
        this.slicNumber = location.slicNumber;
        this.customName = location.customName;
        this.lastUpdatedAt = location.customNameLastUpdatedAt;
        this.lastUpdatedBy = location.customNameLastUpdatedBy;
        this.address = location.address;
        this.users = [];
    }
}