import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(input: string, pattern: string | RegExp, replacement: string = ''): string {
      //do nothing if the input or the pattern is not present
      if(!input || !pattern) {
          return input;
      }
    return input.replace(pattern, replacement);
  }
}