import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { EMPTY, Observable } from "rxjs";
import { catchError, debounceTime, map, switchMap, withLatestFrom } from "rxjs/operators";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { LocationListService } from 'src/app/services/location-list.service';
import { ApiService } from "../../api.service";
import { AppState } from "../../app.state";
import { AuthService } from "../../auth/auth.service";
import { LocationUser } from "../../types/locationUser";
import { Role } from "../../types/role";
import { UPDATE_DATE_RANGE } from "../actions/filter.actions";
import { LOAD_LOCATION_LIST, LOAD_LOCATION_LIST_TIMER, LOAD_LOCATION_USERS, setLocationList, setLocationUsers } from "../actions/location-list.actions";
import { TRAILER_REQUEST_COMMENTS_READ, TRAILER_REQUEST_STATUS_CHANGE } from "../actions/trailer-requests.actions";

@Injectable()
export class LocationListEffects {
  constructor(private actions$: Actions, private apiService: ApiService, private authService: AuthService,
    private store$: Store<AppState>, private loadingIndicatorService: LoadingIndicatorService,
    private locationListService: LocationListService) { }

  loadLocationList$: Observable<Action> = createEffect(() =>
    this.actions$
      .pipe(
        ofType(LOAD_LOCATION_LIST_TIMER, LOAD_LOCATION_LIST, UPDATE_DATE_RANGE, TRAILER_REQUEST_STATUS_CHANGE, TRAILER_REQUEST_COMMENTS_READ),
        debounceTime(100),
        withLatestFrom(this.store$),
        switchMap(([action, state]: [Action, AppState]) => {
          const { fromDate, toDate } = state.requestFilters;
          this.locationListService.lastUpdateFromTimer.next(action.type === LOAD_LOCATION_LIST_TIMER);
          return this.apiService.searchLocations('', { fromDate, toDate })
            .pipe(
              catchError(() => {
                this.loadingIndicatorService.locationListLoading$.next(false);
                return EMPTY;
              })
            );
        }),
        map(response => {
          let locations = setLocationList(response.map(loc => {
            if (loc.slicNumber) {
              loc.profile.forceReadOnly = loc.profile.forceReadOnly || ((loc.role & Role.ReadonlyUser) === Role.ReadonlyUser);
            }
            loc.userList = [];
            return loc;
          }));
          this.loadingIndicatorService.locationListLoading$.next(false);
          return locations;
        })

      ));

  loadProfileUsers$: Observable<Action> = createEffect(() =>
    this.actions$
      .pipe(
        ofType(LOAD_LOCATION_USERS),
        debounceTime(100),
        switchMap(action => {
          let payload = (action as any).payload;
          return this.apiService.getLocationUsers(payload.slicNumber, payload.countryCode).pipe(
            map(users => [payload.slicNumber, payload.countryCode, users]),
            catchError(() => EMPTY)
          )
        }),
        map(([slicNumber, countryCode, users]: [string, string, LocationUser[]]) => ([
          slicNumber,
          countryCode,
          users.sort((a, b) => (a.role === b.role) ? (a.upsId.toLowerCase() > b.upsId.toLowerCase() ? 1 : -1) : (a.role < b.role ? 1 : -1))
        ])),
        map(([slicNumber, countryCode, users]: [string, string, LocationUser[]]) => setLocationUsers(slicNumber, countryCode, users))
      ));
}
