import { Component } from '@angular/core';
import { Toast } from 'src/app/types/Toast';
import { ToastService } from 'src/app/services/toast.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
  toasts: Toast[] = [];
  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor (private toastService: ToastService) {
    this.toastService.Toasts.pipe(takeUntil(this.disconnect$)).subscribe(toasts => {
      this.toasts = toasts;
    });
  }

  closeToast(toast: Toast) {
    this.toastService.removeToast(toast);
  }

}
