export enum NcmUserType {
    NoAccess = "0",
    Carrier = "1",
    Customer = "2",
    Upser = "3"
}

export enum NcmUserRole {
    NoAccess = "0",
    CSR = "16",
    Admin = "256"
}