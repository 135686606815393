import { TrailerRequestDataResponse } from 'src/app/types/trailerRequestDataResponse';
import { TrailerRequestResponse } from "../../types/trailerRequestResponse";

export const ADD_TRAILER_REQUESTS = 'ADD_TRAILER_REQUESTS';
export const addTrailerRequests = (requestData: TrailerRequestDataResponse, totalRequestCount: number) => ({
  type: ADD_TRAILER_REQUESTS,
  payload: { requestData, totalRequestCount }
})

export const SET_TRAILER_REQUESTS = 'SET_TRAILER_REQUESTS';
export const setTrailerRequests = (requestData: TrailerRequestDataResponse) => ({
  type: SET_TRAILER_REQUESTS,
  payload: { requestData }
})

export const REFRESH_TRAILER_REQUESTS = 'REFRESH_TRAILER_REQUESTS';
export const refreshTrailerRequests = () => ({
  type: REFRESH_TRAILER_REQUESTS
})

export const UPDATE_TRAILER_REQUESTS = 'UPDATE_TRAILER_REQUESTS';
export const updateTrailerRequests = (requestData: TrailerRequestResponse[]) => ({
  type: UPDATE_TRAILER_REQUESTS,
  payload: { requestData }
})

export const UPDATE_TRAILER_REQUEST = 'UPDATE_TRAILER_REQUEST';
export const updateTrailerRequest = (requestId: string, request: TrailerRequestResponse) => ({
  type: UPDATE_TRAILER_REQUEST,
  payload: { requestId, request }
})

export const TRAILER_REQUEST_STATUS_CHANGE = 'TRAILER_REQUEST_STATUS_CHANGE';
export const trailerRequestStatusChange = () => ({
  type: TRAILER_REQUEST_STATUS_CHANGE
})

export const TRAILER_REQUEST_COMMENTS_READ = 'TRAILER_REQUEST_COMMENTS_READ';
export const trailerRequestCommentsRead = () => ({
  type: TRAILER_REQUEST_COMMENTS_READ
})

export const REQUEST_CHANGES_DETECTED = 'REQUEST_CHANGES_DETECTED';
export const requestChangesDetected = () => ({
  type: REQUEST_CHANGES_DETECTED
})
