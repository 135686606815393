import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LOAD_INITIAL_DATA } from "../actions/app.actions";
import { loadLocationList } from "../actions/location-list.actions";

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions) { }

  loadLocationList$: Observable<Action> = createEffect(() => 
    this.actions$
      .pipe(
        ofType(LOAD_INITIAL_DATA),
        map(() => loadLocationList())
  ));
}
