import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { StringService } from 'src/app/services/string.service';
import { LocationProfile } from 'src/app/types/locationProfile';
import { LocationUser } from 'src/app/types/locationUser';
import { LocationName } from '../../../types/locationName';
import { Role } from "../../../types/role";

@Component({
  selector: 'add-user-form',
  templateUrl: './add-user-form.component.html',
  styleUrls: ['./add-user-form.component.scss']
})
export class AddUserFormComponent extends LocalizableComponent {
  @Output() addUserToQueue: EventEmitter<LocationProfile[]> = new EventEmitter<LocationProfile[]>();

  user: LocationUser = {
    upsId: '',
    email: '',
    role: 0,
  } as LocationUser;

  errors: { email: string } = { email: '' };
  locations: LocationName[] = [];

  upsIdError: boolean = false;

  roleList = [
    { name: this.localize(this.langSection.Role, this.langText.ViewOnly), value: Role.ReadonlyUser },
    { name: this.localize(this.langSection.Role, this.langText.ViewEdit), value: Role.User },
    { name: this.localize(this.langSection.Role, this.langText.Administrator), value: Role.Admin }
  ];

  refreshUserList: Subject<void> = new Subject<void>();

  constructor(private stringService: StringService) {
    super();
  }

  isCurrentUserValid(): boolean {

    let valid = !!(this.user.email?.trim() && this.user.upsId?.trim() && this.user.role && this.locations.length);

    this.errors.email = '';
    if (this.user.email && ! /^([a-zA-Z0-9_\-'!#$%&*+/=?^`{|}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(this.stringService.normalizeString(this.user.email))) {
      valid = false;
      this.errors.email = this.localize(this.langSection.AddUserPage, this.langText.EmailInvalid);
    }

    return valid;
  }

  onLocationSelect(slic: string | LocationName) {
    if (slic && typeof (slic) === 'object') {
      if (!this.locations.some(location => location.slicNumber === slic.slicNumber && location.countryCode === slic.countryCode)) {
        this.locations.push(slic);
        this.locations = this.locations.sort((a, b) => {
          let nameA = (a.customName) ? a.customName : a.slicName;
          let nameB = (b.customName) ? b.customName : b.slicName;
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });
      }
    }
  }

  formatLocation(location: LocationName): string {
    let formatted = '';
    const addressEmpty = (location.address?.trim() === '' || location.address?.trim() === ', ,')

    if (location.customName === '' && addressEmpty) formatted = location.slicName;
    if (location.customName === '' && !addressEmpty) formatted = `${location.slicName} - ${location.address}`;
    if (location.customName !== '' && addressEmpty) formatted = `${location.customName} / ${location.slicName}`;
    if (location.customName !== '' && !addressEmpty) formatted = `${location.customName} / ${location.slicName} - ${location.address}`;

    return formatted;
  }

  removeLocation(location: LocationName) {
    this.locations.splice(this.locations.indexOf(location), 1);
  }

  addToQueue() {
    let userLocations: LocationProfile[] = this.locations.map((location) => {
      return {
        slicName: location.slicName,
        slicNumber: location.slicNumber,
        countryCode: location.countryCode,
        customName: location.customName,
        address: location.address,
        userList: [{ upsId: this.user.upsId.trim(), email: this.user.email.trim(), role: this.user.role }]
      } as LocationProfile;
    });
    this.addUserToQueue.emit(userLocations);
    this.clearForm();
  }

  onIdInput(val) {
    const idValue = val.trim();

    this.user.upsId = (idValue);

    this.upsIdError = idValue.length > 16;
  }

  clearForm() {
    this.user.upsId = '';
    this.user.email = '';
    this.user.role = 0;
    this.locations = [];
  }
}
