import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { NotificationsFilterService, NotificationSortPayload } from 'src/app/services/notifications-filter.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ToastService } from 'src/app/services/toast.service';
import { LocationProfile } from 'src/app/types/locationProfile';
import { NotificationSettingsErrors, NotificationUserLocationSettings } from 'src/app/types/NotificationUserLocationSettings';

@Component({
  selector: 'location-settings-grid',
  templateUrl: './location-settings-grid.component.html',
  styleUrls: ['./location-settings-grid.component.scss']
})
export class LocationSettingsGridComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() set locations(input: LocationProfile[]) {
    this._locations = input;
    this.locationSettings = this.mergeLocationsWithSettings(input);
    this.notificationsService.initLocationSettings(this.locationSettings);
    this.updateAllHeaders();
  };
  @Input() totalLocationCount: number = 0;

  _locations: LocationProfile[] = [];
  locationSettings: NotificationUserLocationSettings[] = [];
  allLocationSettings: NotificationUserLocationSettings[] = [];

  showStatusCheckboxes: boolean = false;
  statusChangeStatus = { allOn: false, allOff: true };
  commentStatus = { allOn: false, allOff: true };
  requestEditedStatus = { allOn: false, allOff: true };
  settingsInitialized: boolean = false;
  locationSearchTerm$ = this.notificationsFilterService.searchTerm$;
  locationColumnErrors = new NotificationSettingsErrors();
  disconnect$ = new Subject<boolean>();

  errorToastId: string = 'notification-save-api-error';

  dataOutOfDate: boolean = false;

  constructor(private notificationsService: NotificationsService, private apiService: ApiService, private authService: AuthService, private notificationsFilterService: NotificationsFilterService, private toastService: ToastService) {
    super();
  }

  ngOnInit() {
    this.notificationsFilterService.sortPayload$.pipe(takeUntil(this.disconnect$)).subscribe(s => {
      this.locationSettings = this.applySort(s, this.locationSettings);
    })

    this.notificationsService.notificationSettings$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(settings => {
        this.locationSettings = settings.map(s => { return (new NotificationUserLocationSettings()).clone(s); });
        this.allLocationSettings = settings.map(s => { return (new NotificationUserLocationSettings()).clone(s); });
        if (this._locations?.length)
          this.locationSettings = this.mergeLocationsWithSettings(this._locations);
        this.notificationsService.initLocationSettings(this.locationSettings);
        this.updateAllHeaders();
        this.settingsInitialized = true;
      });

    this.notificationsService.notificationSettingsOutOfDate$.pipe(takeUntil(this.disconnect$)).subscribe(outOfDate => {
      this.dataOutOfDate = outOfDate;
    });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  applySort(payload: NotificationSortPayload, locationSettings: NotificationUserLocationSettings[]) {
    let fnSortField;

    switch (payload.sortField) {
      case 'slicName':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => locationSetting.slicName ?? '';
        break;
      case 'statusChange':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => !locationSetting.statusChange;
        break;
      case 'comment':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => !locationSetting.comment;
        break;
      case 'requestEdited':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => !locationSetting.requestEdited;
        break;
      default:
        fnSortField = (locationSetting: NotificationUserLocationSettings) => locationSetting.slicName ?? '';
        break;
    }

    return [...locationSettings].sort((settingA, settingB) => {
      let a = fnSortField(settingA);
      let b = fnSortField(settingB);
      let compare = 0;

      if (a < b) {
        compare = -1;
      }
      else if (a > b) {
        compare = 1;
      } else {
        compare = 0;
      }

      if (!payload.isAscending)
        compare = -compare;

      if (compare === 0) {
        if (settingA.slicName < settingB.slicName) {
          compare = -1;
        }
        else if (settingA.slicName > settingB.slicName) {
          compare = 1;
        }
        else {
          compare = 0;
        }
      }

      return compare;
    });
  }

  toggleStatusCheckboxes() {
    if (this.dataOutOfDate)
      return;

    this.showStatusCheckboxes = !this.showStatusCheckboxes;
  }

  mergeLocationsWithSettings(locations: LocationProfile[]): NotificationUserLocationSettings[] {
    let settings = locations.map(location => {
      let setting = this.allLocationSettings.find(s => s.countryCode === location.countryCode && s.slicNumber === location.slicNumber);
      if (setting) {
        setting.slicName = location.slicName;
        setting.customName = location.customName;
        setting.address = location.address;

        return setting;
      }

      return new NotificationUserLocationSettings(location);
    });

    return this.applySort(this.notificationsFilterService.sortPayload$.value, settings);
  }

  changeStatusChangeToggle(settings: NotificationUserLocationSettings) {
    this.updateStatusChangeHeader();
    this.saveSettings(settings);
  }

  onStatusChange(settings: NotificationUserLocationSettings) {
    this.saveSettings(settings);
  }

  updateStatusChangeHeader() {
    let enabledCount = this.locationSettings.filter(setting => setting.statusChange).length;
    this.statusChangeStatus.allOn = enabledCount !== 0 && enabledCount === this.locationSettings.length;
    this.statusChangeStatus.allOff = enabledCount === 0;
  }

  saveSettings(settings: NotificationUserLocationSettings, errorHandler: (settings: NotificationUserLocationSettings, updated: NotificationUserLocationSettings) => void = null): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.notificationsService.saveLocationSettings(settings).subscribe(response => {
        let currentSettings = this.locationSettings.find(ls => ls.countryCode === settings.countryCode && ls.slicNumber === settings.slicNumber);
        if (!response.saved) {
          if (!response.outOfDate) {
            if (errorHandler) {
              errorHandler(settings, response.updated);
            }
            else {
              if (this.checkForErrors(settings, response.updated))
                this.showError();
            }
          }
          currentSettings.merge(response.updated);
        }
        else {
          this.clearModifiedSettingsErrors(response.updated, response.saved, response.previous);
        }

        resolve();
      });

    });
  }

  checkForErrors(originalSettings: NotificationUserLocationSettings, updatedSettings: NotificationUserLocationSettings): boolean {
    let error = true;
    updatedSettings.errors.statusChange ||= originalSettings.statusChange != updatedSettings.statusChange ||
      originalSettings.statusChangeNotifications.approved != updatedSettings.statusChangeNotifications.approved ||
      originalSettings.statusChangeNotifications.attentionNeeded != updatedSettings.statusChangeNotifications.attentionNeeded ||
      originalSettings.statusChangeNotifications.pending != updatedSettings.statusChangeNotifications.pending ||
      originalSettings.statusChangeNotifications.inTransit != updatedSettings.statusChangeNotifications.inTransit ||
      originalSettings.statusChangeNotifications.scheduled != updatedSettings.statusChangeNotifications.scheduled ||
      originalSettings.statusChangeNotifications.onProperty != updatedSettings.statusChangeNotifications.onProperty ||
      originalSettings.statusChangeNotifications.completed != updatedSettings.statusChangeNotifications.completed ||
      originalSettings.statusChangeNotifications.canceled != updatedSettings.statusChangeNotifications.canceled;
    updatedSettings.errors.comment ||= originalSettings.comment != updatedSettings.comment;
    updatedSettings.errors.requestEdited ||= originalSettings.requestEdited != updatedSettings.requestEdited;

    return error;
  }

  enableCommentNotificationChanged(settings: NotificationUserLocationSettings, enabled: boolean) {
    settings.comment = enabled;
    this.updateCommentHeader();
    this.saveSettings(settings);
  }

  enableRequestEditedNotificationChanged(settings: NotificationUserLocationSettings, enabled: boolean) {
    settings.requestEdited = enabled;
    this.updateRequestEditedHeader();
    this.saveSettings(settings);
  }

  updateAllHeaders() {
    this.updateStatusChangeHeader();
    this.updateCommentHeader();
    this.updateRequestEditedHeader();
  }

  updateCommentHeader() {
    const enabledCount = this.locationSettings.filter(setting => setting.comment).length;
    this.commentStatus.allOn = enabledCount !== 0 && enabledCount === this.locationSettings.length;
    this.commentStatus.allOff = enabledCount === 0;
  }

  updateRequestEditedHeader() {
    const enabledCount = this.locationSettings.filter(setting => setting.requestEdited).length;
    this.requestEditedStatus.allOn = enabledCount !== 0 && enabledCount === this.locationSettings.length;
    this.requestEditedStatus.allOff = enabledCount === 0;
  }

  async setAllStatusChangeToggles(enabled) {
    let saving: Promise<void>[] = [];

    this.locationColumnErrors.statusChange = false;

    this.locationSettings.forEach(settings => {
      if (settings.statusChange !== enabled) {
        settings.statusChange = enabled;
        if (enabled) {
          let hasCheckedStatuses = Object.keys(settings.statusChangeNotifications).some(key => settings.statusChangeNotifications[key]);
          if (!hasCheckedStatuses) { // select all statuses
            Object.keys(settings.statusChangeNotifications).forEach(key => { settings.statusChangeNotifications[key] = true; });
          }
        }

        saving.push(this.saveSettings(settings, (setting, updated) => {
          this.locationColumnErrors.statusChange = true;
        }));
      }
    })

    await Promise.all(saving);

    if (this.locationColumnErrors.statusChange)
      this.showError();

    this.updateStatusChangeHeader();
  }

  async setAllCommentToggles(enabled: boolean) {
    let saving: Promise<void>[] = [];

    this.locationColumnErrors.comment = false;

    this.locationSettings.forEach(settings => {
      if (settings.comment !== enabled) {
        settings.comment = enabled;

        saving.push(this.saveSettings(settings, (setting, updated) => {
          this.locationColumnErrors.comment = true;
        }));
      }
    })

    await Promise.all(saving);

    if (this.locationColumnErrors.comment)
      this.showError();

    this.updateCommentHeader();
  }

  async setAllRequestEditedToggles(enabled: boolean) {
    let saving: Promise<void>[] = [];

    this.locationColumnErrors.requestEdited = false;

    this.locationSettings.forEach(settings => {
      if (settings.requestEdited !== enabled) {
        settings.requestEdited = enabled;

        saving.push(this.saveSettings(settings, (setting, updated) => {
          this.locationColumnErrors.requestEdited = true;
        }));
      }
    })

    await Promise.all(saving);

    if (this.locationColumnErrors.requestEdited)
      this.showError();

    this.updateRequestEditedHeader();
  }

  onLocationSearch(searchTerm: string) {
    this.locationSearchTerm$.next(searchTerm);
    this.resetLocationColumnErrors();
  }

  clearLocationSearch() {
    this.locationSearchTerm$.next('');
    this.resetLocationColumnErrors();
  }

  resetLocationColumnErrors() {
    this.locationColumnErrors.statusChange = false;
    this.locationColumnErrors.comment = false;
    this.locationColumnErrors.requestEdited = false;
  }

  showError() {
    this.toastService.pushToast({
      id: this.errorToastId,
      text: this.localize(this.langSection.Toast, this.langText.FailedToSaveChanges),
      duration: 10
    });
  }

  clearModifiedSettingsErrors(current: NotificationUserLocationSettings, saved: NotificationUserLocationSettings, previous: NotificationUserLocationSettings) {
    this.toastService.removeToast(this.errorToastId);

    if (saved.statusChange !== previous.statusChange ||
      saved.statusChangeNotifications.approved != previous.statusChangeNotifications.approved ||
      saved.statusChangeNotifications.attentionNeeded != previous.statusChangeNotifications.attentionNeeded ||
      saved.statusChangeNotifications.pending != previous.statusChangeNotifications.pending ||
      saved.statusChangeNotifications.inTransit != previous.statusChangeNotifications.inTransit ||
      saved.statusChangeNotifications.scheduled != previous.statusChangeNotifications.scheduled ||
      saved.statusChangeNotifications.onProperty != previous.statusChangeNotifications.onProperty ||
      saved.statusChangeNotifications.completed != previous.statusChangeNotifications.completed ||
      saved.statusChangeNotifications.canceled != previous.statusChangeNotifications.canceled)
      current.errors.statusChange = false;
    if (saved.comment !== previous.comment)
      current.errors.comment = false;
    if (saved.requestEdited !== previous.requestEdited)
      current.errors.requestEdited = false;

  }
}
