import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, interval } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AppState } from '../app.state';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { LocalizedDatePipe } from '../pipes/localized-date.pipe';
import { DateService } from '../services/date.service';
import { LocalizeCancelReasonService } from '../services/localize-cancel-reason.service';
import { AuditReason } from '../types/auditReason';
import { LocationProfile } from '../types/locationProfile';
import { TrailerRequestAudit } from '../types/trailerRequestAudit';
import { TrailerRequestResponse } from '../types/trailerRequestResponse';


@Component({
  selector: 'request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.css']
})
export class RequestDetailsComponent extends LocalizableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() requestId;

  breadcrumbs: { url: string, name: string }[] = [
    { url: '/', name: this.localize(this.langSection.Breadcrumb, this.langText.Home) },
    { url: '#', name: this.localize(this.langSection.Breadcrumb, this.langText.RequestDetails) }
  ];
  model: TrailerRequestResponse | null = null;
  reasonType = AuditReason;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  locationProfile: LocationProfile = null;
  audit: TrailerRequestAudit | null = null;
  datePipe: LocalizedDatePipe = new LocalizedDatePipe(this.dateService);
  locationSubscription: any;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private store: Store<AppState>, private dateService: DateService,
              private localizeCancelReasonService: LocalizeCancelReasonService) {
    super();
  }

  ngOnInit() {
    this.model = new TrailerRequestResponse();
    this.audit = new TrailerRequestAudit();
    this.loadDetails();
    interval(30000).pipe(takeUntil(this.disconnect$))
      .subscribe(() => {
        this.loadDetails();
      });
    }
   
  localizeCancelReasonString(value: string) {
    return this.localizeCancelReasonService.localizeCancelReasonString(value);
  }

  private loadDetails() {
    if (this.requestId) {
      //If a request ID is provided, we're editing a request. Pull it down via the API and set the model.
      this.apiService.getTrailerRequest(this.requestId)
        .pipe(first())
        .subscribe(data => {
          this.model = data;
          if (data && !this.locationSubscription) {
            this.setLocationSubscription(data);
          }
        });

      this.apiService.getTrailerRequestAudit(this.requestId)
        .pipe(first())
        .subscribe(data => {
          this.audit = data;
        });

    }
  }

  private setLocationSubscription(data: TrailerRequestResponse) {
    this.locationSubscription = this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(state => this.locationProfile = state.locations.find(loc => data.slicName == loc.slicName)?.profile
      );
  }

  ngAfterViewInit() {
    window.name = 'RequestDetails';
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
    this.locationSubscription = null;
  }

  localizeField(field: string) {
    switch (field) {
      case "":
        return '';
      case "% Full":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.PercentFull);
      case "Bags":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Bags);
      case "Bay Number":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.BayNumber);
      case "Delivery Number":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.DeliveryNumber);
      case "Destination":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Destination);
      case "Destination Sort":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.DestinationSort);
      case "Due Date":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.DueDate);
      case "Earliest":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Earliest);
      case "Gaylords":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Gaylords);
      case "Irregs":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Irregs);
      case "Latest":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Latest);
      case "Pallets":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Pallets);
      case "Pickup Number":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.PickupNumber);
      case "Pieces":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Pieces);
      case "Reason":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Reason);
      case "Request Type":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.RequestType);
      case "Service Level":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.ServiceLevel);
      case "Special Instructions":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.SpecialInstructions);
      case "Trailer Number":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.TrailerNumber);
      case "Trailer Type":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.TrailerType);
      default:
        return field;
    }
  }

  localizeValue(field: string, value: string) {
    switch (field) {
      case "Earliest":
      case "Latest":
        return this.datePipe.transform(value, 'M/d/yy, h:mm a', 'yyyy/MM/dd HH:mm');
      default:
        return value;
    }
  }

  getEventType(eventType: string) {
    let type = eventType.toLowerCase();
    switch (type) {
      case "created":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Created);
      case "pending":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Pending);
      case "edited":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Edited);
      case "approved":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Approved);
      case "scheduled":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Scheduled);
      case "in transit":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.InTransit);
      case "on property":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.OnProperty);
      case "picked up":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.PickedUp);
      case "completed":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Completed);
      case "transferred":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Transferred);
      case "customer selected":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.CustomerSelected);
      case "transfer requested":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.TransferRequested);
      case "transfer approved":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.TransferApproved);
      case "transfer rejected":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.TransferRejected);
      case "rejected":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Rejected);
      case "canceled":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.Canceled);
      case "undo transfer":
        return this.localize(this.langSection.RequestDetailsPage, this.langText.UndoTransfer);
      default:
        return type;
    }
  }

  onContinue() {
    this.router.navigateByUrl("/", { replaceUrl: true });
  }
}
