import { Component } from "@angular/core";
import { UserDataService } from "src/app/services/user-data.service";
import { LocalizableComponent } from "../localizable/localizable.component";

@Component({
  selector: 'user-data-stale-toast',
  templateUrl: './user-data-stale-toast.component.html',
  styleUrls: [
    './user-data-stale-toast.component.scss'
  ]
})
export class UserDataStaleToast extends LocalizableComponent {
  isVisible: boolean = true;

  constructor (private userDataService: UserDataService) {
    super();
  }

  refreshUserData() {
    this.userDataService.refreshUserData.emit();
  }
}
