import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { firstValueFrom, Subject } from 'rxjs';
import { LocationProfile } from 'src/app/types/locationProfile';
import { OperatingHours } from 'src/app/types/operatingHours';
import { ApiService } from '../../api.service';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { TrailerRequestResponse } from '../../types/trailerRequestResponse';



@Component({
  selector: 'contact-request',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModal
  extends LocalizableComponent
  implements OnInit, OnDestroy {
  contact: LocationProfile = null;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = true;

  @Input() request: TrailerRequestResponse;
  @Input() modal;

  hasHours: boolean = false;
  hours: string[] = [
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Sunday hours
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Monday hours
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Tuesday hours
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Wednesday hours
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Thursday hours
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Friday hours
    this.localize(this.langSection.ContactModal, this.langText.NoAvailableInformation), // Saturday hours
  ];

  constructor(private apiService: ApiService) {
    super();
  }

  async ngOnInit() {
    try {
      if (this.request.responsibleSiteCountryCode && this.request.responsibleSiteSlicNumber) {
        this.contact = await firstValueFrom(this.apiService.getLocation(this.request.responsibleSiteCountryCode, this.request.responsibleSiteSlicNumber));
      }
  
      if (this.contact === null) {
        this.contact = await firstValueFrom(this.apiService.getDefaultResponsibleSiteFromLocation(this.request.slicCountryCode, this.request.slicNumber));
        if (this.contact === null && this.request.trailerLoads?.length > 1 && this.request.trailerLoads[1].destinationCountryCode && this.request.trailerLoads[1].destinationOrganizationNumber) {
          this.contact = await firstValueFrom(this.apiService.getLocation(this.request.trailerLoads[1].destinationCountryCode, this.request.trailerLoads[1].destinationOrganizationNumber));
        }
      }
  
      this.hasHours = !!this.contact?.operatingHours?.length;
      if (this.hasHours) {
        this.initHours(this.contact?.operatingHours);
      }
    } catch (err) {
      console.log(err);
    }

    this.isLoading = false;
  }

  initHours(operatingHours: OperatingHours[]) {
    let french = this.isFrench;

    for (let index in this.hours) {
      this.hours[index] = this.localize(this.langSection.ContactModal, this.langText.Closed);
    }

    for (let operatingHour of operatingHours) {
      if (this.is24Hour(operatingHour)) {
        this.hours[operatingHour.day] = this.localize(this.langSection.ContactModal, this.langText.Open24Hours);
      }
      else {
        let openTime = moment(operatingHour.openTime, "HH:mm");
        let closeTime = moment(operatingHour.closeTime, "HH:mm");

        this.hours[operatingHour.day] = (french) ? `${operatingHour.openTime} - ${operatingHour.closeTime}` : `${openTime.format('hh:mm A')} - ${closeTime.format('hh:mm A')}`;
      }
    }
  }

  is24Hour(operatingHour: OperatingHours): boolean {
    const allDayFrom: string = "00:00";
    const allDayTo: string = "23:59";
    const midnight: string = "00:00";

    return ((operatingHour.openTime === allDayFrom && operatingHour.closeTime === allDayTo) || (operatingHour.openTime === midnight && operatingHour.closeTime === midnight));
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  dismissModal() {
    this.modal.close();
  }
}
