// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  padding-left: 20px;
  list-style: none;
}
ul li {
  border-bottom: 1px solid #6E6E6E;
}
ul li:last-of-type {
  border-bottom: none;
}

.user-queue-location-entry {
  background-color: #DDD7D0;
  font-weight: 600;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/add-location-users/new-user-queue/new-user-queue-location/new-user-queue-location.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;AACJ;AACI;EACI,gCAAA;AACR;AACQ;EACI,mBAAA;AACZ;;AAIA;EACI,yBAAA;EACA,gBAAA;EACA,aAAA;AADJ","sourcesContent":["ul {\n    padding-left: 20px;\n    list-style: none;\n\n    li {\n        border-bottom: 1px solid #6E6E6E;\n\n        &:last-of-type {\n            border-bottom: none;\n        }\n    }\n}\n\n.user-queue-location-entry {\n    background-color: #DDD7D0;\n    font-weight: 600;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
