import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable()
export class LoginFallbackGuardService {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(): boolean {
    if (!this.auth.shouldFallback) {
      this.router.navigateByUrl('/gateway');
    }
    return this.auth.shouldFallback;
  }
}

export const LoginFallbackGuard: CanActivateFn = (): Observable<boolean> | boolean => {
  return inject(LoginFallbackGuardService).canActivate();
}
