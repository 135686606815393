// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range-picker {
  display: flex;
}
@media screen and (max-width: 739px) {
  .date-range-picker {
    gap: 15px;
    justify-content: center;
  }
}
@media screen and (min-width: 740px) {
  .date-range-picker {
    flex-direction: column;
    align-items: flex-start;
    width: 240px;
  }
}
.date-range-picker .date {
  margin-bottom: 17px;
}

.date-separator {
  display: none !important;
}

@media (max-width: 739px) {
  .wide-screen {
    display: none;
  }
}
@media (min-width: 740px) {
  .small-screen {
    display: none;
  }
}
@media (min-width: 1280px) {
  .date-range-picker {
    width: 346px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .date-range-picker .date {
    margin-bottom: auto;
  }
  .date-separator {
    position: relative;
    margin-top: 2rem;
    display: inline-flex !important;
  }
}
@media (min-width: 1880px) {
  .date-range-picker {
    width: 377px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .date-range-picker .date {
    margin-bottom: auto;
  }
  .date-separator {
    position: relative;
    margin-top: 2rem;
    display: inline-flex !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/date-range-picker/date-range-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EAHF;IAII,SAAA;IACA,uBAAA;EAEF;AACF;AAAE;EARF;IASI,sBAAA;IACA,uBAAA;IACA,YAAA;EAGF;AACF;AADE;EACE,mBAAA;AAGJ;;AACA;EACE,wBAAA;AAEF;;AACA;EAEE;IACE,aAAA;EACF;AACF;AAEA;EAEE;IACE,aAAA;EADF;AACF;AAKA;EACE;IACE,YAAA;IACA,mBAAA;IACA,8BAAA;IACA,mBAAA;IACA,mBAAA;EAHF;EAKE;IACE,mBAAA;EAHJ;EAOA;IACE,kBAAA;IACA,gBAAA;IACA,+BAAA;EALF;AACF;AASA;EACE;IACE,YAAA;IACA,mBAAA;IACA,8BAAA;IACA,mBAAA;IACA,mBAAA;EAPF;EASE;IACE,mBAAA;EAPJ;EAWA;IACE,kBAAA;IACA,gBAAA;IACA,+BAAA;EATF;AACF","sourcesContent":[".date-range-picker {\n  display: flex;\n\n  @media screen and (max-width: 739px) {\n    gap: 15px;\n    justify-content: center;\n  }\n\n  @media screen and (min-width: 740px) {\n    flex-direction: column;\n    align-items: flex-start;\n    width: 240px;\n  }\n\n  & .date {\n    margin-bottom: 17px;\n  }\n}\n\n.date-separator {\n  display: none !important;\n}\n\n@media (max-width:739px) {\n\n  .wide-screen {\n    display: none;\n  }\n}\n\n@media (min-width:740px) {\n\n  .small-screen {\n    display: none;\n  }\n\n}\n\n@media (min-width:1280px) {\n  .date-range-picker {\n    width: 346px;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1rem;\n\n    & .date {\n      margin-bottom: auto;\n    }\n  }\n\n  .date-separator {\n    position: relative;\n    margin-top: 2rem;\n    display: inline-flex !important;\n  }\n}\n\n\n@media (min-width:1880px) {\n  .date-range-picker {\n    width: 377px;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1rem;\n\n    & .date {\n      margin-bottom: auto;\n    }\n  }\n\n  .date-separator {\n    position: relative;\n    margin-top: 2rem;\n    display: inline-flex !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
