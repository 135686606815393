import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NewUserQueueEntry } from '../types/newUserQueueEntry';

@Injectable({
  providedIn: 'root'
})
export class NewUserQueueErrorService {
  errorEntries: NewUserQueueEntry[] = [];
  hasErrors$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasErrors(): boolean {
    return this.errorEntries.filter(e => e.hasError).length > 0;
  }

  addEntry(entry: NewUserQueueEntry) {
    const entryIndex = this.errorEntries.findIndex(ee => ee.countryCode === entry.countryCode && ee.slicNumber === entry.slicNumber && ee.upsId === entry.upsId);
    
    if (entry.hasError && entryIndex === -1){
      this.errorEntries.push(entry);
    } else if (!entry.hasError && entryIndex !== -1) {
      this.errorEntries.splice(entryIndex, 1);
    }

    this.hasErrors$.next(this.hasErrors());
  }

  deleteEntry(countryCode: string, slicNumber: string, upsId: string) {
    const entryIndex = this.errorEntries.findIndex(ee => ee.countryCode === countryCode && ee.slicNumber === slicNumber && ee.upsId === upsId);

    if (entryIndex > -1) {
      this.errorEntries.splice(entryIndex, 1);
    }

    this.hasErrors$.next(this.hasErrors());
  }

  clearEntries() {
    this.errorEntries = [];
    this.hasErrors$.next(false);
  }
}
