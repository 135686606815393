import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalizableComponent } from "../components/localizable/localizable.component";
import { CancelRequestModal } from "../home/cancel-request-modal/cancel-request-modal.component";
import { RequestFormComponent } from "./request-form.component";

@Injectable()
export class CanDeactivateRequestFormService extends LocalizableComponent  {
  public modalRef: NgbModal
  constructor(
    private modalService: NgbModal
     ) {
    super()
  } 
  async canDeactivate(component: RequestFormComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean> {
    if (component.isDirty && nextState.url !== '/gateway') {
      const modalRef = this.modalService.open(CancelRequestModal, { centered: true, size: 'cancel-on-deactivator', backdrop: 'static' });
      modalRef.componentInstance.isNewRequest = component.isNewRequest;
      return await modalRef.result;
    } else {
      return true;
    }
  }
  
}

export const CanDeactivateRequestForm: CanDeactivateFn<RequestFormComponent> = (component: RequestFormComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean> => {
  return inject(CanDeactivateRequestFormService).canDeactivate(component, currentRoute, currentState, nextState);
}
