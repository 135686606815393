import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsFilterService {
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject('');
  sortPayload$: BehaviorSubject<NotificationSortPayload> = new BehaviorSubject<NotificationSortPayload>(new NotificationSortPayload());
}

export class NotificationSortPayload {
  sortField: string = 'slicName';
  isAscending: boolean = true;
}
