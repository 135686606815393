import { Component } from "@angular/core";
import { UserDataService } from "src/app/services/user-data.service";
import { LocalizableComponent } from "../localizable/localizable.component";
import { NotificationsService } from "src/app/services/notifications.service";

@Component({
  selector: 'notification-location-data-stale-toast',
  templateUrl: './notification-location-data-stale-toast.component.html',
  styleUrls: [
    './notification-location-data-stale-toast.component.scss'
  ]
})
export class NotificationLocationDataStaleToast extends LocalizableComponent {
  isVisible: boolean = true;

  constructor (private notificationService: NotificationsService) {
    super();
  }

  refreshNotificationLocationData() {
    this.notificationService.refreshNotificationLocationData.emit();
  }
}
