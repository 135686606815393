import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { VirtualScrollerComponent } from "ngx-virtual-scroller";
import { LocalizableComponent } from "src/app/components/localizable/localizable.component";
import { TrailerAsset } from "src/app/types/onPropertyData";

@Component({
    selector: 'trailer-list',
    templateUrl: './trailer-list.component.html',
    styleUrls: ['./trailer-list.component.scss']
})

export class TrailerListComponent extends LocalizableComponent implements AfterViewInit, OnChanges {
    @ViewChild('scroll') scroll: VirtualScrollerComponent;

    constructor() {
        super();
    }

    @Input() trailers: TrailerAsset[];
    @Input() isLoading: boolean;
    @Input() selectedTrailer: TrailerAsset;
    @Output() onTrailerSelect: EventEmitter<TrailerAsset> = new EventEmitter<TrailerAsset>();
    trailerListScrollTop: number = 0;
    recalcInterval: any; //number = -1;

    self: HTMLDivElement;
    itemContainer: HTMLDivElement;
    container: HTMLDivElement;

    ngAfterViewInit() {
        this.captureHandles();

        this.recalcInterval = setInterval(() => {
            if (this.scroll && this.scroll['element']) {
                this.scroll['element'].nativeElement.scrollTop = this.trailerListScrollTop;
            }
        }, 500); // as number;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedTrailer && this.selectedTrailer) {
            // When selectedTrailer changes, if it is not visible on the list scroll to selectedTrailer
            if (!this.trailerCardIsVisibleInList(this.scroll.viewPortItems.findIndex(x => x == this.selectedTrailer)))
                this.scroll.scrollToIndex(this.trailers.findIndex(x => x == this.selectedTrailer));
        }
    }

    captureHandles() {
        this.container = document.querySelector('.trailer-list-container');
        this.self = document.querySelector('.trailer-list');
        this.itemContainer = document.querySelector('.trailer-list .trailer-items');
    }

    setSelection(trailer: TrailerAsset) {
        if (trailer) {
            if (this.selectedTrailer != trailer)
                this.onTrailerSelect.emit(trailer);
            else
                this.onTrailerSelect.emit(null);
        }
    }

    trailerListScroll(event: Event) {
        const scrollTop = (event.target as HTMLElement).scrollTop;
        this.trailerListScrollTop = scrollTop;
    }

    private trailerCardIsVisibleInList(index): boolean {
        const trailerCardHeight: number = 56;
        
        if (index == -1)
            return false;
        else if (this.scroll.viewPortInfo.scrollStartPosition % trailerCardHeight > 0)
            return index > this.scroll.viewPortInfo.startIndex && index < this.scroll.viewPortInfo.endIndex - 1;
        else 
            return index >= this.scroll.viewPortInfo.startIndex && index < this.scroll.viewPortInfo.endIndex;
    }
}