// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: none;
  padding: 22px 30px 16px 60px;
}
.modal-header h4 {
  font-size: 32px;
}

.modal-sub-header {
  padding: 0 60px 16px;
}

.modal-body {
  display: flex;
  flex-flow: column;
  row-gap: 1.5rem;
  padding: 0 60px;
}
.modal-body .labeled-input {
  display: flex;
  flex-flow: column;
  padding-bottom: 16px;
}
.modal-body .labeled-input label {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
}
.modal-body .labeled-input .email-input {
  width: 310px;
}
.modal-body .labeled-input .ups-icon-x-circle {
  color: #DF2901;
  font-size: 24px;
}

.modal-footer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-top: none;
  padding: 32px 60px;
}

.btn-green {
  color: white;
  background-color: #00857D;
  border-radius: 35px;
  width: 120px;
  height: 36px;
}

.error-message {
  margin-top: 0.5rem;
  color: #C91913;
}

.add-email-btn {
  color: #426DA9;
  font-size: 16px;
  display: flex;
  column-gap: 8px;
  align-items: center;
}
.add-email-btn .ups-icon {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/notification-settings/delivery-type-modal/delivery-type-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,4BAAA;AACJ;AACI;EACI,eAAA;AACR;;AAGA;EACI,oBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;AAAJ;AAEI;EACI,aAAA;EACA,iBAAA;EACA,oBAAA;AAAR;AAEQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;AAAZ;AAGQ;EACI,YAAA;AADZ;AAIQ;EACI,cAAA;EACA,eAAA;AAFZ;;AAOA;EACI,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AAJJ;;AAOA;EACI,kBAAA;EACA,cAAA;AAJJ;;AAOA;EACI,cAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;AAJJ;AAMI;EACI,eAAA;AAJR","sourcesContent":[".modal-header {\n    border-bottom: none;\n    padding: 22px 30px 16px 60px;\n\n    h4 {\n        font-size: 32px;\n    }\n}\n\n.modal-sub-header {\n    padding: 0 60px 16px;\n}\n\n.modal-body {\n    display: flex;\n    flex-flow: column;\n    row-gap: 1.5rem;\n    padding: 0 60px;\n\n    .labeled-input {\n        display: flex;\n        flex-flow: column;\n        padding-bottom: 16px;\n\n        label {\n            font-size: 14px;\n            font-weight: 700;\n            padding-bottom: 5px;\n        }\n\n        .email-input {\n            width: 310px;\n        }\n\n        .ups-icon-x-circle {\n            color: #DF2901;\n            font-size: 24px;\n        }\n    }\n}\n\n.modal-footer {\n    display: flex;\n    flex-flow: column;\n    justify-content: center;\n    align-items: center;\n    border-top: none;\n    padding: 32px 60px;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n    border-radius: 35px;\n    width: 120px;\n    height: 36px;\n}\n\n.error-message {\n    margin-top: .5rem;\n    color: #C91913;\n}\n\n.add-email-btn {\n    color: #426DA9;\n    font-size: 16px;\n    display: flex;\n    column-gap: 8px;\n    align-items: center;\n\n    .ups-icon {\n        font-size: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
