import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { sortLocations } from 'src/app/store/actions/location-users.actions';
import { LocationProfile } from 'src/app/types/locationProfile';
import { Sort } from 'src/app/types/states/locationUsersState';

@Component({
  selector: 'location-users-location-sort',
  templateUrl: './location-sort.component.html',
  styleUrls: ['./location-sort.component.scss']
})
export class LocationSortComponent extends LocalizableComponent implements OnDestroy, OnInit {

  public selectedSort: keyof (LocationProfile) | 'custom' = 'slicName';
  public storedCustomSort: { field?: keyof (LocationProfile) | 'custom', userId?: string } = { field: null, userId: '' };

  public sortOptions: { name: string, value: keyof (LocationProfile) | 'custom' }[] = [
    { name: this.localize(this.langSection.HomePage, this.langText.LocationCode), value: 'slicName' },
    { name: this.localize(this.langSection.HomePage, this.langText.CustomLocationName), value: 'customName' },
    { name: this.localize(this.langSection.HomePage, this.langText.StateProvinceTerritory), value: 'address' },
    { name: this.localize(this.langSection.Sort, this.langText.TotalUsers), value: 'users' }
  ];

  public isAscending: boolean = true;
  private disconnect$ = new Subject<boolean>();

  constructor(private store: Store<AppState>) {
    super();

    store.pipe(takeUntil(this.disconnect$)).subscribe(state => {
      const sort = state.locationUsers.locationSort;

      if (sort.customSortValues && Object.keys(sort.customSortValues).length > 0) {
        const previousSearch = this.sortOptions.find(option => option.value == this.selectedSort);
        if (sort.customSortValues?.userId) {
          this.storedCustomSort.userId = sort.customSortValues.userId;
        }
        if (previousSearch.value != 'custom') {
          this.storedCustomSort.field = previousSearch.value;
          this.sortOptions = this.sortOptions.filter(option => option.value !== 'custom');
          this.sortOptions.push({ name: `${previousSearch.name} (${this.localize(this.langSection.Term, this.langText.Custom)})`, value: 'custom' });
        }

        this.selectedSort = 'custom';
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(sortLocations({ field: this.selectedSort as keyof (LocationProfile), isAscending: this.isAscending }));
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
    this.store.dispatch(sortLocations({ field: 'slicName', isAscending: true, customSortValues: null }));
  }

  updateSort() {
    let params: Sort<LocationProfile> = { field: this.selectedSort as keyof (LocationProfile), isAscending: this.isAscending };

    if (this.selectedSort == 'custom') {
      params.customSortValues = { ...this.storedCustomSort };
    } else {
      this.sortOptions = this.sortOptions.filter(option => option.value != 'custom');
    }

    this.store.dispatch(sortLocations(params));
  }

  updateLocationSort(key: keyof (LocationProfile)) {
    this.selectedSort = key;
    this.updateSort();
  }

  toggleAscending() {
    this.isAscending = !this.isAscending;
    this.updateSort();
  }
}
