import { Component } from '@angular/core';
import { LocalizableComponent } from '../components/localizable/localizable.component';

@Component({
  selector: 'no-location',
  templateUrl: './no-location.component.html'
})
export class NoLocationComponent extends LocalizableComponent {
  constructor() {
    super();
  }
}
