import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LocationProfile } from '../../../types/locationProfile';
import { LocalizableComponent } from "../../../components/localizable/localizable.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomNameModalComponent } from 'src/app/home/custom-name-modal/custom-name-modal.component';
import { Subject, take, takeUntil } from 'rxjs';
import { sortUsers } from 'src/app/store/actions/location-users.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';

const MAX_CUSTOM_NAME_LENGTH = 30;

@Component({
  selector: 'location-header',
  templateUrl: './location-header.component.html',
  styleUrls: ['./location-header.component.scss']
})
export class LocationHeaderComponent extends LocalizableComponent implements OnDestroy, OnInit {
  @Input() location: LocationProfile;
  showFullCustomName: boolean = false;
  isCustomSorted: boolean = false;
  disconnect$ = new Subject<boolean>();

  //regex to verify that the address is not just commas and spaces (same as being empty)
  emptyAddressRegex = /^[\s,]+$/;

  constructor(private modalService: NgbModal, private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.pipe(takeUntil(this.disconnect$)).subscribe(state => {
      const currentSort = state.locationUsers.userSort;
      this.isCustomSorted = currentSort.customSortValues && 
        currentSort.customSortValues['slicCountryCode'] === this.location?.countryCode && 
        currentSort.customSortValues['slicNumber'] === this.location?.slicNumber;
    });
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  openCustomNameModal(event: Event) {
    event.preventDefault();
    const customNameModal = this.modalService.open(CustomNameModalComponent, { centered: true, modalDialogClass: 'modal-custom-name', backdrop: 'static' });
    customNameModal.componentInstance.locationProfile = this.location;
    customNameModal.componentInstance.modal = customNameModal;
  }

  filterByLocation(event: Event) {
    event.preventDefault();
    this.store.pipe(take(1)).subscribe(state => {
      const currentSort = state.locationUsers.userSort;
      this.store.dispatch(sortUsers({field: currentSort.field, isAscending: currentSort.isAscending, customSortValues: {
        slicCountryCode: this.location.countryCode,
        slicNumber: this.location.slicNumber
      }}));
    });
  }

}
