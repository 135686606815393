import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
 
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url.replace('#', '%23'));
  }
 
  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    let path = dus.serialize(tree);
    return path.replace('#', '%23');
  }
}