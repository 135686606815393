// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e668" !important;
  color: #FFF;
}

.clear.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e67b" !important;
  color: #FFF;
}

label {
  margin-bottom: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItYWNjZXNzLWhpc3Rvcnktc2VhcmNoLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0kseUNBQUE7RUFDQSwyQkFBQTtFQUNBLFdBQUE7QUFDSjs7QUFFQTtFQUNFLHlDQUFBO0VBQ0EsMkJBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDSSxrQkFBQTtBQUNKIiwiZmlsZSI6InVzZXItYWNjZXNzLWhpc3Rvcnktc2VhcmNoLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlYXJjaC5pY29uOmJlZm9yZSB7XG4gICAgZm9udC1mYW1pbHk6IHVwc2ljb25zLCBmYW50YXN5ICFpbXBvcnRhbnQ7XG4gICAgY29udGVudDogXCJcXGU2NjhcIiAhaW1wb3J0YW50O1xuICAgIGNvbG9yOiAjRkZGO1xuICB9XG5cbi5jbGVhci5pY29uOmJlZm9yZSB7XG4gIGZvbnQtZmFtaWx5OiB1cHNpY29ucywgZmFudGFzeSAhaW1wb3J0YW50O1xuICBjb250ZW50OiBcIlxcZTY3YlwiICFpbXBvcnRhbnQ7XG4gIGNvbG9yOiNGRkY7XG59XG5cbmxhYmVsIHtcbiAgICBtYXJnaW4tYm90dG9tOiA1cHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/user-access-history/user-access-history-search/user-access-history-search.component.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,2BAAA;EACA,WAAA;AACJ;;AAEA;EACE,yCAAA;EACA,2BAAA;EACA,WAAA;AACF;;AAEA;EACI,kBAAA;AACJ;AACA,oyBAAoyB","sourcesContent":[".search.icon:before {\n    font-family: upsicons, fantasy !important;\n    content: \"\\e668\" !important;\n    color: #FFF;\n  }\n\n.clear.icon:before {\n  font-family: upsicons, fantasy !important;\n  content: \"\\e67b\" !important;\n  color:#FFF;\n}\n\nlabel {\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
