import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, skip, tap, throwError } from 'rxjs';
import { ApiService } from '../api.service';
import { NotificationDeliveryType } from '../types/NotificationDeliveryType';
import { NotificationUserLocationSettings } from '../types/NotificationUserLocationSettings';
import { UserLocation } from '../types/userLocation';
import { LocationListService } from './location-list.service';

type saveLocationSettingsResponse = {
  updated: NotificationUserLocationSettings,
  previous: NotificationUserLocationSettings,
  saved: NotificationUserLocationSettings,
  outOfDate?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  REFRESH_NOTIFICATION_SETTINGS_RATE = 30 * 1000;
  private notificationRefreshInterval: number = 0;

  public notificationSettingsSubject = new BehaviorSubject<NotificationUserLocationSettings[]>([]);
  public notificationSettings$: Observable<NotificationUserLocationSettings[]> = this.notificationSettingsSubject.asObservable();

  public deliveryTypeSubject = new BehaviorSubject<NotificationDeliveryType>(null);
  public deliveryType$: Observable<NotificationDeliveryType> = this.deliveryTypeSubject.asObservable();

  private unmodifiedLocationSettings: NotificationUserLocationSettings[] = [];
  private savingLocationSettings = new WeakMap<NotificationUserLocationSettings, any>();

  public notificationSettingsOutOfDate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public userLocations$ = new BehaviorSubject<UserLocation[]>([]);
  public refreshNotificationLocationData: EventEmitter<void> = new EventEmitter();

  private lastModifiedUtc?: string = null;

  savingDeliveryType: boolean = false;
  pendingDeliveryTypeChange: NotificationDeliveryType = null;
  deliveryTypeSaved$: Subject<NotificationDeliveryType>;
  deliveryTypelastModifiedUtc: string = null;

  private pauseDeliveryTypeRefresh: boolean = false;
  private pauseNotificationRefresh: boolean = false;

  constructor(private apiService: ApiService, private locationListService: LocationListService) { }

  public initLocationSettings(settings: NotificationUserLocationSettings[]) {
    this.unmodifiedLocationSettings = settings.map(setting => setting.clone()); // store unmodified backup in case of api failure
  }

  public saveLocationSettings(updated: NotificationUserLocationSettings, savedSubject?: BehaviorSubject<saveLocationSettingsResponse>): Observable<saveLocationSettingsResponse> {
    let status;
    if (this.savingLocationSettings.has(updated))
      status = this.savingLocationSettings.get(updated);

    if (status) {
      if (!status.pending) {
        status.pending = new BehaviorSubject<saveLocationSettingsResponse>(null); // additional changes require saving
      }

      return status.pending.asObservable().pipe(skip(1));
    }
    else {
      let saved: BehaviorSubject<saveLocationSettingsResponse> = savedSubject ?? new BehaviorSubject<saveLocationSettingsResponse>(null);
      let saved$ = saved.asObservable().pipe(skip(1));
      let savingSettings = updated.clone();

      if (this.lastModifiedUtc)
        updated.lastModifiedUtc = this.lastModifiedUtc;
      this.pauseNotificationRefresh = true;

      this.apiService.saveNotificationLocationSettings(updated).subscribe({
        next: (updatedSetting) => {
          this.lastModifiedUtc = updatedSetting.lastModifiedUtc;
          let previousSettings: NotificationUserLocationSettings;
          let unmodifiedIndex = this.unmodifiedLocationSettings.findIndex(s => s.countryCode === updated.countryCode && s.slicNumber === updated.slicNumber);
          if (unmodifiedIndex >= 0) {
            previousSettings = this.unmodifiedLocationSettings[unmodifiedIndex];
            this.unmodifiedLocationSettings.splice(unmodifiedIndex, 1, savingSettings);
          }
          else {
            previousSettings = savingSettings;
            this.unmodifiedLocationSettings.push(savingSettings);
          }

          saved.next({ updated: updated, previous: previousSettings, saved: savingSettings }); // updated may have some changes still to be saved - needed to prevent ui flickering
        },
        error: error => {
          const outOfDate = error?.error === 'DataOutOfDate';
          if (outOfDate)
            this.notificationSettingsOutOfDate$.next(true);

          let status;
          if (this.savingLocationSettings.has(updated)) {
            status = this.savingLocationSettings.get(updated);
            this.savingLocationSettings.delete(updated);
          }

          let unmodified = this.unmodifiedLocationSettings.find(settings => settings.countryCode === updated.countryCode && settings.slicNumber === updated.slicNumber);
          saved.next({ updated: unmodified, previous: null, saved: null, outOfDate: outOfDate }); // restore to unmodified value
          if (status?.pending)
            status.pending.next({ updated: unmodified, previous: null, saved: null });
        },
        complete: () => {
          if (this.savingLocationSettings.has(updated)) {
            let status = this.savingLocationSettings.get(updated);
            this.savingLocationSettings.delete(updated);
            if (status.pending) {
              this.saveLocationSettings(updated, status.pending);
            }
          }
          else {
            this.pauseNotificationRefresh = false;
          }
        }
      });

      this.savingLocationSettings.set(updated, { pending: null });

      return saved$;
    }
  }

  public saveNotificationDeliveryType(deliveryTypeSettings: NotificationDeliveryType) {
    if (this.savingDeliveryType) {
      this.pendingDeliveryTypeChange = deliveryTypeSettings;
      if (this.deliveryTypeSaved$ == null)
        this.deliveryTypeSaved$ = new Subject<NotificationDeliveryType>();

      return this.deliveryTypeSaved$;
    }

    this.savingDeliveryType = true;
    this.pauseDeliveryTypeRefresh = true;
    if (this.deliveryTypelastModifiedUtc) {
      deliveryTypeSettings.lastModifiedUtc = this.deliveryTypelastModifiedUtc;
    }
    return this.apiService.saveNotificationDeliveryType(deliveryTypeSettings)
      .pipe(
        catchError(error => {
          if (error?.error === 'DataOutOfDate')
            this.notificationSettingsOutOfDate$.next(true);

          this.savingDeliveryType = false;

          if (this.pendingDeliveryTypeChange) {
            this.pendingDeliveryTypeChange = null;
            this.deliveryTypeSaved$.error(error);
            this.deliveryTypeSaved$ = null;
          }

          return throwError(() => error);
        }),
        tap((saved) => {
          this.deliveryTypelastModifiedUtc = saved.lastModifiedUtc;
          this.savingDeliveryType = false;
          if (this.pendingDeliveryTypeChange) {
            deliveryTypeSettings = this.pendingDeliveryTypeChange;
            this.pendingDeliveryTypeChange = null;
            deliveryTypeSettings.lastModifiedUtc = saved.lastModifiedUtc;
            this.saveNotificationDeliveryType(deliveryTypeSettings).subscribe();
          }
          else {
            if (this.deliveryTypeSaved$ != null) {
              this.deliveryTypeSaved$.next(saved);
              this.deliveryTypeSaved$ = null;
            }
            this.pauseDeliveryTypeRefresh = false;
          }
        })
      );
  }

  public startRefreshTimer() {
    this.refreshSettings();
    this.stopRefreshTimer();
    this.notificationRefreshInterval = setInterval(() => {
      this.refreshSettings();
    }, this.REFRESH_NOTIFICATION_SETTINGS_RATE) as any;
  }

  public stopRefreshTimer() {
    clearInterval(this.notificationRefreshInterval);
    this.notificationRefreshInterval = 0;
  }

  private refreshSettings() {
    this.notificationSettingsOutOfDate$.next(false);
    this.refreshDeliveryType();
    this.refreshNotificationSettings();
  }

  private refreshNotificationSettings() {
    if (this.pauseNotificationRefresh)
      return;

    this.apiService.getNotificationLocationSettings().subscribe(settings => {
      if (!this.pauseNotificationRefresh) {
        this.lastModifiedUtc = null;
        this.notificationSettingsSubject.next(settings.map(s => (new NotificationUserLocationSettings()).clone(s)));
      }
    });
  }

  public refreshDeliveryType() {
    if (this.pauseDeliveryTypeRefresh)
      return;

    this.apiService.getNotificationDeliveryType().subscribe(deliveryType => {
      if (!this.pauseDeliveryTypeRefresh)
        this.deliveryTypeSubject.next(deliveryType);
    })
  }
}
