// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-alert:before {
  font-family: upsicons, fantasy !important;
  content: "\\e60a" !important;
  font-size: 23px;
  color: #F68B20;
}

.warning-alert {
  margin-right: 13px;
}

.alert-warning {
  display: flex;
  background-color: white;
  color: #242424;
  border-color: #F68B20;
  font-size: 12px;
  min-height: 70px;
  align-items: center;
}

strong {
  margin-right: 0.3em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/notification/notification.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,2BAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".warning-alert:before {\n  font-family: upsicons, fantasy !important;\n  content: '\\E60A' !important;\n  font-size: 23px;\n  color: #F68B20;\n}\n\n.warning-alert {\n    margin-right: 13px;\n}\n\n.alert-warning {\n  display: flex;\n  background-color: white;\n  color: #242424;\n  border-color: #F68B20;\n  font-size: 12px;\n  min-height: 70px;\n  align-items: center;\n}\n\nstrong {\n    margin-right: 0.3em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
