import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalizableComponent } from '../../components/localizable/localizable.component';

@Component({
  selector: 'setup-incomplete-modal',
  templateUrl: './setup-incomplete-modal.component.html',
  styleUrls: ['./setup-incomplete-modal.component.scss']
})
export class SetupIncompleteModalComponent extends LocalizableComponent {
  @Input() slicName: string;

  constructor(public activeModal: NgbActiveModal) {
    super();
  }

  goBack() {
    this.activeModal.close(true);
  }
  
  dismissModal() {
    this.activeModal.close(true);
  }
}
