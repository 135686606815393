import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from "@ngrx/store";
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../api.service';
import { AppState } from "../app.state";
import { AuthService } from '../auth/auth.service';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { FeatureFlagService } from '../services/feature-flag.service';
import { NotificationsFilterService } from '../services/notifications-filter.service';
import { NotificationsService } from '../services/notifications.service';
import { StringService } from '../services/string.service';
import { ToastService } from '../services/toast.service';
import { loadLocationList } from '../store/actions/location-list.actions';
import { NotificationDeliveryType } from '../types/NotificationDeliveryType';
import { DropdownOption } from '../types/dropdownOption';
import { LocationProfile } from '../types/locationProfile';
import { DeliveryTypeModalComponent } from './delivery-type-modal/delivery-type-modal.component';

@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent extends LocalizableComponent implements OnDestroy, OnInit {
  locations: LocationProfile[] = [];
  sortInitVal: string;
  selectedSort: string = 'slicName';
  isAscending: boolean = true;

  breadcrumbs: { url: string, name: string }[] = [
    { url: '', name: this.localize(this.langSection.Breadcrumb, this.langText.Home) },
    { url: '', name: this.localize(this.langSection.Breadcrumb, this.langText.NotificationSettings) }
  ];

  sortOptions: DropdownOption[] = [
    { name: this.localize(this.langSection.HomePage, this.langText.LocationCode), value: 'slicName' },
    { name: this.localize(this.langSection.NotificationSettings, this.langText.StatusChangeOn), value: 'statusChange' },
    { name: this.localize(this.langSection.NotificationSettings, this.langText.CommentOn), value: 'comment' },
    { name: this.localize(this.langSection.NotificationSettings, this.langText.RequestEditedOn), value: 'requestEdited' },
  ]

  disconnect$: Subject<boolean> = new Subject<boolean>();
  deliveryTypeSettings: NotificationDeliveryType = null;
  validEmail: boolean;
  emailRegex = /^([a-zA-Z0-9_\-'!#$%&*+/=?^`{|}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  locationSearchTerm: string = '';
  emailNotificationError: boolean = false;
  canSubmit: boolean = false;
  
  showSettingsGrid: boolean = false;

  emailAlertsEnabled: boolean = false;

  dataOutOfDate: boolean = false;

  errorToastId: string = 'notification-save-api-error';

  constructor(private featureFlagService: FeatureFlagService, private router: Router, private apiService: ApiService, private modalService: NgbModal, private authService: AuthService, private notificationsService: NotificationsService, private store: Store<AppState>, private stringService: StringService, private notificationsFilterService: NotificationsFilterService, private toastService: ToastService) {
    super();

    this.featureFlagService.sdkReady$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(() => {
        const showNotificationSettings = this.featureFlagService.checkFeatureFlagBoolean('customer-notifications', false);
        this.checkEmailTextFeatureFlags();

        if (!showNotificationSettings || !this.emailAlertsEnabled)
          this.router.navigateByUrl("/");
      });

    this.sortInitVal = 'slicName';
  }

  ngOnInit(): void {
    this.notificationsService.startRefreshTimer();
    this.store.select(s => s.locationList).pipe(takeUntil(this.disconnect$)).subscribe(state => {
      this.locations = state.locations?.filter(l => l.slicNumber).map(l => l.profile);
    });

    this.notificationsFilterService.searchTerm$.pipe(takeUntil(this.disconnect$)).subscribe(searchTerm => {
      this.locationSearchTerm = searchTerm;
    });

    this.notificationsFilterService.sortPayload$.next({ sortField: 'slicName', isAscending: true });

    this.notificationsService.deliveryType$.pipe(takeUntil(this.disconnect$)).subscribe(deliveryType => {
      if (deliveryType) {
        if (deliveryType.emails && deliveryType.emails?.length > 0)
          this.validEmail = this.emailRegex.test(this.stringService.normalizeString(deliveryType.emails[0]));
        
        this.deliveryTypeSettings = deliveryType;
        this.checkSettingsGridDisplay();
      }
    })

    this.notificationsService.notificationSettingsOutOfDate$.pipe(takeUntil(this.disconnect$)).subscribe(outOfDate => {
      this.dataOutOfDate = outOfDate;
    })

    this.notificationsService.refreshNotificationLocationData.pipe(takeUntil(this.disconnect$)).subscribe(() => {
      this.store.dispatch(loadLocationList());
      this.notificationsService.startRefreshTimer();
      this.notificationsService.notificationSettingsOutOfDate$.next(false);
    })
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
    this.notificationsService.stopRefreshTimer();
    this.notificationsService.notificationSettingsOutOfDate$.next(false);
  }

  openDeliveryTypeModal() {
    const modalRef = this.modalService.open(DeliveryTypeModalComponent, { centered: true, backdrop: 'static', modalDialogClass: 'delivery-type-modal' });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.currentSettings = this.deliveryTypeSettings;
    modalRef.componentInstance.upsId = this.authService.getUniqueName();
  }

  saveDeliveryType(field: string, value: boolean) {
    const initialValue = this.deliveryTypeSettings[field];
    this.deliveryTypeSettings[field] = value;
    this.saveNotificationDeliveryType(field, initialValue);
  }

  private saveNotificationDeliveryType(field: string, initialValue: any) {
    this.notificationsService.saveNotificationDeliveryType(this.deliveryTypeSettings)
      .subscribe({
        next: () => {
          this.toastService.removeToast(this.errorToastId);
          if (field === 'emailNotification')
            this.emailNotificationError = false;

          this.checkSettingsGridDisplay();
        },
        error: () => {
          this.deliveryTypeSettings[field] = initialValue;
          
          if (this.notificationsService.notificationSettingsOutOfDate$.value)
            return;
          
          if (field === 'emailNotification')
            this.emailNotificationError = true;

          this.toastService.pushToast({
            id: this.errorToastId,
            text: this.localize(this.langSection.Toast, this.langText.FailedToSaveChanges),
            duration: 10
          });

          this.checkSettingsGridDisplay();
        }
      });
  }

  updateLocationSort(key: string) {
    this.selectedSort = key;
    this.notificationsFilterService.sortPayload$.next({ sortField: key, isAscending: this.isAscending });
  }

  toggleAscending() {
    this.isAscending = !this.isAscending;
    let currentPayload = this.notificationsFilterService.sortPayload$.value;
    if (currentPayload) {
      this.notificationsFilterService.sortPayload$.next({ sortField: currentPayload.sortField, isAscending: this.isAscending });
    }
  }

  checkSettingsGridDisplay() {
    this.showSettingsGrid = (this.deliveryTypeSettings?.emailNotification && this.emailAlertsEnabled);
  }

  checkEmailTextFeatureFlags() {
    this.emailAlertsEnabled = this.featureFlagService.checkFeatureFlagBoolean('customer-notifications-email-alerts', false);
    this.checkSettingsGridDisplay();
  }
}
