import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { LocalizableComponent } from '../components/localizable/localizable.component';

@Injectable()

export class DateService extends LocalizableComponent {

  constructor() {
    super();
  }

  getLocalizedMonthDateFormat(date: Date) {
    switch (this.getLanguageCode()) {
      case 'fr': {
        return date.getDate() + ' ' + this.localizeValue(this.langSection.Months, date.toLocaleString('default', { month: 'long' })); // returns in format 21 novembre
      }
      default: {
        return (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0'); // returns in format 11/21
      }
    }
  }

  datesAreEqual(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  getDateTimeForLocation(offset: number, date?: Date) {
    // Use current date if none passed in
    date = date ? date : new Date();

    let offsetDifference = this.getLocationTimeZoneOffsetMinutes(offset) - this.getBrowserTimeZoneOffset();
    return this.addMinutesToDate(date, offsetDifference);
  }

  getDateTimeForLocationFromUTCDate(offset: number, date: Date) {

    let offsetDifference = this.getLocationTimeZoneOffsetMinutes(offset);
    return this.addMinutesToDate(date, offsetDifference);
  }

  private getBrowserTimeZoneOffset(): number {
    // Note the minus sign is due to the offset being
    // positive f the local timezone is behind UTC and
    // negative if it is ahead. Eastern time would be
    // positive, but in reality Eastern is lagging
    // UTC by 4 or 5 hours depending on daylight
    // savings. Request offsets are negative if the time
    // zone is behind UTC. We want to work in the same
    // coordinate system.
    return -1 * (new Date()).getTimezoneOffset();
  }

  private getLocationTimeZoneOffsetMinutes(offset: number): number {
    // Time zone offsets as stored in the database are decimal
    // values, like -4, -2.5, etc. These are hours.minutes,
    // but the browser offset is in minutes, so convert
    // the database request offset to minutes.
    return offset * 60;
  }

  private addMinutesToDate(date: Date, minutes: number): Date {
    date.setTime(date.getTime() + (minutes * 60 * 1000)); // minutes * 60 seconds/minute * 1000 ms/second, yielding duration in milliseconds
    return date;
  }

  getTimezoneOffsetDifferenceInMs(locationOffsetHrs: number): number {
    const browserOffset = this.getBrowserTimeZoneOffset();
    const locationOffset = this.getLocationTimeZoneOffsetMinutes(locationOffsetHrs);


    return (browserOffset - locationOffset) * 60 * 1000;
  }

  getDateInUTC() {
    let now = new Date();
    return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
  }

  // Return the browser's offset as we save it within the database (negative hours from UTC)
  getTranslatedBrowserOffset(): number {
    return this.getBrowserTimeZoneOffset() / 60;
  }

  getBrowserTimezoneAbbreviation() {
    const currentTimeZone = moment.tz.guess(true);
    const abbreviation = moment.tz(currentTimeZone).format("z");
    return abbreviation;
  }

}
