import { Component } from '@angular/core';
import { RequestSubForm } from '../request-sub-form.component';

@Component({
  selector: 'realign-request-form',
  templateUrl: './realign-request-form.component.html',
  styleUrls: []
})
export class RealignRequestFormComponent extends RequestSubForm {
  pageName = 'Realign';

}
