// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-user-queue {
  margin-top: 20px;
  min-width: 880px;
}
.new-user-queue .queue-title-line {
  display: flex;
  justify-content: space-between;
}
.new-user-queue .queue-title-line h1 {
  font-size: 21px;
  align-self: flex-end;
}
.new-user-queue .queue-title-line .listed-entries-display {
  align-self: flex-end;
}
.new-user-queue .queue-title-line .listed-entries-display .attention-needed-entries {
  padding-right: 1rem;
  color: #C91913;
}
.new-user-queue .queue-title-line .listed-entries-display .attention-needed-entries::before {
  font-family: "upsicons", fantasy !important;
  content: "\\e638" !important;
  padding-right: 0.25rem;
  font-size: 16px;
}

.user-queue-list {
  height: 390px;
  list-style: none;
  clear: both;
  padding: 0;
  overflow-y: auto;
  border: 1px solid #6E6E6E;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/add-location-users/new-user-queue/new-user-queue.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;AACR;AACQ;EACI,eAAA;EACA,oBAAA;AACZ;AAEQ;EACI,oBAAA;AAAZ;AAEY;EACI,mBAAA;EACA,cAAA;AAAhB;AAEgB;EACI,2CAAA;EACA,2BAAA;EACA,sBAAA;EACA,eAAA;AAApB;;AAOA;EACI,aAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,gBAAA;EACA,yBAAA;AAJJ","sourcesContent":[".new-user-queue {\n    margin-top: 20px;\n    min-width: 880px; \n\n    .queue-title-line {\n        display: flex;\n        justify-content: space-between;\n\n        h1 {\n            font-size: 21px;\n            align-self: flex-end;\n        }\n    \n        .listed-entries-display {\n            align-self: flex-end;\n\n            .attention-needed-entries{\n                padding-right: 1rem;\n                color: #C91913;\n\n                &::before{\n                    font-family: 'upsicons', fantasy !important;\n                    content: \"\\e638\" !important;\n                    padding-right: .25rem;\n                    font-size: 16px;\n                }\n            }\n        }\n    }\n}\n\n.user-queue-list {\n    height: 390px;\n    list-style: none;\n    clear: both;\n    padding: 0;\n    overflow-y: auto;\n    border: 1px solid #6E6E6E;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
