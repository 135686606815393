import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { debounceFunction } from 'src/decorators/debounceFunction';
import { LocationProfile } from '../../types/locationProfile';
import { UserLocationRole } from '../../types/userLocationRole';
import { UserWithLocations } from '../../types/userWithLocations';

@Component({
  selector: 'location-user-grid',
  templateUrl: './location-user-grid.component.html',
  styleUrls: ['./location-user-grid.component.scss']
})

export class LocationUserGridComponent extends LocalizableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('locationUserTable', { static: true, read: ElementRef }) locationUserTable!: ElementRef;
  @ViewChild('userHeaderTable', { static: true, read: ElementRef }) userHeaderTable!: ElementRef;
  @ViewChild('userHeaderContainer', { static: true, read: ElementRef }) userHeaderContainer!: ElementRef;
  @ViewChild('locationUserContainer', { static: false, read: ElementRef }) locationUserContainer!: ElementRef;
  @Input() set locations(input: LocationProfile[]) {
    this._locations = input;
    setTimeout(() => {
      this.locationHeaders = [...(this.locationUserContainer.nativeElement.getElementsByTagName("location-header"))];
      this.filteredLocationsCount.next(this._locations?.length || 0);
      this.filteredLocations.emit(this._locations);
      this.findVisibleLocations();
    }, 0);
  };
  @Input() set users(input: UserWithLocations[]) {
    this._users = input;
    setTimeout(() => {
      this.showVisibleUsers(input);
      this.filteredUsersCount.next(this._users?.length || 0);
      this.filteredUsers.emit(this._users);
    }, 0);
  };
  @Input() filteredUsersCount: BehaviorSubject<number>;
  @Input() filteredLocationsCount: BehaviorSubject<number>;
  @Input() unlockUsers: Subject<void> = new Subject<void>();
  @Output() refreshGrid = new EventEmitter<void>();
  @Output() filteredLocations = new EventEmitter<LocationProfile[]>();
  @Output() filteredUsers = new EventEmitter<UserWithLocations[]>();
  scrollWidth: number = 0;
  scrollLeft: number = 0;
  _users: UserWithLocations[] = [];
  _locations: LocationProfile[] = [];
  resizeObserver: ResizeObserver;
  resizeUsersObserver: ResizeObserver;
  resizeContainerObserver: ResizeObserver;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  scrollLeftView: number = 0;
  firstVisibleLocationIndex: number = 0;
  lastVisibleLocationIndex: number = 1;
  locationHeaders: HTMLElement[] = [];
  userView: UserWithLocations[] = [];
  cellErrors: { userId: string, slic: string }[] = [];
  searchTermIsPresent: boolean = false;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(s => {
      return { locationSearch: s.requestFilters.locationSearchTerm, userSearch: s.requestFilters.locationUsersSearchTerm }
    }).pipe(takeUntil(this.disconnect$)).subscribe(search => {
      this.searchTermIsPresent = !!(search.locationSearch || search.userSearch);
    });

    this.unlockUsers.pipe(takeUntil(this.disconnect$)).subscribe(_ => {
      this.unlockDisplayedUsers();
    });
  }

  ngAfterViewInit(): void {
    this.resizeUsersObserver = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width;
      this.scrollWidth = width;
    });

    this.resizeContainerObserver = new ResizeObserver(entries => {
      this.showVisibleUsers(this._users);
      this.locationHeaders = [...(this.locationUserContainer.nativeElement.getElementsByTagName("location-header"))];
      this.findVisibleLocations();
    });

    this.resizeUsersObserver.observe(this.userHeaderTable.nativeElement);
    this.resizeContainerObserver.observe(this.locationUserContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
    this.resizeUsersObserver.unobserve(this.userHeaderTable.nativeElement);
    this.resizeContainerObserver.unobserve(this.locationUserContainer.nativeElement);
  }

  @HostListener('body:scroll', ['$event'])
  @HostListener('window:resize', ['$event'])
  @debounceFunction(75)
  onWindowScroll() {
    this.findVisibleLocations();
  }

  onUserScroll(evt: WheelEvent) {
    this.scrollLeft = 0 - (evt.target as HTMLElement).scrollLeft;
    this.showVisibleUsers(this._users);
  }

  showVisibleUsers(userList: UserWithLocations[]) {
    let visibleUserInfo = this.findVisibleUsers();
    this.mergeVisibleUsers(visibleUserInfo.users);
    this.setUserScrollPosition(visibleUserInfo);
  }

  setUserScrollPosition(visibleUserInfo: { users: UserWithLocations[], leftPosition: number }) {
    this.scrollLeftView = visibleUserInfo.leftPosition;
    let scrollbar = document.getElementsByClassName('scrollbar');
    if (scrollbar && scrollbar.length > 0) {
      scrollbar[0].scrollLeft = 0 - this.scrollLeft;
    }
  }

  findVisibleUsers(): { users: UserWithLocations[], leftPosition: number } {
    let headerCells = [...this.userHeaderTable.nativeElement.getElementsByTagName("th")];
    let leftEdge = 0;
    let rightEdge = this.userHeaderContainer.nativeElement.clientWidth;
    let leftPosition = 0;
    let visibleUsers: UserWithLocations[] = [];
    if (headerCells) {
      let locationHeaderSize = headerCells[0].clientWidth;
      headerCells.every((cell, i) => {
        if (i > 0) { // bypass location place holder
          let left = cell.offsetLeft + this.scrollLeft;
          let right = left + cell.clientWidth;
          if (right >= leftEdge) { // not clipped to the left side
            if (left <= rightEdge) {
              if (visibleUsers.length == 0)
                leftPosition = (left - locationHeaderSize) - 1;
              visibleUsers.push(this._users[i - 1]);
            }
            else
              return false;
          }
        }
        return true;
      })
    }

    return { users: visibleUsers, leftPosition: leftPosition };
  }

  mergeVisibleUsers(visibleUsers: UserWithLocations[]) {
    let insertedIndex = -1;

    visibleUsers.forEach((user, visibleIndex) => {
      let currentIndex = this.userView.findIndex(u => u.userId === user?.userId);
      if (currentIndex >= 0) {
        if (insertedIndex == -1) {
          this.userView.splice(0, currentIndex);
        }
        else if (insertedIndex != (currentIndex - 1)) {
          this.userView.splice(insertedIndex + 1, currentIndex - insertedIndex - 1);
        }
        insertedIndex++;
        if (!this.userView[insertedIndex].locked && !this.usersMatch(this.userView[insertedIndex], user)) {
          this.userView[insertedIndex] = user;
        }
      }
      else {
        insertedIndex++;
        this.userView.splice(insertedIndex, 0, user);
      }
    });

    if (insertedIndex < (this.userView.length - 1))
      this.userView.splice(insertedIndex + 1);
  }

  usersMatch(currentUser: UserWithLocations, updatedUser: UserWithLocations): boolean {
    return JSON.stringify(currentUser) == JSON.stringify(updatedUser);
  }

  unlockDisplayedUsers() {
    let updateDisplay = false;
    this.userView.forEach(user => {
      if (user.locked)
        user.locked--;
        if (!user.locked)
          updateDisplay = true;
    });

    if (updateDisplay)
      this.showVisibleUsers(this._users);
  }

  findVisibleLocations() {
    if (!this.locationHeaders?.length) {
      this.firstVisibleLocationIndex = -1;
      this.lastVisibleLocationIndex = -1;
      return;
    }

    const rowHeight = this.locationHeaders[0].parentElement.offsetHeight;
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const numItems = Math.ceil(vh / rowHeight);

    const bufferItemsToDisplay = 10;
    for (let i = 0; i < this.locationHeaders.length; i++) {
      let rect = this.locationHeaders[i].getBoundingClientRect();
      let visible = rect.bottom >= window.scrollY && rect.top <= window.scrollY + window.outerHeight;
      if (visible) {
        this.firstVisibleLocationIndex = i - bufferItemsToDisplay < 0 ? 0 : i - bufferItemsToDisplay;
        let last = i + numItems + bufferItemsToDisplay;
        this.lastVisibleLocationIndex = last > this.locationHeaders.length - 1 ? this.locationHeaders.length - 1 : last;
        return;
      }

      // If the bottom of the current element is not visible,
      // calculate how many rows can be skipped until we find 
      // an index that should be visible
      if (rect.bottom < window.scrollY) {
        i += Math.ceil((window.scrollY - rect.bottom) / rowHeight);
      }
    }
  }

  handleCellError(errorEvent: { hasError: boolean, userId: string, slic: string }) {
    if (errorEvent.hasError) {
      if (!this.cellErrors.some(ce => ce.userId === errorEvent.userId && ce.slic === errorEvent.slic)) {
        this.cellErrors.push({ userId: errorEvent.userId, slic: errorEvent.slic });
      }
    } else {
      const errorIndex = this.cellErrors.findIndex(ce => ce.userId === errorEvent.userId && ce.slic === errorEvent.slic);
      if (errorIndex > -1) {
        this.cellErrors.splice(errorIndex, 1);
      }
    }
  }

  refreshUserRole(modifiedUser: { user: UserWithLocations, location: UserLocationRole }) {
    let user = this._users.find(u => u.userId === modifiedUser?.user?.userId);
    let location = modifiedUser?.location;
    if (user && location) {
      let userLocation = user.locations.find(l => l.slicNumber === location.slicNumber && l.countryCode === location.countryCode);
      if (userLocation) {
        userLocation.role = location.role;
      }
      else { // need to add location
        user.locations.push(location);
      }

      this.refreshGrid.emit();
    }
  }

}

