import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from "../api.service";
import { WhatFixService } from "../components/whatfix/whatfix.service";
import { AuthService } from "./auth.service";

@Component({
  selector: 'gateway',
  template: ''
})
export class GatewayComponent implements OnDestroy {
  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private whatFixService: WhatFixService
  ) { }

  ngAfterViewInit() {
    this.activeRoute.queryParams
      .pipe(takeUntil(this.disconnect$))
      .subscribe(({ code, state }) => {
        if (code) {
          this.requestAuthorization(code, state);
        } else {
          this.goToAuthPage();
        }
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  requestAuthorization(code: string, state: string) {
    this.authService.authorize(code, state)
      .catch(error => {
        console.log(error);
        this.router.navigate(['gateway'], { queryParams: this.activeRoute["queryParamsSubject"]?.value });
      })
      .then(() => { this.whatFixService.initWhatFix() });
  }

  goToAuthPage() {
    this.authService.loggedIn = false;
    this.apiService.getOAuthRedirect()
      .pipe(takeUntil(this.disconnect$))
      .subscribe(({ body, status }) => {
        let queryParams = this.activeRoute["queryParamsSubject"]?.value;
        if (queryParams)
          sessionStorage.setItem('queryParams', JSON.stringify(queryParams));
        
        if (status === 204) {
          this.authService.shouldFallback = true;
          this.router.navigate(['login']);
        } else {
          location.href = (body as any) as string;
        }
      });
  }
}
