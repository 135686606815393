// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-message {
  background-color: #FFD100;
  width: 100%;
  min-height: 42px;
  font-size: 14px;
  font-weight: 700;
  display: grid;
  align-items: center;
  grid-template-columns: 15px [page-number] max-content 6px [message] auto [close] min-content 15px;
}
@media screen and (min-width: 768px) {
  .system-message {
    grid-template-columns: 36px [page-number] max-content 9px [message] auto [close] min-content 40px;
  }
}
@media screen and (min-width: 1880px) {
  .system-message {
    grid-template-columns: 250px [page-number] max-content 60px [message] auto [close] min-content 280px;
  }
}

.message-number {
  font-size: 19px;
  font-weight: 400;
  margin-left: 3px;
  margin-right: 3px;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .message-number {
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media screen and (min-width: 1880px) {
  .message-number {
    margin-left: 30px;
    margin-right: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/system-message/system-message.component.scss"],"names":[],"mappings":"AAEA;EACI,yBAHe;EAIf,WAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,iGAAA;AADJ;AAGI;EAVJ;IAWQ,iGAAA;EAAN;AACF;AACI;EAbJ;IAcQ,oGAAA;EAEN;AACF;;AACA;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAAI;EAPJ;IAQQ,gBAAA;IACA,iBAAA;EAGN;AACF;AAFI;EAXJ;IAYQ,iBAAA;IACA,kBAAA;EAKN;AACF","sourcesContent":["$background-color: #FFD100;\n\n.system-message {\n    background-color: $background-color;\n    width: 100%;\n    min-height: 42px;\n    font-size: 14px;\n    font-weight: 700;\n    display: grid;\n    align-items: center;\n    grid-template-columns: 15px [page-number] max-content 6px [message] auto [close] min-content 15px;\n\n    @media screen and (min-width: 768px) {\n        grid-template-columns: 36px [page-number] max-content 9px [message] auto [close] min-content 40px;\n    }\n    @media screen and (min-width: 1880px) {\n        grid-template-columns: 250px [page-number] max-content 60px [message] auto [close] min-content 280px;\n    }\n}\n\n.message-number {\n    font-size: 19px;\n    font-weight: 400;\n    margin-left: 3px;\n    margin-right: 3px;\n    white-space: nowrap;\n\n    @media screen and (min-width: 768px) {\n        margin-left: 5px;\n        margin-right: 5px;\n    }\n    @media screen and (min-width: 1880px) {\n        margin-left: 30px;\n        margin-right: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
