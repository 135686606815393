import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';

@Component({
  selector: 'app-import-error-modal',
  templateUrl: './import-error-modal.component.html',
  styleUrls: ['./import-error-modal.component.scss']
})
export class ImportErrorModalComponent extends LocalizableComponent {
  @Input() modal: NgbModalRef;

  constructor() {
    super();
   }

  dismissModal() {
    this.modal.close();
  }

}
