import { TranslationWidth } from '@angular/common';
import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Lang } from "../../types/language";

/**
 * By default, ng-bootstrap's datepicker has 2 letter day of the week names. We can provide a custom locale provider that returns the UPS-standard 3 letter names.
 */
@Injectable()
export class DatepickerLocalePreferences extends NgbDatepickerI18n {
  langCode: string;

  constructor() {
    super();
    this.langCode = localStorage.getItem('lang') || navigator.languages.find(x => x.indexOf('-') === -1 && (x == 'fr' || x == 'en')) || 'en';
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
      switch (this.langCode) {
        case Lang.French:
          return ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"][weekday - 1];
        default:
          return ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"][weekday - 1];
      }
  }

  getMonthShortName(month: number, year?: number): string {
    switch (this.langCode) {
      case Lang.French:
        return ["Janv", "Févr", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"][month - 1];
      default:
        return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month - 1];
    }
  }

  getMonthFullName(month: number, year?: number): string {
    switch (this.langCode) {
      case Lang.French:
        return ["Janvier", "Février", "Mars", "Avril", "Peut", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"][month - 1];
      default:
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][month - 1];
    }
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return jsDate.toLocaleDateString();
  }
}
