// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location {
  width: 328px;
  font-weight: normal;
  line-height: 16px;
  display: grid;
  grid-template-areas: "slic" "name" "address";
  row-gap: 1px;
}
.location div {
  height: 16px;
}
.location div:nth-child(1) {
  grid-area: slic;
  font-weight: 700;
}
.location div:nth-child(2) {
  grid-area: name;
  overflow: hidden;
  text-overflow: ellipsis;
}
.location div:nth-child(3) {
  grid-area: address;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/notification-settings/location-settings-grid/notification-location/notification-location.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,4CACE;EAGF,YAAA;AAFJ;AAII;EACE,YAAA;AAFN;AAKI;EACE,eAAA;EACA,gBAAA;AAHN;AAMI;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;AAJN;AAOI;EACE,kBAAA;EACA,gBAAA;EACA,uBAAA;AALN","sourcesContent":[".location {\n    width: 328px;\n    font-weight: normal;\n    line-height: 16px;\n    display: grid;\n    grid-template-areas: \n      \"slic\"\n      \"name\"\n      \"address\";\n    row-gap: 1px;\n  \n    div {\n      height: 16px;\n    }\n  \n    div:nth-child(1) {\n      grid-area: slic;\n      font-weight: 700;\n    }\n  \n    div:nth-child(2) {\n      grid-area: name;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  \n    div:nth-child(3) {\n      grid-area: address;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
