import { TrailerInfo } from "./trailerInfo";
import { TrailerLoad } from "./trailerLoad";
import { TrailerRequestResponse } from "./trailerRequestResponse";

//Used in the request creation forms and is translated to TrailerRequestResponse
//TODO: Probably want to change the names in the future
export class TrailerRequest {
  trailerRequestData: TrailerRequestResponse;

  get destinationCountryCode() { return this.primaryLoad.destinationCountryCode; }
  set destinationCountryCode(value: string) { this.primaryLoad.destinationCountryCode = value; }

  get destinationOrganizationAbbreviatedName() { return this.primaryLoad.destinationOrganizationAbbreviatedName; }
  set destinationOrganizationAbbreviatedName(value: string) { this.primaryLoad.destinationOrganizationAbbreviatedName = value; }

  get destinationOrganizationNumber() { return this.primaryLoad.destinationOrganizationNumber; }
  set destinationOrganizationNumber(value: string) { this.primaryLoad.destinationOrganizationNumber = value; }

  get originOrganizationAbbreviatedName() {
    if (!this.primaryLoad.originOrganizationAbbreviatedName) {
      return this.primaryLoad.destinationOrganizationAbbreviatedName;
    }
    return this.primaryLoad.originOrganizationAbbreviatedName;
  }

  get originOrganizationCountryCode() {
    if (!this.primaryLoad.originCountryCode) {
      return this.primaryLoad.destinationCountryCode;
    }
    return this.primaryLoad.originCountryCode;
  }

  get originOrganizationSlicNumber() {
    if (!this.primaryLoad.originOrganizationNumber) {
      return this.primaryLoad.destinationOrganizationNumber;
    }
    return this.primaryLoad.originOrganizationNumber;
  }

  get destinationSortCode() { return this.primaryLoad.destinationSortCode; }
  set destinationSortCode(value: string) { this.primaryLoad.destinationSortCode = value; }

  get palletCount() { return this.primaryLoad.palletCount; }
  set palletCount(value: number) { this.primaryLoad.palletCount = value; }

  get percentFull() { return this.primaryLoad.percentFull; }
  set percentFull(value: number) { this.primaryLoad.percentFull = value; }

  get pieceCount() { return this.primaryLoad.pieceCount; }
  set pieceCount(value: number) { this.primaryLoad.pieceCount = value; }

  get gaylords() { return this.primaryLoad.gaylords; }
  set gaylords(value: number) { this.primaryLoad.gaylords = value; }

  get bags() { return this.primaryLoad.bags; }
  set bags(value: number) { this.primaryLoad.bags = value; }

  get irregs() { return this.primaryLoad.irregs; }
  set irregs(value: number) { this.primaryLoad.irregs = value; }

  get trailerInfo() { return this.primaryLoad.trailerInfo; }
  set trailerInfo(value: TrailerInfo) { this.primaryLoad.trailerInfo = value; }

  get serviceLevel() { return this.primaryLoad.serviceLevel; }
  set serviceLevel(value: string) { this.primaryLoad.serviceLevel = value; }

  get trailerNumber() { return this.primaryLoad.trailerNumber; }
  set trailerNumber(value: string) { this.primaryLoad.trailerNumber = value; }

  get trailerType() { return this.primaryLoad.trailerType; }
  set trailerType(value: string) { this.primaryLoad.trailerType = value; }

  get bayNumber() { return this.primaryLoad.bayNumber; }
  set bayNumber(value: string) { this.primaryLoad.bayNumber = value; }

  get arrivalPickupDateTime() { return this.trailerRequestData.arrivalPickupDateTime; }
  set arrivalPickupDateTime(value: string) { this.trailerRequestData.arrivalPickupDateTime = value; }

  get arrivingPickupDateTime() { return this.trailerRequestData.arrivingPickupDateTime; }
  set arrivingPickupDateTime(value: string) { this.trailerRequestData.arrivingPickupDateTime = value; }

  get createdDateTime() { return this.trailerRequestData.createdDateTime; }
  set createdDateTime(value: string) { this.trailerRequestData.createdDateTime = value; }

  get canceledPickupDateTime() { return this.trailerRequestData.canceledPickupDateTime; }
  set canceledPickupDateTime(value: string) { this.trailerRequestData.canceledPickupDateTime = value; }

  get customer() { return this.trailerRequestData.customer; }
  set customer(value: string) { this.trailerRequestData.customer = value; }

  get customerLocationName() { return this.trailerRequestData.customerLocationName; }
  set customerLocationName(value: string) { this.trailerRequestData.customerLocationName = value; }

  get deliveryNumber() { return this.trailerRequestData.deliveryNumber; }
  set deliveryNumber(value: string) { this.trailerRequestData.deliveryNumber = value; }

  get dueDate() { return this.trailerRequestData.dueDate; }
  set dueDate(value: string) { this.trailerRequestData.dueDate = value; }

  get earliestPickupDateTime() { return this.trailerRequestData.earliestPickupDateTime; }
  set earliestPickupDateTime(value: string) { this.trailerRequestData.earliestPickupDateTime = value; }

  get extendedHours() { return this.trailerRequestData.extendedHours; }
  set extendedHours(value: string) { this.trailerRequestData.extendedHours = value; }

  get jobNumber() { return this.trailerRequestData.jobNumber; }
  set jobNumber(value: string) { this.trailerRequestData.jobNumber = value; }

  get latestPickupDateTime() { return this.trailerRequestData.latestPickupDateTime; }
  set latestPickupDateTime(value: string) { this.trailerRequestData.latestPickupDateTime = value; }

  get pickUpNumber() { return this.trailerRequestData.pickUpNumber; }
  set pickUpNumber(value: string) { this.trailerRequestData.pickUpNumber = value; }

  get pickUpWindow() { return this.trailerRequestData.pickUpWindow; }
  set pickUpWindow(value: string) { this.trailerRequestData.pickUpWindow = value; }

  get rejectedPickupDateTime() { return this.trailerRequestData.rejectedPickupDateTime; }
  set rejectedPickupDateTime(value: string) { this.trailerRequestData.rejectedPickupDateTime = value; }

  get rejectReason() { return this.trailerRequestData.rejectReason; }
  set rejectReason(value: string) { this.trailerRequestData.rejectReason = value; }

  get requestorFirstName() { return this.trailerRequestData.requestorFirstName; }
  set requestorFirstName(value: string) { this.trailerRequestData.requestorFirstName = value; }

  get requestorId() { return this.trailerRequestData.requestorId; }
  set requestorId(value: string) { this.trailerRequestData.requestorId = value; }

  get requestorLastName() { return this.trailerRequestData.requestorLastName; }
  set requestorLastName(value: string) { this.trailerRequestData.requestorLastName = value; }

  get requestCategory() { return this.trailerRequestData.requestCategory; }
  set requestCategory(value: string) { this.trailerRequestData.requestCategory = value; }

  get requestId() { return this.trailerRequestData.requestId; }
  set requestId(value: string) { this.trailerRequestData.requestId = value; }

  get requestReason() { return this.trailerRequestData.requestReason; }
  set requestReason(value: string) { this.trailerRequestData.requestReason = value; }

  get requestStatus() { return this.trailerRequestData.requestStatus; }
  set requestStatus(value: string) { this.trailerRequestData.requestStatus = value; }

  get requestType() { return this.trailerRequestData.requestType; }
  set requestType(value: string) { this.trailerRequestData.requestType = value; }

  get sortDateTime() { return this.trailerRequestData.sortDateTime; }
  set sortDateTime(value: string) { this.trailerRequestData.sortDateTime = value; }

  get cancelReason() { return this.trailerRequestData.cancelReason; }
  set cancelReason(value: string) { this.trailerRequestData.cancelReason = value; }

  get lastUpdatedByName() { return this.trailerRequestData.lastUpdatedByName; }
  set lastUpdatedByName(value: string) { this.trailerRequestData.lastUpdatedByName = value; }

  get lastUpdatedByUserId() { return this.trailerRequestData.lastUpdatedByUserId; }
  set lastUpdatedByUserId(value: string) { this.trailerRequestData.lastUpdatedByUserId = value; }

  get lastUpdatedDateTime() { return this.trailerRequestData.lastUpdatedDateTime; }
  set lastUpdatedDateTime(value: string) { this.trailerRequestData.lastUpdatedDateTime = value; }

  get scheduledPickupDateTime() { return this.trailerRequestData.scheduledPickupDateTime; }
  set scheduledPickupDateTime(value: string) { this.trailerRequestData.scheduledPickupDateTime = value; }

  get specialInstructions() { return this.trailerRequestData.specialInstructions; }
  set specialInstructions(value: string) { this.trailerRequestData.specialInstructions = value; }

  get tractorNumber() { return this.trailerRequestData.tractorNumber; }
  set tractorNumber(value: string) { this.trailerRequestData.tractorNumber = value; }

  get updateTypeCode() { return this.trailerRequestData.updateTypeCode; }
  set updateTypeCode(value: string) { this.trailerRequestData.updateTypeCode = value; }

  get numberTrailersRequested() { return this.trailerRequestData.numberTrailersRequested; }
  set numberTrailersRequested(value: number) { this.trailerRequestData.numberTrailersRequested = value; }

  get primaryLoad(): TrailerLoad {
    if (['Switch', 'Live Load', 'Retrieve Empty', 'Retrieve Load'].includes(this.requestType) && this.trailerRequestData.trailerLoads.length > 1) {
      return this.trailerRequestData.trailerLoads[1];
    }

    return this.trailerRequestData.trailerLoads[0];
  }

  constructor(requestType: string, customerSlic: string, model?: TrailerRequestResponse) {
    const data = model ? model : new TrailerRequestResponse();
    if (!model) {
      data.requestType = requestType;
      data.trailerLoads[0].originOrganizationAbbreviatedName = customerSlic;
    }
    this.trailerRequestData = data;
  }
}
