export enum Role {
  NonUser = 0,
  ReadonlyUser = 1,
  User = 2,
  Admin = 4,
  FeederIE = 8,
  AccountExec = 16,
  SuperUser = 32,
  PendingReadOnly = 64,
  PendingUser = 128,
  PendingAdmin = 256
}
