export class Sorter<T> {
    sortValues(items: T[], key: keyof(T), secondaryKey: keyof(T) = null, ascending: boolean = true, customSortFunction: (a:T,b:T) => number = null): T[] {
        if(items?.length > 0) {
            if(customSortFunction) {
                return items.sort(customSortFunction);
            }

            return items.sort((a, b) => {
                return this.sort(a[key], b[key], ascending) || this.sort(a[secondaryKey], b[secondaryKey], ascending);
            })
        } else {
            return items;
        }

    }

    sortString(value1: any, value2: any, ascending: boolean): number {
        if(ascending) {
            return ('' + value1).localeCompare('' + value2);
        } else {
            return ('' + value2).localeCompare('' + value1);
        }
        
    }

    sort(value1: any, value2: any, ascending: boolean) {
        if(typeof(value1) == "string" && typeof(value2) == "string") {
            return this.sortString(value1, value2, ascending);
        }
        
        if(value1 < value2) {
            return ascending ? -1 : 1;
        } else if(value1 == value2) {
            return 0;
        } else {
            return ascending ? 1 : -1;
        }
    }
}
