import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, interval } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AppState } from './app.state';
import { AuthService } from './auth/auth.service';
import { LocalizableComponent } from './components/localizable/localizable.component';
import { FeatureFlagService } from './services/feature-flag.service';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { MaintenanceService } from './services/maintenance.service';
import { NotificationsService } from './services/notifications.service';
import { StorageAccountService } from './services/storage-account.service';
import { UserDataService } from './services/user-data.service';
import { loadLocationListTimer } from './store/actions/location-list.actions';

@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent extends LocalizableComponent implements OnInit, OnDestroy {
  title = 'app';
  locationListUpdateInterval: number = 0;
  @ViewChild(RouterOutlet, { static: false }) outlet;
  DEFAULT_APPLICATION_TIMEOUT_TIME_MS = 1000 * 30 * 60;                   //30 minutes
  userActivity;
  userInactive: Subject<any> = new Subject();
  disconnect$: Subject<boolean> = new Subject<boolean>();
  maintenance: boolean = false;
  userDataIsStale: boolean = false;
  notificationDataIsStale: boolean = false;
  storageAccountIsDown: boolean = false;
  storageAccountCheckTimer = null;
  locationListLoading$: Observable<boolean>;
  allowedToUpdateInactivityTracker: boolean = true;
  inactivityTimeoutPeriodMs: number = this.DEFAULT_APPLICATION_TIMEOUT_TIME_MS;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private loadingIndicatorService: LoadingIndicatorService,
    private maintenanceService: MaintenanceService,
    private userDataService: UserDataService,
    private storageAccountService: StorageAccountService,
    private apiService: ApiService,
    private featureFlagService: FeatureFlagService,
    private notificationsService: NotificationsService
  ) {
    super();
    this.locationListLoading$ = this.loadingIndicatorService.locationListLoading$;
  }

  ngOnInit() {
    this.loadingIndicatorService.locationListLoading$.next(true);

    this.authService.loggedInEvent$
      .pipe(first())
      .subscribe(() => {
        this.maintenanceService.queryMaintenance();
        this.maintenanceService.maintenance$
          .pipe(takeUntil(this.disconnect$))
          .subscribe(inMaintenance => {

            this.maintenance = inMaintenance;

            if (!inMaintenance) {
              this.locationListUpdateInterval = setInterval(() => {
                if (this.authService.loggedIn)
                  this.store.dispatch(loadLocationListTimer());
              }, 30 * 1000) as any;
            }
            else if (this.locationListUpdateInterval) {
              clearInterval(this.locationListUpdateInterval);
              this.locationListUpdateInterval = 0;
            }
          });

        this.setInactiveTimeout();
        this.userInactive
          .pipe(takeUntil(this.disconnect$))
          .subscribe(() => this.timeoutDetected());

        this.featureFlagService.sdkReady$
          .pipe(takeUntil(this.disconnect$))
          .subscribe(() => {
            const featureFlagTime = this.featureFlagService.checkFeatureFlag('inactivity-timeout-period-ms', this.DEFAULT_APPLICATION_TIMEOUT_TIME_MS);
            if (featureFlagTime < this.inactivityTimeoutPeriodMs) {
              this.inactivityTimeoutPeriodMs = featureFlagTime;
              clearTimeout(this.userActivity);
              this.setInactiveTimeout(1); // Set timeout for 1ms to check against new timeout period instantly
            }
            else {
              this.inactivityTimeoutPeriodMs = featureFlagTime;
            }
          });

        this.userDataService.isStale
          .pipe(takeUntil(this.disconnect$))
          .subscribe(x => this.userDataIsStale = x);

        this.storageAccountService.isDown
          .pipe(takeUntil(this.disconnect$))
          .subscribe(isDown => {
            this.storageAccountIsDown = isDown;
            if (this.storageAccountIsDown && this.storageAccountCheckTimer == null) {
              this.createStorageAccountCheckTimer();
            }
          });

        this.notificationsService.notificationSettingsOutOfDate$
          .pipe(takeUntil(this.disconnect$))
          .subscribe(x => this.notificationDataIsStale = x);

        this.storageAccountIsDown = localStorage.getItem("storageAccountDown") == "true";
        if (this.storageAccountIsDown) {
          this.createStorageAccountCheckTimer();
        }
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }


  timeoutDetected() {
    console.log("Timer Logging out");
    this.authService.loggedOutEvent$.next();
  }

  setInactiveTimeout(timerPeriodMs: number = this.inactivityTimeoutPeriodMs) {
    this.userActivity = setTimeout(() => {
      const time = localStorage.getItem("lastTrsSuiteActiveTime");
      const now = Date.now();
      if (!time || now - parseInt(time) > this.inactivityTimeoutPeriodMs) {
        !time ?
          console.log('No lastTrsSuiteActiveTime found. Logging user out.') :
          console.log(`Logging user out. (now): ${now}, (lastTrsSuiteActiveTime): ${time}, (diff): ${now - parseInt(time)}, (timeout): ${this.inactivityTimeoutPeriodMs}`);

        this.userInactive.next(undefined);
      }
      else {
        clearTimeout(this.userActivity);
        const newTimerPeriodMs = this.inactivityTimeoutPeriodMs - (now - parseInt(time));
        this.setInactiveTimeout(newTimerPeriodMs);
        console.log(`Detected activity. Resetting timer. (now): ${now}, (lastTrsSuiteActiveTime): ${time}, (newTimer): ${newTimerPeriodMs}, (timeout): ${this.inactivityTimeoutPeriodMs}`);
      }
    }, timerPeriodMs);
  }

  @HostListener('window:resize')
  @HostListener('window:scroll')
  @HostListener('window:mousemove')
  @HostListener('document:click')
  @HostListener('document:wheel')
  @HostListener('document:scroll')
  @HostListener('document:mousemove')
  @HostListener('document:keyup')
  @HostListener('body:scroll')
  refreshUserState() {
    if (this.allowedToUpdateInactivityTracker) {
      this.allowedToUpdateInactivityTracker = false;
      localStorage.setItem("lastTrsSuiteActiveTime", Date.now().toString());

      // Allow updates at most every 30 seconds
      setTimeout(() => this.allowedToUpdateInactivityTracker = true, 30000);

      clearTimeout(this.userActivity);
      this.setInactiveTimeout();
    }
  }

  onScroll() {
    if (this.outlet.component.requests) //I'm the home component!
      this.outlet.component.onScroll();
  }

  createStorageAccountCheckTimer() {
    this.storageAccountCheckTimer = interval(30000).pipe(takeUntil(this.disconnect$))
      .subscribe(() => this.updateStorageAccountStatus());
  }

  updateStorageAccountStatus() {
    this.apiService.StorageAccountIsDown()
      .pipe(takeUntil(this.disconnect$))
      .subscribe(response => {
        this.storageAccountIsDown = response;
        if (!this.storageAccountIsDown) {
          localStorage.removeItem("storageAccountDown");
          this.storageAccountCheckTimer.unsubscribe();
          this.storageAccountService.isDown.emit(false);
        }
      });
  }
}
