import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { LangSection, LangText } from 'src/app/types/language';
import { LocationUserImportError } from 'src/app/types/locationUserImportError';
import { Role } from 'src/app/types/role';

@Component({
  selector: 'app-import-invalid-entries-modal',
  templateUrl: './import-invalid-entries-modal.component.html',
  styleUrls: ['./import-invalid-entries-modal.component.scss']
})
export class ImportInvalidEntriesModalComponent extends LocalizableComponent {
  @Input() modal: NgbModalRef;
  @Input() errors: LocationUserImportError[];

  constructor() {
    super();
  }

  dismissModal() {
    this.modal.close();
  }

  roleToText(role: Role) {
    switch (role) {
      case Role.Admin:
        return this.localize(LangSection.Role, LangText.Administrator);
      case Role.User:
        return this.localize(LangSection.Role, LangText.ViewEdit);
      case Role.ReadonlyUser:
        return this.localize(LangSection.Role, LangText.ViewOnly);
      default:
        return '';
    }
  }

}
