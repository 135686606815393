import { LocationProfile } from "src/app/types/locationProfile";
import { Sort } from "src/app/types/states/locationUsersState";
import { UserWithLocations } from "src/app/types/userWithLocations";

export const REFRESH_LOCATION_USERS = 'REFRESH_LOCATION_USERS';
export const SORT_LOCATION_USERS_LOCATIONS = 'SORT_LOCATION_USERS_LOCATIONS';
export const SORT_LOCATION_USERS_USERS = 'SORT_LOCATION_USERS_USERS';

export const removeLocationUser = (userId: string) => ({
  type: REFRESH_LOCATION_USERS,
  payload: userId
})

export const sortLocations = (sortParams: Sort<LocationProfile>) => ({
  type: SORT_LOCATION_USERS_LOCATIONS,
  payload: sortParams
})

export const sortUsers = (sortParams: Sort<UserWithLocations>) => ({
  type: SORT_LOCATION_USERS_USERS,
  payload: sortParams
})