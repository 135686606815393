import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestSubForm } from '../request-sub-form.component';

@Component({
  selector: 'request-empty-request-form',
  templateUrl: './request-empty-request-form.component.html',
  styleUrls: []
})
export class RequestEmptyRequestFormComponent extends RequestSubForm {
  pageName = 'RequestEmpty';
}
