import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizableComponent } from '../localizable/localizable.component';

@Component({
  selector: 'on-off-tri-toggle',
  templateUrl: './on-off-tri-toggle.component.html',
  styleUrls: ['./on-off-tri-toggle.component.scss']
})
export class OnOffTriToggleComponent extends LocalizableComponent {
  @Input() boolValue: boolean = false;
  @Input() partial: boolean = false;
  @Input() active: boolean = false;
  @Output() onChange = new EventEmitter<boolean>();

  toggle(ignoreOnPartial = true) {
    if (!this.active || (ignoreOnPartial && this.partial))
      return;

    this.boolValue = !this.boolValue;
    this.onChange.emit(this.boolValue);
  }

  toggleOn() {
    if (!this.active)
      return;

    if (!this.partial) {
      this.toggle(false);
      return;
    }

    this.boolValue = true;
    this.onChange.emit(this.boolValue);
  }

  toggleOff() {
    if (!this.active)
      return;

      if (!this.partial) {
        this.toggle(false);
        return;
      }
  
    this.boolValue = false;
    this.onChange.emit(this.boolValue);
  }

}
