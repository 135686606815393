import { Injectable, OnDestroy } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, debounceTime, filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { LocationListService } from 'src/app/services/location-list.service';
import { ApiService } from "../../api.service";
import { AppState } from "../../app.state";
import { FETCH_MORE_REQUESTS, setStatusFilters, SET_STATUS_FILTERS, TOGGLE_SORT_DIRECTION, UPDATE_DATE_RANGE, UPDATE_FILTERS, UPDATE_LOCATIONS, UPDATE_SEARCH_QUERY, UPDATE_SORT_FILTER, UPDATE_STATUS_FILTER } from "../actions/filter.actions";
import { addTrailerRequests, REFRESH_TRAILER_REQUESTS, REQUEST_CHANGES_DETECTED, setTrailerRequests, updateTrailerRequests } from "../actions/trailer-requests.actions";

@Injectable()
export class TrailerRequestEffects implements OnDestroy {
  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<AppState>,
    private loadingIndicatorService: LoadingIndicatorService,
    private locationListService: LocationListService
  ) {
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  updateStatusFilters$: Observable<Action> = createEffect(() =>
    this.actions$
      .pipe(
        ofType(UPDATE_STATUS_FILTER),
        debounceTime(50),
        withLatestFrom(this.store$),
        map(([action, state]) => {
          const { payload: { statusFilter } } = action;

          //If ALL was clicked, clear out others
          if (statusFilter === 0)
            return setStatusFilters([0]);
          //If this status is already selected, remove it
          else if (state.requestFilters.statusFilters.includes(statusFilter))
            //If this was the only one, set it back to ALL
            if (state.requestFilters.statusFilters.length === 1)
              return setStatusFilters([0]);
            else
              return setStatusFilters(
                state.requestFilters.statusFilters
                  .filter(statusIndex =>
                    statusIndex !== statusFilter
                  )
              );
          //If we've selected all available options, replace with ALL
          else if (state.requestFilters.statusFilters.length === 7) {
            return setStatusFilters([0]);
          }
          //Otherwise, add this status and filter out ALL
          else
            return setStatusFilters([
              ...state.requestFilters.statusFilters,
              statusFilter
            ].filter(statusIndex =>
              statusIndex !== 0
            ));
        })
  ));

  getRequests$: Observable<Action> = createEffect(() =>
    this.actions$
      .pipe(
        ofType(
          UPDATE_FILTERS,
          UPDATE_DATE_RANGE,
          UPDATE_LOCATIONS,
          UPDATE_SEARCH_QUERY,
          UPDATE_SORT_FILTER,
          SET_STATUS_FILTERS,
          TOGGLE_SORT_DIRECTION,
          FETCH_MORE_REQUESTS
        ),
        debounceTime(100),
        withLatestFrom(this.store$),
        switchMap(([action, state]: [Action, AppState]) => {
          this.locationListService.lastUpdateFromTimer.next(false);
          let filterPayload = state.requestFilters;
          if (action.type !== FETCH_MORE_REQUESTS) {
            filterPayload = { ...state.requestFilters, skip: 0 };
            if (action.type !== UPDATE_SEARCH_QUERY || !filterPayload.query)
              this.loadingIndicatorService.trailerRequestsLoading$.next(true);
          }
          else{
            if (state.trailerRequests.requests.length === state.trailerRequests.metrics.totalCount || 
              state.trailerRequests.requests.length % filterPayload.take !== 0) {
                return EMPTY;
            }
            
            filterPayload = {...state.requestFilters, skip: state.trailerRequests.requests.filter(tr => !tr.attentionNeeded).length}
          }

          return this.apiService.getAllRequests(filterPayload).pipe(
            filter(requestData =>  {
              if (action.type === FETCH_MORE_REQUESTS && requestData.requests.length == 0) {
                return false;
              }
              return true;
            }),
            map(requestData => {
              const existingRequests = state.trailerRequests?.requests;
              if (action.type === FETCH_MORE_REQUESTS && filterPayload.skip != 0) {
                //Dispatch action to add new requests to list
                this.loadingIndicatorService.trailerRequestsLoading$.next(false)
                return addTrailerRequests(requestData, existingRequests.length + requestData.requests.length);
              } else {
                //Dispatch action to replace request listing
                this.loadingIndicatorService.trailerRequestsLoading$.next(false)
                return setTrailerRequests(requestData)
              }
            }),
            catchError(() => EMPTY)
          );
        })
  ));

  updateTrailerRequests$: Observable<Action> = createEffect(() =>
    this.actions$
      .pipe(
        ofType(REFRESH_TRAILER_REQUESTS),
        withLatestFrom(this.store$),
        switchMap(([action, state]) => {
          const requests = state.trailerRequests?.requests;
          if (!requests || requests.length === 0) {
            return EMPTY;
          }

          return this.apiService.getTrailerRequestUpdates(requests).pipe(
            map(requestData => updateTrailerRequests(requestData)),
            catchError(() => EMPTY)
          );
        })
  ));

  requestChangesDetected$: Observable<Action> = createEffect(() =>
    this.actions$
      .pipe(
        ofType(REQUEST_CHANGES_DETECTED),
        withLatestFrom(this.store$),
        switchMap(([action, state]) => {
          const requests = state.trailerRequests?.requests;
          if (!requests) {
            return EMPTY;
          }

          const takeLength = requests.length > state.requestFilters.take ? requests.length : state.requestFilters.take;
          const filterPayload = { ...state.requestFilters, skip: 0, take: takeLength };
          return this.apiService.getAllRequests(filterPayload).pipe(
            map(requestData => setTrailerRequests(requestData)),
            catchError(() => EMPTY)
          );
        })
  ));
}
