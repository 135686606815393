import LocationUsersState from "src/app/types/states/locationUsersState";
import { REFRESH_LOCATION_USERS, SORT_LOCATION_USERS_LOCATIONS, SORT_LOCATION_USERS_USERS } from "../actions/location-users.actions";

const defaultState: LocationUsersState = {
    removedUser: "",
    locationSort: {
      field: "slicName",
      isAscending: true
    },
    userSort: {
      field: "upsId",
      isAscending: true
    }
}

export function locationUsersReducer(state: LocationUsersState = defaultState, { type, payload }): LocationUsersState {
    switch (type) {
      case REFRESH_LOCATION_USERS:
          return {...state, removedUser: payload};
      case SORT_LOCATION_USERS_LOCATIONS:
        return {...state, locationSort: payload};
      case SORT_LOCATION_USERS_USERS:
        return {...state, userSort: payload};
      default:
        return state;
    }
  }