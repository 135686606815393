import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { updateLocationUsersFilter as updateUserSearch } from 'src/app/store/actions/filter.actions';
import { debounceFunction } from 'src/decorators/debounceFunction';

@Component({
  selector: 'app-users-search',
  templateUrl: './users-search.component.html',
  styleUrls: ['./users-search.component.scss']
})
export class UsersSearchComponent extends LocalizableComponent implements OnInit {

  public userSearchString: string = "";
  public iconClass: string = 'search icon';

  constructor(private store: Store<AppState>) {
    super();
   }

  ngOnInit(): void {
    this.store.dispatch(updateUserSearch(this.userSearchString));
  }

  @debounceFunction(300)
  searchForUser() {
    this.iconClass = this.userSearchString === "" ? 'search icon' : 'clear icon';
    this.store.dispatch(updateUserSearch(this.userSearchString));
  }

  clearUserSearch() {
    this.userSearchString = "";
    this.searchForUser();
  }
}
