import { Role } from "./role";
import { UserLocationRole } from "./userLocationRole";
import { UserWithLocations } from "./userWithLocations";

export class ExportUser {
    upsId: string;
    email: string;
    role: Role;
    lastUpdatedAt: string;
    lastUpdatedBy: string;

    constructor (user: UserWithLocations, location: UserLocationRole) {
        this.upsId = user.upsId;
        this.email = user.email;
        this.role = location.role;
        this.lastUpdatedAt = location.lastUpdatedAt;
        this.lastUpdatedBy = location.lastUpdatedBy;
    }
}