import { LocationProfile } from "./locationProfile";
import { StatusChangeNotifications } from "./StatusChangeNotifications";

export class NotificationUserLocationSettings {
    notificationSettingsId: string;
    upsIdLower: string;
    slicNumber: string;
    countryCode: string;
    statusChange: boolean = false;
    statusChangeNotifications: StatusChangeNotifications = {
        attentionNeeded: false,
        pending: false,
        approved: false,
        scheduled: false,
        inTransit: false,
        onProperty: false,
        completed: false,
        canceled: false
    };

    comment: boolean = false;
    requestEdited: boolean = false;
    userRequest: boolean = false;

    slicName?: string;
    customName?: string;
    address?: string;
    errors: NotificationSettingsErrors;
    lastModifiedUtc?: string;

    constructor(location?: LocationProfile) {
        if (location) {
            this.slicNumber = location.slicNumber;
            this.countryCode = location.countryCode;
            this.slicName = location.slicName;
            this.customName = location.customName;
            this.address = location.address;
        }

        this.errors = new NotificationSettingsErrors();
    }

    public clone(setting: NotificationUserLocationSettings = null): NotificationUserLocationSettings {
        if (!setting)
            setting = this;

        return Object.assign(new NotificationUserLocationSettings(), setting, { statusChangeNotifications: { ...setting.statusChangeNotifications } });
    }

    public merge(update: NotificationUserLocationSettings) {
        let setting = this;
        return Object.assign(setting, update, { statusChangeNotifications: { ...update.statusChangeNotifications } });
    }

}

export class NotificationSettingsErrors {
    statusChange: boolean = false;
    comment: boolean = false;
    requestEdited: boolean = false;
    userRequest: boolean = false;
}

