import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';
import { Lang } from '../types/language';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  languageCode: Lang;

  constructor(private _dateService: DateService) {
    this.languageCode = localStorage.getItem('lang') as Lang | undefined ||
      navigator.languages.find(x => x.indexOf('-') === -1 && (x == 'fr' || x == 'en')) as Lang | undefined ||
      Lang.English;
  }

  transform(value: any, pattern: string = 'M/d', frPattern: string = 'yyyy-MM-dd', offset: number = this._dateService.getTranslatedBrowserOffset()) {
    if (isNaN(Date.parse(value)))
      return '';

    const datePipe: DatePipe = new DatePipe("en-US");
    let parsedDate = Date.parse(value);
    parsedDate -= this._dateService.getTimezoneOffsetDifferenceInMs(offset);

    if (this.languageCode === Lang.English) {
      return datePipe.transform(parsedDate, pattern);
    } else {
      return datePipe.transform(parsedDate, frPattern);
    }
  }
}
