// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-sort .sort-controls {
  display: flex;
  flex-direction: row;
}
.users-sort .sort-controls ups-select {
  width: 250px;
}
.users-sort .up-and-down-sort {
  box-shadow: none;
  background-color: white;
  border: thin #757575 solid;
  margin-left: 10px;
}
.users-sort .up-and-down-sort:before {
  font-family: upsicons, fantasy !important;
  content: "\\e619" !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItc29ydC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGFBQUE7RUFDQSxtQkFBQTtBQUFSO0FBRVE7RUFDSSxZQUFBO0FBQVo7QUFJSTtFQUNJLGdCQUFBO0VBQ0EsdUJBQUE7RUFDQSwwQkFBQTtFQUNBLGlCQUFBO0FBRlI7QUFJUTtFQUNJLHlDQUFBO0VBQ0EsMkJBQUE7QUFGWiIsImZpbGUiOiJ1c2VyLXNvcnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudXNlcnMtc29ydCB7XG4gICAgLnNvcnQtY29udHJvbHMge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuXG4gICAgICAgIHVwcy1zZWxlY3Qge1xuICAgICAgICAgICAgd2lkdGg6IDI1MHB4O1xuICAgICAgICB9XG4gICAgfVxuXG4gICAgLnVwLWFuZC1kb3duLXNvcnQge1xuICAgICAgICBib3gtc2hhZG93OiBub25lO1xuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICAgICAgYm9yZGVyOiB0aGluICM3NTc1NzUgc29saWQ7XG4gICAgICAgIG1hcmdpbi1sZWZ0OiAxMHB4O1xuXG4gICAgICAgICY6YmVmb3JlIHtcbiAgICAgICAgICAgIGZvbnQtZmFtaWx5OiB1cHNpY29ucywgZmFudGFzeSAhaW1wb3J0YW50O1xuICAgICAgICAgICAgY29udGVudDogXCJcXGU2MTlcIiAhaW1wb3J0YW50O1xuXG4gICAgICAgIH1cbiAgICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/location-users/user-sort/user-sort.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,gBAAA;EACA,uBAAA;EACA,0BAAA;EACA,iBAAA;AAFR;AAIQ;EACI,yCAAA;EACA,2BAAA;AAFZ;AACA,4/BAA4/B","sourcesContent":[".users-sort {\n    .sort-controls {\n        display: flex;\n        flex-direction: row;\n\n        ups-select {\n            width: 250px;\n        }\n    }\n\n    .up-and-down-sort {\n        box-shadow: none;\n        background-color: white;\n        border: thin #757575 solid;\n        margin-left: 10px;\n\n        &:before {\n            font-family: upsicons, fantasy !important;\n            content: \"\\e619\" !important;\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
