import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {

  private _environmentSubject = new BehaviorSubject<any>(null);
  public environment$ = this._environmentSubject.asObservable();

  private isLocalHost = window.location.origin.indexOf('localhost') > -1;

  constructor(private http: HttpClient) {
    this.getEnvironment().subscribe(value => {
      if (value && (value.ok || value.environment)) {
        this._environmentSubject.next(value);
      }
    });
  }

  public getEnvironment(): Observable<any> {
    return this.isLocalHost ? of(environment) : this.http.get('./api/environment').pipe(
      catchError(err => {
        return of(err);
      }));
  }

  public getNcmLink(): string {
    return this.isLocalHost ? "https://trsdev.ups.com/network-carrier-management/" : `${window.location.origin}/network-carrier-management`;
  }
}
