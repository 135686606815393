import { Action } from '@ngrx/store';
import { LocationInfo } from "../../types/locationInfo";
import { LocationProfile } from "../../types/locationProfile";
import { LocationSortType } from '../../types/locationSortType';
import { LocationListState } from "../../types/states/locationListState";
import { SET_LOCATION_LIST, SET_LOCATION_LIST_QUERY, SET_LOCATION_USERS, TOGGLE_LOCATION_SORT_DIRECTION, UPDATE_LOCATION_PROFILE, UPDATE_LOCATION_SORT_FILTER } from "../actions/location-list.actions";

const defaultState: LocationListState = {
  query: '',
  sortType: LocationSortType.CODE,
  ascending: true,
  locations: null
}
export function locationListReducer(state: LocationListState = defaultState, action: Action): LocationListState {
  switch (action.type) {
    case SET_LOCATION_LIST:
      let locations = (action as any).payload as LocationInfo[];
      if (locations) {
        locations = locations.map(loc => {
          let location = state.locations?.find(l => l.slicNumber == loc.slicNumber && l.countryCode == loc.countryCode);
          return { ...loc, userList: (location) ? location.userList : [] };
        });
      }

      return {
        ...state,
        locations: locations
      };
    case SET_LOCATION_LIST_QUERY:
      return { ...state, query: (action as any).payload.query };
    case SET_LOCATION_USERS:
      const { slicNumber, countryCode, users } = (action as any).payload;
      let userLocations = state.locations;
      let location = userLocations?.find(loc => loc.slicNumber === slicNumber && loc.countryCode === countryCode);
      if (location) {
        userLocations = userLocations.map(loc => {
          return {
            ...loc,
            userList: (loc === location) ? [...users] : loc.userList
          };
        });
      }

      return {
        ...state,
        locations: userLocations
      };
    case UPDATE_LOCATION_PROFILE:
      const { profile }: { profile: LocationProfile } = (action as any).payload;
      let profileLocation = state.locations?.find(loc => loc.slicNumber === profile.slicNumber && loc.countryCode == profile.countryCode);
      if (profileLocation) {
        return {
          ...state,
          locations: state.locations?.map(loc => (loc === profileLocation) ? { ...loc, profile: profile } : loc)
        };
      } else {
        return state;
      }
    case TOGGLE_LOCATION_SORT_DIRECTION:
      return { ...state, ascending: !state.ascending };
    case UPDATE_LOCATION_SORT_FILTER:
      return { ...state, sortType: (action as any).payload.sortType };
    default:
      return state;
  }
}
