import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentStatus } from 'src/app/types/locationProfile';
import { AppState } from '../../../app.state';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { TrailerRequestResponse } from '../../../types/trailerRequestResponse';


@Component({
  selector: 'request-info',
  templateUrl: './request-info.component.html',
  styleUrls: ['./request-info.component.scss']
})
export class RequestInfoComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() request: TrailerRequestResponse;

  disconnect$: Subject<boolean> = new Subject<boolean>();

  customName: string = "";
  softDeleted: boolean = false;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(locationList => {
        const location = locationList.locations.find(loc => this.request.slicName == loc.slicName);
        if (location) {
          this.customName = location.profile.customName;
          this.softDeleted = location.profile.documentStatus === DocumentStatus.SoftDelete;
        }
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  get plannedTime() {
    if (['request empty', 'delivery'].includes(this.request.requestType.toLowerCase())) {
      return this.request.latestPickupDateTime;
    } else {
      return this.request.earliestPickupDateTime;
    }
  }

  get showPlannedTime() {
    return this.request.requestCategory.toLowerCase() === 'planned';
  }

  get showArriveTime() {
    return ['request empty', 'delivery'].includes(this.request.requestType.toLowerCase());
  }

  getAlphaSlic(slic: string): string {
    const slicArray = slic.split("::");
    return slicArray[2];
  }

}
