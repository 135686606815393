import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestSubForm } from '../request-sub-form.component';

@Component({
  selector: 'retrieve-load-request-form',
  templateUrl: './retrieve-load-request-form.component.html',
  styleUrls: []
})
export class RetrieveLoadRequestFormComponent extends RequestSubForm {
  pageName = 'RequestRetrieveLoad';
}
