// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-sort .sort-controls {
  display: flex;
  flex-direction: row;
}
.location-sort .sort-controls ups-select {
  width: 250px;
}
.location-sort .up-and-down-sort {
  box-shadow: none;
  background-color: white;
  border: thin #757575 solid;
  margin-left: 10px;
}
.location-sort .up-and-down-sort:before {
  font-family: upsicons, fantasy !important;
  content: "\\e619" !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvY2F0aW9uLXNvcnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUk7RUFDSSxhQUFBO0VBQ0EsbUJBQUE7QUFEUjtBQUdRO0VBQ0UsWUFBQTtBQURWO0FBS007RUFDRSxnQkFBQTtFQUNBLHVCQUFBO0VBQ0EsMEJBQUE7RUFDQSxpQkFBQTtBQUhSO0FBS1E7RUFDRSx5Q0FBQTtFQUNBLDJCQUFBO0FBSFYiLCJmaWxlIjoibG9jYXRpb24tc29ydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5sb2NhdGlvbi1zb3J0IHtcblxuICAgIC5zb3J0LWNvbnRyb2xzIHtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICAgIFxuICAgICAgICB1cHMtc2VsZWN0IHtcbiAgICAgICAgICB3aWR0aDogMjUwcHg7XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICAgIFxuICAgICAgLnVwLWFuZC1kb3duLXNvcnQge1xuICAgICAgICBib3gtc2hhZG93OiBub25lO1xuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbiAgICAgICAgYm9yZGVyOiB0aGluICM3NTc1NzUgc29saWQ7XG4gICAgICAgIG1hcmdpbi1sZWZ0OiAxMHB4O1xuICAgICAgXG4gICAgICAgICY6YmVmb3JlIHtcbiAgICAgICAgICBmb250LWZhbWlseTogdXBzaWNvbnMsIGZhbnRhc3kgIWltcG9ydGFudDtcbiAgICAgICAgICBjb250ZW50OiBcIlxcZTYxOVwiICFpbXBvcnRhbnQ7XG4gICAgICAgICAgIFxuICAgICAgICB9XG4gICAgICB9XG59XG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/location-users/location-sort/location-sort.component.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,mBAAA;AADR;AAGQ;EACE,YAAA;AADV;AAKM;EACE,gBAAA;EACA,uBAAA;EACA,0BAAA;EACA,iBAAA;AAHR;AAKQ;EACE,yCAAA;EACA,2BAAA;AAHV;AACA,4jCAA4jC","sourcesContent":[".location-sort {\n\n    .sort-controls {\n        display: flex;\n        flex-direction: row;\n      \n        ups-select {\n          width: 250px;\n        }\n      }\n      \n      .up-and-down-sort {\n        box-shadow: none;\n        background-color: white;\n        border: thin #757575 solid;\n        margin-left: 10px;\n      \n        &:before {\n          font-family: upsicons, fantasy !important;\n          content: \"\\e619\" !important;\n           \n        }\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
