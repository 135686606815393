// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-sort .sort-controls {
  display: flex;
  flex-direction: row;
}
.users-sort .sort-controls ups-select {
  width: 250px;
}
.users-sort .up-and-down-sort {
  box-shadow: none;
  background-color: white;
  border: thin #757575 solid;
  margin-left: 10px;
}
.users-sort .up-and-down-sort:before {
  font-family: upsicons, fantasy !important;
  content: "\\e619" !important;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/user-sort/user-sort.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,gBAAA;EACA,uBAAA;EACA,0BAAA;EACA,iBAAA;AAFR;AAIQ;EACI,yCAAA;EACA,2BAAA;AAFZ","sourcesContent":[".users-sort {\n    .sort-controls {\n        display: flex;\n        flex-direction: row;\n\n        ups-select {\n            width: 250px;\n        }\n    }\n\n    .up-and-down-sort {\n        box-shadow: none;\n        background-color: white;\n        border: thin #757575 solid;\n        margin-left: 10px;\n\n        &:before {\n            font-family: upsicons, fantasy !important;\n            content: \"\\e619\" !important;\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
