import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentStatus } from "src/app/types/locationProfile";
import { AuthService } from "../../../auth/auth.service";
import { LocalizableComponent } from "../../../components/localizable/localizable.component";
import { LocationInfo } from "../../../types/locationInfo";
import { Role } from "../../../types/role";
import { CustomNameModalComponent } from "../../custom-name-modal/custom-name-modal.component";
import { FeatureFlagService } from "src/app/services/feature-flag.service";
import { Subject, takeUntil } from "rxjs";

const MAX_CUSTOM_NAME_LENGTH = 30;

@Component({
  selector: 'location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
/**
 * Displays information about a customer location. Simple dummy component.
 */
export class LocationComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() location: LocationInfo;
  showFullCustomName: boolean = false;
  showCustomNameLink: boolean = false;
  showOnPropertyLink: boolean = false;

  //regex to verify that the address is not just commas and spaces (same as being empty)
  emptyAddressRegex = /^[\s,]+$/;

  documentStatus = DocumentStatus;

  disconnect$: Subject<boolean> = new Subject<boolean>();

  get customerLocationName() {
    return this.showFullCustomName || this.location.profile.customName.length <= MAX_CUSTOM_NAME_LENGTH ?
      this.location.profile.customName :
      `${this.location.profile.customName.substr(0, MAX_CUSTOM_NAME_LENGTH)}...`;
  }

  constructor(private authService: AuthService, private modalService: NgbModal, private featureFlagService: FeatureFlagService) {
    super();
    this.featureFlagService.sdkReady$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(() => {
        this.showOnPropertyLink = this.featureFlagService.checkFeatureFlagBoolean('on-property-view', false);
      });
  }

  ngOnInit() {
    this.showCustomNameLink = this.showCustomNameHyperlink();
    this.showOnPropertyLink = this.featureFlagService.checkFeatureFlagBoolean('on-property-view', false);
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  showFullName() {
    if (this.location.profile.customName.length > MAX_CUSTOM_NAME_LENGTH) {
      this.showFullCustomName = true;
    }
  }

  hideFullName() {
    this.showFullCustomName = false;
  }

  showCustomNameHyperlink() {
    return this.location.slicName &&
      this.authService.hasLocationRole(this.location.slicName, Role.Admin) &&
      !this.location.profile.forceReadOnly &&
      (this.location.profile.customName.trim().length == 0 || (!this.location.profile.customNameLastUpdatedBy && this.location.slicName == this.location.profile.customName));
  }

  openCustomNameModal(clickEvent: Event) {
    // Stop propagation that selects location list item
    clickEvent.stopPropagation();
    const modalRef = this.modalService.open(CustomNameModalComponent, { modalDialogClass: 'modal-custom-name', centered: true, backdrop: 'static' });
    modalRef.componentInstance.locationProfile = this.location.profile;
    modalRef.componentInstance.modal = modalRef;
  }
}
