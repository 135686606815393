import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, UrlSegment } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AppState } from '../app.state';
import { AuthService } from '../auth/auth.service';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { Sorter } from '../helpers/sort';
import { FullPageModalComponent } from '../home/full-page-modal/full-page-modal.component';
import { UserDataService } from '../services/user-data.service';
import { UserRoleService } from "../services/user-role.service";
import { ExportLocation } from '../types/exportLocation';
import { ExportUser } from '../types/exportUser';
import { DocumentStatus, LocationProfile } from '../types/locationProfile';
import { Role } from '../types/role';
import { Sort } from '../types/states/locationUsersState';

import { ToastService } from '../services/toast.service';
import { LangSection, LangText } from '../types/language';
import { UserWithLocations } from '../types/userWithLocations';

@Component({
  selector: 'location-users',
  templateUrl: './location-users.component.html',
  styleUrls: ['./location-users.component.scss']
})
export class LocationUsersComponent extends LocalizableComponent implements OnInit, OnDestroy {
  locations: LocationProfile[] = [];
  users: UserWithLocations[] = [];
  displayedUsers: UserWithLocations[] = [];
  displayedLocations: LocationProfile[] = [];
  usersSearchTerm = "";
  locationSearchTerm = "";
  disconnect$: Subject<boolean> = new Subject<boolean>();
  refreshUserList: Subject<void> = new Subject<void>();
  filteredUsersCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  filteredUsers: UserWithLocations[] = [];
  filteredLocationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  filteredLocations: LocationProfile[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading: Observable<boolean> = this.isLoading$.asObservable();
  unlockUsers: Subject<void> = new Subject<void>();
  private locationSorter: Sorter<LocationProfile> = new Sorter<LocationProfile>();
  private userSorter: Sorter<UserWithLocations> = new Sorter<UserWithLocations>();
  private sortUserList: (users: UserWithLocations[]) => UserWithLocations[] = (users: UserWithLocations[]) => this.userSorter.sortValues([...users], "upsId", "userId", true, null);
  private refreshUsersSubscription: Subscription;
  disableExport: boolean = false;
  currentFullPageModalRefs: NgbModalRef[] = [];
  private modalNavSubscription = Subscription.EMPTY;
  filteredLocationsDisplayMessage: string = "";
  filteredUsersDisplayMessage: string = "";

  breadcrumbs: { name: string; url: string; }[] = [
    { name: this.localize(this.langSection.Breadcrumb, this.langText.Home), url: '/' },
    { name: this.localize(this.langSection.Breadcrumb, this.langText.LocationUserManagement), url: '/location-users' }];

  private exportErrorToastId = 'export-location-users-error';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private apiService: ApiService,
    private userRoleService: UserRoleService,
    private modalService: NgbModal,
    private userDataService: UserDataService,
    private toastService: ToastService
  ) {
    super();
    this.modalNavSubscription = this.getRouteData().pipe(takeUntil(this.disconnect$), switchMap(obs => {
      if (obs) {
        return of(obs as NavigationEnd);
      } else {
        return this.getUrlData();
      }
    })).subscribe(navData => {
      let urlData: { params: any, url: UrlSegment[] };

      if (navData instanceof NavigationEnd) {
        let urlString = navData.url.replace('/location-users?', '');
        urlData = {
          params: new URLSearchParams(urlString),
          url: navData.url.split('/').map(x => {
            return {
              path: x
            } as UrlSegment
          })
        }
      } else {
        urlData = navData as { params: Params, url: UrlSegment[] }
      }

      const id = urlData.params.get('id');
      const path = urlData.params.get('path');

      if (path === 'user-access-history') {
        this.currentFullPageModalRefs.push(this.openFullPageModal('user-access-history', id));
      } else if (!path) {
        this.currentFullPageModalRefs.forEach(ref => {
          ref.close();
        });
      } else {
        this.currentFullPageModalRefs.forEach(ref => {
          ref.close();
        });
        this.modalService.dismissAll();
        this.modalNavSubscription.unsubscribe();
      }
      this.currentFullPageModalRefs = this.currentFullPageModalRefs.filter(ref => ref.componentInstance);

    })
  }

  ngOnInit(): void {
    this.initializeUserRefresh();
    this.store
      .select(s => {
        return { locations: s.locationList.locations, locationSort: s.locationUsers.locationSort, userSort: s.locationUsers.userSort }
      })
      .pipe(takeUntil(this.disconnect$))
      .subscribe(state => {
        let updatedlocations = state.locations
          .map(loc => Object.assign({}, loc.profile))
          .filter(profile =>
            this.authService.hasLocationRole(profile.slicName, Role.Admin) &&
            profile.locationEnabled &&
            profile.documentStatus != DocumentStatus.SoftDelete
          );

        if (!updatedlocations?.length) {
          this.locations = [];
          this.users = [];
        }

        let customLocationSort = this.generateCustomLocationSortFunction(state.locationSort);
        this.locations = this.locationSorter.sortValues([...this.locations], state.locationSort.field, "slicName", state.locationSort.isAscending, customLocationSort);

        let customUserSort = this.generateCustomUserSortFunction(state.userSort);
        this.sortUserList = (users: UserWithLocations[]) => this.userSorter.sortValues([...users], state.userSort.field, "upsId", state.userSort.isAscending, customUserSort);
        this.users = this.userSorter.sortValues([...this.users], state.userSort.field, 'upsId', state.userSort.isAscending, customUserSort);

        if (this.locationsAddedOrRemoved(updatedlocations)) {
          this.locations = updatedlocations;
          this.loadUsers();
        }
        else {
          this.refreshLocationInformation(updatedlocations);
        }

        this.filterDisplayedUserAndLocations();
      });

    this.store.select(s => s.requestFilters.locationUsersSearchTerm).pipe(takeUntil(this.disconnect$)).subscribe(searchTerm => {
      this.usersSearchTerm = searchTerm;
    });

    this.store.select(s => s.requestFilters.locationSearchTerm).pipe(takeUntil(this.disconnect$)).subscribe(searchTerm => {
      this.locationSearchTerm = searchTerm;
    });

    this.store.select(s => s.locationUsers.removedUser).pipe(takeUntil(this.disconnect$)).subscribe(userId => {
      if (!userId)
        return;

      this.refreshUserList.next();
      const removedUserIndex = this.users.findIndex(u => u.userId == userId);
      if (removedUserIndex >= 0) {
        this.users.splice(removedUserIndex, 1);
      }
    });

    interval(30000).pipe(takeUntil(this.disconnect$)).subscribe(() => {
      this.loadUsers();
    });

    combineLatest([this.filteredLocationsCount$, this.filteredUsersCount$])
      .pipe(takeUntil(this.disconnect$))
      .subscribe(([displayedLocationCount, displayedUserCount]) => {
        if (displayedLocationCount > 0 && displayedUserCount > 0) {
          this.disableExport = false;
        } else {
          this.disableExport = true;
        }

        this.updateLocationDisplayMessage();
        this.updateUserDisplayMessage();
      });

    this.userDataService.refreshUserData.pipe(takeUntil(this.disconnect$)).subscribe(() => {
      this.loadUsers();
    })
    this.filteredUsers = this.users;
    this.filteredLocations = this.locations;
  }

  updateUserDisplayMessage() {
    // If no locations show, then display no users
    let displayedUserCount = this.filteredLocationsCount$.value === 0 ? 0 : this.filteredUsersCount$.value;
    let displayMessage = `${this.localize(LangSection.Term, LangText.Displaying)} ${displayedUserCount} `;
    let userText = this.users?.length === 1 ? this.localize(LangSection.Term, LangText.User) : this.localize(LangSection.Term, LangText.Users);

    if (displayedUserCount === this.users?.length) {
      displayMessage += userText;
    } else {
      displayMessage += `${this.localize(LangSection.Term, LangText.OfMultiple)} ${this.users.length} ${userText}`;
    }

    this.filteredUsersDisplayMessage = displayMessage;
  }

  updateLocationDisplayMessage() {
    // If no users show, then display no locations
    let displayedLocationCount = this.filteredUsersCount$.value === 0 ? 0 : this.filteredLocationsCount$.value;
    let displayMessage = `${this.localize(LangSection.Term, LangText.Displaying)} ${displayedLocationCount} `;
    let locationText = this.locations?.length === 1 ? this.localize(LangSection.Term, LangText.Location) : this.localize(LangSection.Term, LangText.Locations);

    if (displayedLocationCount === this.locations?.length) {
      displayMessage += locationText;
    } else {
      displayMessage += `${this.localize(LangSection.Term, LangText.OfMultiple)} ${this.locations?.length} ${locationText}`;
    }

    this.filteredLocationsDisplayMessage = displayMessage;
  }

  ngOnDestroy() {
    this.currentFullPageModalRefs.forEach(ref => {
      ref.close();
      ref = null;
    });
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
    this.modalNavSubscription.unsubscribe();
  }

  getUrlData() {
    return combineLatest([this.route.url, this.route.params])
      .pipe(takeUntil(this.disconnect$), map(routeInfo => {

        return { params: this.route.snapshot.params, url: this.route.snapshot.url };
      }))
  }

  getRouteData() {
    return this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(takeUntil(this.disconnect$))
  }

  initializeUserRefresh() {
    this.refreshUserList.pipe(switchMap(() => {
      return this.apiService.getUsersWithLocations(this.locations.map(l => { return { slicNumber: l.slicNumber, countryCode: l.countryCode }; }));
    }))
      .subscribe((userList) => {
        this.findLocationCounts(userList);
        this.showUsers(this.sortUserList(userList));
        this.unlockUsers.next();
        this.isLoading$.next(false);
      });
  }

  locationsAddedOrRemoved(updatedLocations: LocationProfile[]): boolean {
    if (this.locations?.length !== updatedLocations.length)
      return true;

    if (this.locations?.length) { // Length is the same - check for added/removed locations
      this.locations.forEach((location) => {
        if (!updatedLocations.some(ul => ul.slicNumber === location.slicNumber && ul.countryCode === location.countryCode))
          return true;
      });
    }

    return false;
  }

  // Update location displayed information
  refreshLocationInformation(updatedLocations: LocationProfile[]) {
    if (this.locations?.length) { // Length is the same - check for added/removed locations
      this.locations.forEach((location) => {
        let updated = updatedLocations.find(ul => ul.slicNumber === location.slicNumber && ul.countryCode === location.countryCode);
        if (updated) {
          location.customName = updated.customName;
          location.address = updated.address;
          location.customNameLastUpdatedAt = updated.customNameLastUpdatedAt;
          location.customNameLastUpdatedBy = updated.customNameLastUpdatedBy;
        }
      });
    }
  }

  loadUsers() {
    this.userDataService.isStale.emit(false);
    if (this.locations?.length) {
      this.refreshUserList.next();
    }
    else {
      this.users = [];
    }
  }

  filterDisplayedUserAndLocations() {
      if (JSON.stringify(this.locations) !== JSON.stringify(this.displayedLocations))
        this.displayedLocations = this.locations;

      if (JSON.stringify(this.users) !== JSON.stringify(this.displayedUsers))
        this.displayedUsers = this.users;
  }

  showUsers(list: UserWithLocations[]) {
    this.users = this.mergeUsers(list, this.users);
    this.filterDisplayedUserAndLocations();
    this.findUserCounts();
  }

  // merge users assuming upsId,userId sort order
  mergeUsers(updatedUsers: UserWithLocations[], existingUsers: UserWithLocations[]): UserWithLocations[] {
    let merged = [...updatedUsers];

    if (merged.length) {
      let missing = existingUsers.filter((user) => !merged.find((m) => m.userId === user.userId));
      if (missing.length) {
        merged.push(...missing.map(user => this.noAccessUser(user)));
        merged = this.sortUserList(merged);
      }
    }
    else
      merged = this.sortUserList(existingUsers.map(user => this.noAccessUser(user)));

    return merged;
  }

  sortCompare(a: String, b: String) {
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  noAccessUser(user: UserWithLocations): UserWithLocations {
    user = Object.assign({}, user); // make shallow copy of user to force view refresh
    user.locations = user.locations.map(location => {
      return (location.role !== Role.NonUser) ? Object.assign({}, location, { role: Role.NonUser, lastUpdatedBy: '' }) : location;
    });
    return user;
  }

  findUserCounts() {
    if (this.displayedLocations?.length) {
      if (this.displayedUsers?.length) {
        this.displayedLocations.forEach((location) => {
          location.users = this.displayedUsers.reduce((total, user: UserWithLocations) => {
            return (user.locations.some(loc => loc.slicNumber === location.slicNumber && loc.countryCode === location.countryCode && this.userRoleService.hasAccessRole(loc.role))) ? total + 1 : total;
          }, 0);
        });
      }
      else { // no users
        this.displayedLocations.forEach((location) => {
          location.users = 0;
        });
      }
    }
  }

  findLocationCounts(users: UserWithLocations[]) {
    if (users?.length) {
      users.forEach(user => {
        user.accessibleLocations = user.locations.filter(location => this.userHasAccess(location.role)).length;
      });
    }
  }

  private userHasAccess(role: Role): boolean {
    return !!((Role.ReadonlyUser | Role.User | Role.Admin) & role);
  }

  updateFilteredLocations(filteredLocations: LocationProfile[]) {
    this.filteredLocations = filteredLocations;
  }

  updateFilteredUsers(filteredUsers: UserWithLocations[]) {
    this.filteredUsers = filteredUsers;
  }

  exportLocationUsers() {
    let language = this.getLanguageCode() as string;
    this.disableExport = true;
    let exportLocations = [];
    this.filteredLocations.forEach((location) => {
      let exportLocation = new ExportLocation(location);
      this.filteredUsers.forEach((user) => {
        let userLocation = user.locations.find(l => l.slicNumber === location.slicNumber)
        if (userLocation && userLocation.role !== Role.NonUser) {
          exportLocation.users.push(new ExportUser(user, userLocation));
        }
      });
      if (exportLocation.users.length > 0) {
        exportLocations.push(exportLocation);
      }
    });
    this.apiService.exportLocationUsers(this.localize(this.langSection.LocationUserPage, this.langText.LocationUserExportFilename), { locationUsers: exportLocations, language: language }).pipe(first())
      .subscribe(
        {
          next: () => {  
            this.disableExport = false;
            this.clearExportError();
          },
          error: error => {
            console.log(error);
            this.showExportError();
            this.disableExport = false;
          }
        });
  }

  private extractTerritory(address: string): string {
    if (!address) {
      return '';
    }

    let territory = address.match(/^.+,\s([^,\s]+)\s.+$/);
    if (territory && territory.length > 1)
      return territory[1];
    else
      return '';
  }

  private generateCustomLocationSortFunction(locationSort: Sort<LocationProfile>): (a: LocationProfile, b: LocationProfile) => number {
    let customSort = null;
    const userId = locationSort.customSortValues && locationSort.customSortValues['userId'];
    const sortField = locationSort.customSortValues && locationSort.customSortValues['field'] ? locationSort.customSortValues['field'] : locationSort.field;

    if (locationSort.field === 'address' && userId) {
      const locationsWithUserRole = this.users.find(user => {
        return user.userId == userId;
      })?.locations.filter(loc => this.userHasAccess(loc.role)).map(loc => {
        return {
          slic: loc.slicNumber,
          role: 1
        };
      });

      customSort = (a: LocationProfile, b: LocationProfile) => {
        const locARole = locationsWithUserRole.find(locRole => locRole.slic == a.slicNumber);
        const locBRole = locationsWithUserRole.find(locRole => locRole.slic == b.slicNumber);

        return (locBRole?.role || 0) - (locARole?.role || 0) ||
          this.locationSorter.sortString(this.extractTerritory(a.address), this.extractTerritory(b.address), locationSort.isAscending);
      };
    }
    else if (locationSort.field === 'address') {
      customSort = (a: LocationProfile, b: LocationProfile) => {

        return this.locationSorter.sortString(this.extractTerritory(a.address), this.extractTerritory(b.address), locationSort.isAscending) ||
          this.locationSorter.sort(a['slicName'], b['slicName'], locationSort.isAscending);
      };
    } else if (userId) {
      const locationsWithUserRole = this.users.find(user => {
        return user.userId == userId;
      })?.locations.filter(loc => this.userHasAccess(loc.role)).map(loc => {
        return {
          slic: loc.slicNumber,
          role: 1
        };
      });

      customSort = (a: LocationProfile, b: LocationProfile) => {
        const locARole = locationsWithUserRole.find(locRole => locRole.slic == a.slicNumber);
        const locBRole = locationsWithUserRole.find(locRole => locRole.slic == b.slicNumber);

        return (locBRole?.role || 0) - (locARole?.role || 0) ||
          this.locationSorter.sort(a[sortField], b[sortField], locationSort.isAscending);
      };
    }

    return customSort;
  }

  private generateCustomUserSortFunction(userSort: Sort<UserWithLocations>): (a: UserWithLocations, b: UserWithLocations) => number {
    let customSort = null;
    const locationCountryCode = userSort.customSortValues && userSort.customSortValues['slicCountryCode'];
    const locationSlicNumber = userSort.customSortValues && userSort.customSortValues['slicNumber'];
    const sortField = userSort.customSortValues && userSort.customSortValues['field'] ? userSort.customSortValues['field'] : userSort.field;

    if (locationCountryCode && locationSlicNumber) {
      const usersWithLocationRole = this.users.filter(u => u.locations.find(loc => loc.countryCode == locationCountryCode && loc.slicNumber == locationSlicNumber && this.userHasAccess(loc.role))).map(user => {
        return {
          ...user,
          role: 1
        };
      });

      customSort = (a: UserWithLocations, b: UserWithLocations) => {
        const userA = usersWithLocationRole?.find(user => user.userId === a.userId);
        const userB = usersWithLocationRole?.find(user => user.userId === b.userId);

        return (userB?.role || 0) - (userA?.role || 0) ||
          this.userSorter.sort(a[sortField], b[sortField], userSort.isAscending);
      }
    }

    return customSort;
  }

  openFullPageModal(type: 'user-access-history', id: string = ''): NgbModalRef {
    const modalRef = this.modalService.open(FullPageModalComponent, { size: 'full-page', keyboard: false, backdrop: 'static' });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.modal = modalRef;

    return modalRef;
  }

  clearExportError() {
    this.toastService.removeToast(this.exportErrorToastId);
  }

  showExportError() {
    this.toastService.pushToast({
      id: this.exportErrorToastId,
      text: this.localize(this.langSection.Toast, this.langText.FailedToExport),
      duration: 10
    });
  }
}
