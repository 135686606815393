import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/app.state';
import { LocationInfo } from 'src/app/types/locationInfo';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { LangSection, LangText } from '../../../types/language';
import { TrailerLoad } from '../../../types/trailerLoad';
import { TrailerRequestResponse } from '../../../types/trailerRequestResponse';

@Component({
  selector: 'request-trailer',
  templateUrl: './request-trailer.component.html',
  styleUrls: ['./request-trailer.component.scss']
})
export class RequestTrailerComponent extends LocalizableComponent implements OnDestroy, OnInit {
  @Input() request: TrailerRequestResponse;
  serviceLevelTranslate = { "EAM": "Early AM", "1DA": "Next Day Air", "2DA": "Second Day Air", "3DS": "Three Day Select", "GRD": "Ground" };
  disconnect$: Subject<boolean> = new Subject<boolean>();
  fromCustomerName: string = null;
  toCustomerName: string = null;
  formattedFromDueDate: string = '';
  formattedToDueDate: string = '';
  locations: LocationInfo[];

  constructor(private store: Store<AppState>, private apiService: ApiService) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(locationList => {
        this.locations = locationList.locations;
        if (this.fromCustomerName === null && this.request.trailerLoads.length > 0 && this.showLoad(this.request.trailerLoads[0])) {
          this.updateCustomerName(locationList.locations, this.request.trailerLoads[0], (name) => { this.fromCustomerName = name; });
          this.formattedFromDueDate = this.formatDateWithoutOffset(this.request.trailerLoads[0].dueDate);
        }

        if (this.toCustomerName === null && this.request.trailerLoads.length > 1 && this.showLoad(this.request.trailerLoads[1])) {
          this.updateCustomerName(locationList.locations, this.request.trailerLoads[1], (name) => { this.toCustomerName = name; });
          this.formattedToDueDate = this.formatDateWithoutOffset(this.request.trailerLoads[1].dueDate);
        }
      });
  }

  ngOnChanges() {
    if (this.locations && this.request.trailerLoads.length > 1 && this.showLoad(this.request.trailerLoads[1])) {
      this.updateCustomerName(this.locations, this.request.trailerLoads[1], (name) => { this.toCustomerName = name; });
      this.formattedToDueDate = this.formatDateWithoutOffset(this.request.trailerLoads[1].dueDate);
    }
  }

  updateCustomerName(locations: LocationInfo[], load: TrailerLoad, setCustomerName: (name: string) => void) {
    const location = locations.find(loc => load.destinationOrganizationNumber == loc.slicNumber && load.destinationCountryCode == loc.countryCode);
    if (location) {
      setCustomerName(location.profile.customer || location.profile.crdSlicName);
    }
    else if (load.destinationCountryCode && load.destinationOrganizationNumber) {
      this.apiService.getLocation(load.destinationCountryCode, load.destinationOrganizationNumber)
        .pipe(first())
        .subscribe(
          location => {
            setCustomerName(location?.customer || location?.crdSlicName);
          },
          _ => {
            setCustomerName('');
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  showLoad(load: TrailerLoad) {
    return load.hasUnplannedLoad || load.originOrganizationNumber || load.destinationOrganizationNumber;
  }

  getLoadDirection(index: number) {
    const fromUps = this.localize(LangSection.TrailerCard, LangText.FromUPS);
    const toUps = this.localize(LangSection.TrailerCard, LangText.ToUPS);


    return index === 0 ? fromUps : toUps;
  }

  formatDateWithoutOffset(date: string | Date): string {
    return date && moment.utc(date).isValid() ? moment.utc(date).format('MM/DD') : '';
  }
}
