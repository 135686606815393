// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 375px) {
  #AddRequestInputs {
    min-width: 318px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/request-form/add-request/add-request.component.scss"],"names":[],"mappings":"AACA;EACI;IACE,gBAAA;EAAJ;AACF","sourcesContent":["\n@media (min-width: 375px) {\n    #AddRequestInputs {\n      min-width: 318px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
