import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/app.state';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { removeLocationUser } from 'src/app/store/actions/location-users.actions';
import { LocationProfile } from 'src/app/types/locationProfile';
import { LocationUser } from 'src/app/types/locationUser';
import { UserWithLocations } from 'src/app/types/userWithLocations';

@Component({
  selector: 'app-remove-user-modal',
  templateUrl: './remove-user-modal.component.html',
  styleUrls: ['./remove-user-modal.component.scss']
})
export class RemoveUserModalComponent extends LocalizableComponent {
  @Input() user: UserWithLocations;
  @Input() modal: NgbModalRef;
  private disconnect$: Subject<boolean> = new Subject<boolean>();
  saveFailed: boolean;

  constructor(private modalService: NgbModal, private apiService: ApiService, private store: Store<AppState>) {
    super();
   }

  removeUserFromAllLocations() {
    this.apiService.removeUserFromAllLocations(this.user.userId).pipe(takeUntil(this.disconnect$)).subscribe(result => {
      this.saveFailed = false;
      this.modal.close();
      this.store.dispatch(removeLocationUser(this.user.userId));
    },
      () => {
        this.saveFailed = true;
      });
    
  }

  dismissModal() {
    this.modal.close();
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }
}
