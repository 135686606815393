import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { ApiService } from "../../api.service";
import { AuthService } from "../../auth/auth.service";
import { LocationName } from "../../types/locationName";
import { Role } from "../../types/role";

@Component({
  selector: 'location-search-input',
  templateUrl: './location-search-input.component.html',
  styleUrls: ['./location-search-input.component.scss']
})
export class LocationSearchInput {
  @Input() locationQuery: string | LocationName = {
    slicName: '', customName: '', countryCode: '', slicNumber: ''
  };

  @Input() excludeViewOnly: boolean = false;
  @Input() showCustomName: boolean = true;
  @Input() value: string = '';
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<string | LocationName> = new EventEmitter<LocationName>();

  constructor(private apiService: ApiService, private authService: AuthService) {
  }

  ngOnChanges() {
    this.locationQuery = { slicName: this.value, customName: '', countryCode: '', slicNumber: '' };
  }

  onQueryChange(val: string) {
    this.onChange.emit(val);
  }

  //Called by ng-typeahead, calls to the API with a search query and shows a dropdown list of possible autocomplete values
  searchLocations = (text$: Observable<string | LocationName>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    switchMap(term => {
      if ((typeof (term) === 'string' && term.length == 0) || (typeof (term) === 'object' && term.slicName.length === 0)) {
        this.onSelect.emit(null);
      }

      return typeof (term) === 'object' ?
        this.apiService.searchAllLocations(term.slicName).pipe(map(this.filterResults.bind(this))) :
        (term.length > 1 ?
          this.apiService.searchAllLocations(term).pipe(map(this.filterResults.bind(this)))
          : []
        )
    })
  );

  //Occurs when the user clicks on an autocompleted item. Will cause locations to load.
  onTypeaheadSelect(item: string | LocationName) {
    if (typeof (item) !== 'object') item = { slicName: item, customName: '', countryCode: '', slicNumber: '' };
    this.onSelect.emit(item);
  }

  locationInputFormatter(result: LocationName) {
    if (!result || !result.slicName) return '';
    if (result.customName === '' || !this.showCustomName) return result.slicName;
    return `${result.slicName} (${result.customName})`;
  }

  locationSearchFormatter(result: LocationName) {
    if (result.customName === '' || !this.showCustomName) return result.slicName;
    return `${result.slicName} (${result.customName})`;
  }

  private filterResults(results: LocationName[]) {
    return results
      .filter(loc =>
        this.authService.hasLocationRole(loc.slicName, Role.User) ||
        !this.excludeViewOnly
      );
  }
}
