// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: none;
  padding: 22px 16px 16px 60px;
}

.modal-body {
  padding: 0 60px;
  border-bottom: none;
}

.modal-footer {
  padding: 0 0 24px 0;
  border-top: none;
}

.btn {
  margin-top: 32px;
  height: 36px;
  border-radius: 100px;
  border: 0;
  padding-right: 28px;
  padding-left: 28px;
}

.btn-green {
  color: white;
  background-color: #00857D;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImltcG9ydC1lcnJvci1tb2RhbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLG1CQUFBO0VBQ0EsNEJBQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7RUFDQSxtQkFBQTtBQUNKOztBQUVBO0VBQ0ksbUJBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksZ0JBQUE7RUFDQSxZQUFBO0VBQ0Esb0JBQUE7RUFDQSxTQUFBO0VBQ0EsbUJBQUE7RUFDQSxrQkFBQTtBQUNKOztBQUVBO0VBQ0ksWUFBQTtFQUNBLHlCQUFBO0FBQ0oiLCJmaWxlIjoiaW1wb3J0LWVycm9yLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1vZGFsLWhlYWRlciB7XG4gICAgYm9yZGVyLWJvdHRvbTogbm9uZTtcbiAgICBwYWRkaW5nOiAyMnB4IDE2cHggMTZweCA2MHB4O1xufVxuXG4ubW9kYWwtYm9keSB7XG4gICAgcGFkZGluZzogMCA2MHB4O1xuICAgIGJvcmRlci1ib3R0b206IG5vbmU7XG59XG5cbi5tb2RhbC1mb290ZXIge1xuICAgIHBhZGRpbmc6IDAgMCAyNHB4IDA7XG4gICAgYm9yZGVyLXRvcDogbm9uZTtcbn1cblxuLmJ0biB7XG4gICAgbWFyZ2luLXRvcDogMzJweDtcbiAgICBoZWlnaHQ6IDM2cHg7XG4gICAgYm9yZGVyLXJhZGl1czogMTAwcHg7XG4gICAgYm9yZGVyOiAwO1xuICAgIHBhZGRpbmctcmlnaHQ6IDI4cHg7XG4gICAgcGFkZGluZy1sZWZ0OiAyOHB4O1xufVxuXG4uYnRuLWdyZWVuIHtcbiAgICBjb2xvcjogd2hpdGU7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogIzAwODU3RDtcbn1cblxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/location-users/add-location-users/import-error-modal/import-error-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,4BAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;AACJ;AACA,wlCAAwlC","sourcesContent":[".modal-header {\n    border-bottom: none;\n    padding: 22px 16px 16px 60px;\n}\n\n.modal-body {\n    padding: 0 60px;\n    border-bottom: none;\n}\n\n.modal-footer {\n    padding: 0 0 24px 0;\n    border-top: none;\n}\n\n.btn {\n    margin-top: 32px;\n    height: 36px;\n    border-radius: 100px;\n    border: 0;\n    padding-right: 28px;\n    padding-left: 28px;\n}\n\n.btn-green {\n    color: white;\n    background-color: #00857D;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
