import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { refreshTrailerRequests, updateTrailerRequest } from 'src/app/store/actions/trailer-requests.actions';
import { ApiService } from '../../api.service';
import { LocalizableComponent } from '../../components/localizable/localizable.component';
import { TrailerRequestResponse } from '../../types/trailerRequestResponse';

@Component({
  selector: 'cancel-request',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModal extends LocalizableComponent implements OnInit {
  @Input() request: TrailerRequestResponse;
  @Input() modal;

  readonly otherPleaseSpecifyText: { name: string, value: string } = {
    name: this.localize(this.langSection.CancelModal, this.langText.OtherPleaseSpecify),
    value: this.langText.OtherPleaseSpecify
  };

  cancelReason: string = "";
  error: string = "";
  placeholder: string = "";
  cancelRequestError: boolean = false;
  cancelReasons: { name: string, value: string }[] = [
    { name: this.localize(this.langSection.CancelModal, this.langText.DuplicateRequest), value: this.langText.DuplicateRequest },
    { name: this.localize(this.langSection.CancelModal, this.langText.Holiday), value: this.langText.Holiday },
    { name: this.localize(this.langSection.CancelModal, this.langText.VolumeDidNotGenerate), value: this.langText.VolumeDidNotGenerate },
    { name: this.localize(this.langSection.CancelModal, this.langText.Weather), value: this.langText.Weather },
    this.otherPleaseSpecifyText];
  cancelReasonOtherText: string = "";
  displayCancelReasonOther: boolean = this.cancelReason === this.otherPleaseSpecifyText.value;

  confirm: Subject<void> = new Subject<void>();

  constructor(private apiService: ApiService, private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.cancelReason = this.request.cancelReason;
  }

  cancelRequest() {
    this.cancelRequestError = false;
    this.error = '';
    this.placeholder = '!';

    if (!this.cancelReason?.trim() || (this.cancelReason === this.otherPleaseSpecifyText?.value && !this.cancelReasonOtherText?.trim())) {
      this.error = this.localize(this.langSection.CancelModal, this.langText.PleaseEnterAReason);
      this.placeholder = '!';
      return;
    }

    const originalRequest = this.request;
    this.request.requestStatus = "Canceling";
    this.request.cancelReason = this.cancelReason === this.otherPleaseSpecifyText.value ?
      `${this.langText.Other}: ${this.cancelReasonOtherText}` :
      this.cancelReason;

    if (this.request.attentionNeeded) {
      this.request.selectedCustomerRejectionOption = -1;
      this.request.attentionNeeded = false;
    }

    this.apiService.cancelRequest(this.request)
      .subscribe({
        next: _ => {
          this.store.dispatch(updateTrailerRequest(this.request.requestId, this.request));
          setTimeout(() => this.store.dispatch(refreshTrailerRequests()), 5000)
          this.confirm.next();
          this.modal.close();
        },
        error: error => {
          console.log(error);
          this.request = originalRequest;
          this.cancelRequestError = true;
        }
      });
  }

  onReasonChanged(reason: string) {
    this.cancelReason = reason;
    this.displayCancelReasonOther = this.cancelReason === this.otherPleaseSpecifyText.value;
    this.error = "";
  }

  dismissModal() {
    this.modal.close();
  }
}
