// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e668" !important;
  color: #FFF;
}

.clear.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e67b" !important;
  color: #FFF;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXJzLXNlYXJjaC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHlDQUFBO0VBQ0EsMkJBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSx5Q0FBQTtFQUNBLDJCQUFBO0VBQ0EsV0FBQTtBQUNGIiwiZmlsZSI6InVzZXJzLXNlYXJjaC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zZWFyY2guaWNvbjpiZWZvcmUge1xuICBmb250LWZhbWlseTogdXBzaWNvbnMsIGZhbnRhc3kgIWltcG9ydGFudDtcbiAgY29udGVudDogJ1xcRTY2OCcgIWltcG9ydGFudDtcbiAgY29sb3I6ICNGRkY7XG59XG5cbi5jbGVhci5pY29uOmJlZm9yZSB7XG4gIGZvbnQtZmFtaWx5OiB1cHNpY29ucywgZmFudGFzeSAhaW1wb3J0YW50O1xuICBjb250ZW50OiAnXFxFNjdCJyAhaW1wb3J0YW50O1xuICBjb2xvcjogI0ZGRjtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/location-users/users-search/users-search.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,2BAAA;EACA,WAAA;AACF;;AAEA;EACE,yCAAA;EACA,2BAAA;EACA,WAAA;AACF;AACA,gqBAAgqB","sourcesContent":[".search.icon:before {\n  font-family: upsicons, fantasy !important;\n  content: '\\E668' !important;\n  color: #FFF;\n}\n\n.clear.icon:before {\n  font-family: upsicons, fantasy !important;\n  content: '\\E67B' !important;\n  color: #FFF;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
