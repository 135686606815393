// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-sort .sort-controls {
  display: flex;
  flex-direction: row;
}
.location-sort .sort-controls ups-select {
  width: 250px;
}
.location-sort .up-and-down-sort {
  box-shadow: none;
  background-color: white;
  border: thin #757575 solid;
  margin-left: 10px;
}
.location-sort .up-and-down-sort:before {
  font-family: upsicons, fantasy !important;
  content: "\\e619" !important;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/location-sort/location-sort.component.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,mBAAA;AADR;AAGQ;EACE,YAAA;AADV;AAKM;EACE,gBAAA;EACA,uBAAA;EACA,0BAAA;EACA,iBAAA;AAHR;AAKQ;EACE,yCAAA;EACA,2BAAA;AAHV","sourcesContent":[".location-sort {\n\n    .sort-controls {\n        display: flex;\n        flex-direction: row;\n      \n        ups-select {\n          width: 250px;\n        }\n      }\n      \n      .up-and-down-sort {\n        box-shadow: none;\n        background-color: white;\n        border: thin #757575 solid;\n        margin-left: 10px;\n      \n        &:before {\n          font-family: upsicons, fantasy !important;\n          content: \"\\e619\" !important;\n           \n        }\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
