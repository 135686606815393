// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-alert:before {
  font-family: upsicons, fantasy !important;
  content: "\\e60a" !important;
  font-size: 23px;
  color: #F68B20;
}

.warning-alert {
  margin-right: 13px;
}

.alert-warning {
  display: flex;
  background-color: white;
  color: #242424;
  border-color: #F68B20;
  font-size: 12px;
  min-height: 70px;
  align-items: center;
}

strong {
  margin-right: 0.3em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdGlmaWNhdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHlDQUFBO0VBQ0EsMkJBQUE7RUFDQSxlQUFBO0VBQ0EsY0FBQTtBQUNGOztBQUVBO0VBQ0ksa0JBQUE7QUFDSjs7QUFFQTtFQUNFLGFBQUE7RUFDQSx1QkFBQTtFQUNBLGNBQUE7RUFDQSxxQkFBQTtFQUNBLGVBQUE7RUFDQSxnQkFBQTtFQUNBLG1CQUFBO0FBQ0Y7O0FBRUE7RUFDSSxtQkFBQTtBQUNKIiwiZmlsZSI6Im5vdGlmaWNhdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi53YXJuaW5nLWFsZXJ0OmJlZm9yZSB7XG4gIGZvbnQtZmFtaWx5OiB1cHNpY29ucywgZmFudGFzeSAhaW1wb3J0YW50O1xuICBjb250ZW50OiAnXFxFNjBBJyAhaW1wb3J0YW50O1xuICBmb250LXNpemU6IDIzcHg7XG4gIGNvbG9yOiAjRjY4QjIwO1xufVxuXG4ud2FybmluZy1hbGVydCB7XG4gICAgbWFyZ2luLXJpZ2h0OiAxM3B4O1xufVxuXG4uYWxlcnQtd2FybmluZyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xuICBjb2xvcjogIzI0MjQyNDtcbiAgYm9yZGVyLWNvbG9yOiAjRjY4QjIwO1xuICBmb250LXNpemU6IDEycHg7XG4gIG1pbi1oZWlnaHQ6IDcwcHg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbnN0cm9uZyB7XG4gICAgbWFyZ2luLXJpZ2h0OiAwLjNlbTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/notification/notification.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,2BAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACI,mBAAA;AACJ;AACA,wgCAAwgC","sourcesContent":[".warning-alert:before {\n  font-family: upsicons, fantasy !important;\n  content: '\\E60A' !important;\n  font-size: 23px;\n  color: #F68B20;\n}\n\n.warning-alert {\n    margin-right: 13px;\n}\n\n.alert-warning {\n  display: flex;\n  background-color: white;\n  color: #242424;\n  border-color: #F68B20;\n  font-size: 12px;\n  min-height: 70px;\n  align-items: center;\n}\n\nstrong {\n    margin-right: 0.3em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
