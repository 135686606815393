import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepickerI18n, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { LocalizableComponent } from '../localizable/localizable.component';
import { DatepickerLocalePreferences } from './datepicker.i18n';

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: DatepickerLocalePreferences }
  ]
})

/**
 * An enhanced ng-bootstrap datepicker wrapper, modified to meet UPS datepicker requirements.
 * Input and output are both Javascript Date objects, while internally NgbDate is used. The one thing to note about NgbDate is that it uses months 1-12 whereas Javascript is 0-11.
 */
export class DatepickerComponent extends LocalizableComponent {
  //Declare two model values: the ngbDate we pass to ng-bootstrap, and the modelDate wih a textual represenaion
  ngbDate: NgbDate | null = null;
  modelDate: string = "";
  isRightAligned: boolean = false;
  positionTarget: string = "dtinput";
  errors = "";
  unreadCommentDates: Date[] = [];
  @ViewChild("hiddenInput", { static: true, read: ElementRef }) hiddenInput: ElementRef;

  @Input() disableBefore: Date | null = null;
  @Input() disableAfter: Date | null = null;
  @Input() readonly: boolean = false;
  @Input() showUnreadCommentMeatballs: boolean = false;
  @Input() placement: string = "bottom-left";
  @Input() displayValidationErrors: boolean = true;


  //Take JS Date input and convert it to the corresponding Ngb and textual representations
  @Input()
  set date(date: Date | null) {
    if (date) {
      const ngb = NgbDate.from({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() });
      this.modelDate = this.formatNgbDate(ngb);
      this.ngbDate = ngb;
      this.errors = "";
    } else {
      this.modelDate = "";
      this.ngbDate = null;
    }
  }

  get format() {
    if (this.getLanguageCode() === 'en') {
      return '00/00/0000';
    } else {
      return '0000-00-00';
    }
  }

  //Emit JS Dates
  @Output() onChange: EventEmitter<Date | null> = new EventEmitter<Date | null>();

  constructor(private requestService: ApiService){
    super();
  }

  ngAfterViewChecked() {
    if (this.hiddenInput) {
      let datepicker = this.hiddenInput.nativeElement.nextSibling;
      if (!datepicker.classList?.contains('dropdown-menu'))
        return;
      let datepickerRect = datepicker.getBoundingClientRect();
      let x = datepickerRect.left;
      let width = datepickerRect.width;
      let innerWidth = window.innerWidth;
      this.isRightAligned = x + width >= innerWidth
      if (this.isRightAligned && this.placement != "bottom-right") {
        this.placement = "bottom-right";
      } else if (!this.isRightAligned && this.placement != "bottom-right") {
        this.placement = "bottom-left";
      }
      if (this.placement == 'bottom-right')
        datepicker.classList.add('right-datepicker');
    }
  }

  isDateDisabled = (date: NgbDate) => {
    return this.isDateAfterFutureLimit(date) || this.isDateAfterPastLimit(date);
  }

  //Change selected date to the current date and request ng-datepicker move it's view to that date
  changeToToday(datePicker: NgbInputDatepicker) {
    const today = new Date();
    this.onChange.emit(today);
    datePicker.navigateTo({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  }

  //Clear out the date input and send null back to parent
  clearDate() {
    this.onChange.emit(null);
  }

  //Fired from the ng-datepicker, convert the NgbDate to a JS Date and emit
  onDateChanged(date: NgbDate) {
    const jsDate = this.ngbToJsDate(date);
    this.date = jsDate;
    this.onChange.emit(jsDate);
  }

  //When the user manually enters date text, wait for it to be valid (MMDDYYYY) and parse it
  onModelDateChanged() {
    if (this.modelDate.length === 8) {
      this.errors = "";

      //Now we validate that the user input is within the 30 day back, 90 day forward range. If not, we set it to the lower or upper limit accordingly.
      let date = NgbDate.from(this.parseDateToNgb(this.modelDate));

      if (this.isDateAfterPastLimit(date)) {
        date = NgbDate.from({ year: this.disableBefore.getFullYear(), month: this.disableBefore.getMonth() + 1, day: this.disableBefore.getDate() });
      } else if (this.isDateAfterFutureLimit(date)) {
        date = NgbDate.from({ year: this.disableAfter.getFullYear(), month: this.disableAfter.getMonth() + 1, day: this.disableAfter.getDate() });
      }

      //Set it and fire an event
      this.onDateChanged(date);
    }
    this.errors = this.localize(this.langSection.RequestFormPage, this.langText.InvalidDate)
  }

  //Formats date based on language code
  private formatNgbDate(date: NgbDate) {
    if (this.getLanguageCode() === 'en') {
      return `${date.month.toString().padStart(2, "0")}/${date.day.toString().padStart(2, "0")}/${date.year}`;
    } else {
      return `${date.year}-${date.month.toString().padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`;
    }
  }

  private parseDateToNgb(str: string) {
    let year;
    let month;
    let day;

    if (this.getLanguageCode() === 'en') {
      month = parseInt(str.substr(0, 2));
      day = parseInt(str.substr(2, 2));
      year = parseInt(str.substr(4, 4));
    } else {
      year = parseInt(str.substr(0, 4));
      month = parseInt(str.substr(4, 2));
      day = parseInt(str.substr(6, 2));
    }

    return { year, month, day };
  }

  //Determines if the provided date is before our lower limit of 30 days
  private isDateAfterPastLimit(date: NgbDate) {
    return this.disableBefore !== null && this.ngbToJsDate(date).getTime() < this.disableBefore.getTime();
  }

  //Determines if the provided date is after our upper limit of 90 days
  private isDateAfterFutureLimit(date: NgbDate) {
    return this.disableAfter !== null && this.ngbToJsDate(date).getTime() > this.disableAfter.getTime();
  }

  //Converts an ng-bootstrap internal date to a JS Date
  private ngbToJsDate(date: NgbDate) {
    return new Date(date.year, date.month - 1, date.day);
  }

  hasUnreadComments(date: NgbDate){
    const jsDate = this.ngbToJsDate(date);
    for(let i = 0; i < this.unreadCommentDates.length; i++){
      
      const commentDate = new Date(this.dateWithoutOffset(this.unreadCommentDates[i]));
      if(!this.isDateDisabled(date) && jsDate.getDate() === commentDate.getDate() && jsDate.getMonth() === commentDate.getMonth() && jsDate.getFullYear() === commentDate.getFullYear()){
        return true;
      }
    }
    return false;
  }

  getCommentDates(){
    if(this.showUnreadCommentMeatballs){
      this.requestService.getUnreadCommentDates().subscribe(c => this.unreadCommentDates = c);
    }
  }

  
  private dateWithoutOffset(date){
    let offsetDate = new Date(date);
    let offset = -(offsetDate.getTimezoneOffset() * 60000);
    return offsetDate.getTime() - offset;
  }
}
