// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e668" !important;
  color: #FFF;
}

.clear.icon:before {
  font-family: upsicons, fantasy !important;
  content: "\\e67b" !important;
  color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/app/location-users/users-search/users-search.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,2BAAA;EACA,WAAA;AACF;;AAEA;EACE,yCAAA;EACA,2BAAA;EACA,WAAA;AACF","sourcesContent":[".search.icon:before {\n  font-family: upsicons, fantasy !important;\n  content: '\\E668' !important;\n  color: #FFF;\n}\n\n.clear.icon:before {\n  font-family: upsicons, fantasy !important;\n  content: '\\E67B' !important;\n  color: #FFF;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
