import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { updateLocationUsersLocationFilter } from 'src/app/store/actions/filter.actions';
import { debounceFunction } from 'src/decorators/debounceFunction';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent extends LocalizableComponent implements OnInit {

  public locationSearchString: string = "";
  public iconClass: string = 'search icon';

  constructor(private store: Store<AppState>) {
    super();
   }

  ngOnInit(): void {
    this.store.dispatch(updateLocationUsersLocationFilter(this.locationSearchString));
  }

  @debounceFunction(300)
  searchForLocation() {
    this.iconClass = this.locationSearchString === "" ? 'search icon' : 'clear icon';
    this.store.dispatch(updateLocationUsersLocationFilter(this.locationSearchString));
  }

  clearLocationSearch() {
    this.locationSearchString = "";
    this.searchForLocation();
  }

}
